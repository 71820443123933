import React, { useEffect, useState } from 'react';
import './ScrollToTop.css';
import { IoIosArrowDropup } from 'react-icons/io'

const ScrollToTop = () => {
    const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scorlled upto given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);

  }, []);

  return (
    <div className="scrollToTop">
      {isVisible && (
        <div onClick={scrollToTop}>
          <IoIosArrowDropup className="scrollToTop-icon"/>
        </div>
      )}
    </div>
  );
}

export default ScrollToTop
