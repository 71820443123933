import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { useHistory, useParams, Link } from 'react-router-dom';
import './accountDetails.css';
import axios from '../../../axios';

function AccountDetails() {
    const { t } = useTranslation();
    const history = useHistory();

    const { _id } = useParams();
    
    const [radio,setRadio]=useState();
    const [isChecked,setIsChecked]=useState(false);
    const [userdata, setUserData] = useState([]);

    const getToken = localStorage.getItem("token")
    const parseToken = JSON.parse(getToken)
    
     // previously we used the config file fot the url now i am using Axios base url

    useEffect(() => {
       const fetchUser = async()=>{

        const res = await axios.get(`/user/${_id}`, 

          {
            headers: {
            "Authorization": "Bearer " + parseToken  } 
          }
        )
          // console.log(res);
          const data = res.data;
        
          setUserData(data);
          setRadio(data.approved === true ? "true" : "false" )
          setIsChecked(data?.role === 1 ? true : false )
       }     
       fetchUser();   
      },[_id,parseToken]);

    
      // previously we used the config file fot the url now i am using Axios base url

      const handleSubmit= async(e) =>{
          e.preventDefault();
          try{

            let CheckBoxValue;
            if(isChecked === false){
                CheckBoxValue = 2
             }else{
                CheckBoxValue = 1
             }

             await axios.put(`/user/approve?id=${_id}`,{},{

                headers: {
                    "Authorization": "Bearer " + parseToken
                  }
                }
              )

             await axios.put(`/user?id=${_id}`,{
                approved:radio,
                role:CheckBoxValue
              },{
                 headers: {
                    "Authorization": "Bearer " + parseToken
                  }
              }) 

              // console.log("updateResponse: " + JSON.stringify(updateResponse));

              history.push('/users-list');
          }
          catch(e){
            const message = e.response?.data?.error;
             console.log(message);
           }
      }


    return (
        <div className="updated_accountDetails">
                    <div className="accountDetails_container">

                    <div className="accountDetails_title" >
            <Link to='/users-list' style={{textDecoration:"none"}}>
		        <IoIosArrowRoundBack className="back_icon_centerAlign"/>
	           </Link>
           <div style={{marginRight:"20px"}}> {t('main.accountDetails')}</div>
                      </div>
                           <div className="accountDetails_wrapper">
        
                                 <div className="accountDetails_left">
                                     <div className="text_wrapper_left">
                                     <h5>{t('main.title')}</h5>
                                     <h5>{t('main.firstName')}</h5>
                                     <h5>{t('main.lastName')}</h5>
                                     <h5>{t('main.email')}</h5>
                                     <h5 className="text_padding">{t('main.intendedUse')}</h5>
                                     <h5 className="text_padding">{t('main.purposeOfApplication')}</h5>
                                     <h5 >{t('main.organization')}</h5>
                                     <h5 className="text_padding">{t('main.status')}</h5>
                                     <h5>{t('main.role')}</h5>
                                     </div>
                                 </div>
                                 <div className='accountDetails_right'>

                                   
                                     <div className="text_wrapper_right">
                                     <h5>{userdata?.title}</h5>
                                     <h5>{userdata?.firstName}</h5>
                                     <h5>{userdata?.lastName}</h5>
                                     <h5>{userdata?.email}</h5>
                                     <div className="textarea_wrapper">
                                     <textarea  className="text_area" disabled rows="2" cols="10" placeholder={`${userdata?.intendedUse}`}></textarea>
                                     <textarea  className="text_area" disabled rows="3" cols="10" placeholder={`${userdata?.purpose}`}></textarea>
                                     </div>
                                     <h5>{userdata?.organization}</h5>
                                     
                                     <div className="radio_container">
                                        <div className="radio_wrapper">
                                        <input type="radio" id="approved"  checked={radio === "true"} value="true" onChange={(e)=>{setRadio(e.target.value)}} /><label htmlFor="approved">{t('main.approved')}</label>
                                        </div>
                                        <div className="radio_wrapper">
                                        <input type="radio" id="rejected"  checked={radio === "false"} value="false" onChange={(e)=>{setRadio(e.target.value)}} /><label htmlFor="rejected">{t('main.notApproved')}</label>    
                                        </div>
                                     </div>

                                  <div className="accountDetails_checkbox_wrapper">
                                    
                                   <div className="accountDetails_checkbox">
                                   <input type="checkbox" checked={isChecked} onChange={(e)=>setIsChecked(e.target.checked)}/>
                                    <span className="accountDetails_checkboxName">{t('main.superAdmin')}</span>
                                   </div>

                                      <span className="checkbox_disclaimer">* {t("main.fullAdminAccess")} *</span>
                                   </div>

                                     </div>
                                 </div> 
                           </div>

                           

                            <div className="btn_wrapper">
                            <button className="accountDetails_btn" onClick={handleSubmit}>{t('main.submit')}</button>
                            </div>
                    </div>
        </div>
    )
}

export default AccountDetails;