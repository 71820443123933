import React, { useEffect, useState } from 'react';
import './GroupList.css';
import {FiEdit} from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from '../../../axios';
import ReactPaginate from 'react-paginate';
import PopUp from './PopUp';
import liveImg from '../../../assets/images/onlinemeeting.ico';
import OfflineImg from '../../../assets/images/offlinemeeting.ico'
import { useDataLayerValue } from '../../../datalayer';
import { IoIosArrowRoundBack } from 'react-icons/io';
import UrlCopiedPopup from './UrlCopiedPopup';
import ChatExportPopUp from './ChatExportPopUp/ChatExportPopUp';


function GroupList() {
	  const { t } = useTranslation();

	  const getToken = localStorage.getItem("token")
	  const parseToken = JSON.parse(getToken);

	  const [{groupId},dispatch]=useDataLayerValue();

      const [groupDetails,setGroupDetails]=useState([]);
	  const [pageCount, setPageCount] = useState(1);
	  const [isLoaded, setisLoaded] = useState(false);
	  const [currentPage,setCurrentPage]=useState(1)
	

	  useEffect(()=>{
		fetchGroups(currentPage);
		dispatch({
			 type:"USER_LOGGED_OUT",
			 emptyItems:{
				group_id:null,
				questionDetails:[],
				CreateQuestionDetails:[]
			 }
			})


	      },[dispatch,currentPage])



		const fetchGroups = async (pageToGet) => {
			const res = await axios.get(`/group?page=${pageToGet}`,{
				headers: {
				  "Authorization": "Bearer " + parseToken
				}
			  })
            //  console.log("groupres",res);  
            setGroupDetails(res.data.groups);
			setPageCount(res.data.count / 10);
			if(res.data.count >= 1){
				setisLoaded(true); 
			} 	
		 }
	
		   //  reduces sentence
	   function truncate(str , n){
		 return str?.length > n ? str.substr(0,n - 1) + "..." : str;
	   }
 
	   const handlePageChange = ( selectedObject ) => {
		let intSelectedPage = parseInt(selectedObject.selected) + 1;
		setCurrentPage(intSelectedPage);
		fetchGroups(intSelectedPage);
	  }

     	const deleteGroup = async(id) => {
			    //    console.log(id);
			 try{
				 await axios.delete(`/group?id=${id}`,{
					headers: {
					   "Authorization": "Bearer " + parseToken
					 }
				 }) 
				 fetchGroups(currentPage);
			 }
			 catch(e){
				 console.log(e.response?.data?.error);
			}
	   }

    return (
        <div className="userlist">
        <div className="userlist_wrapper">
    
		<div className="manageVideo_titleWrapper">
            <div className="manageVideo_title">
			 <div className="userlist_title">
                <Link to='/' style={{textDecoration:"none"}}>
		        <IoIosArrowRoundBack className="back_icon"/>
	           	</Link> {t('main.roomTemplateList')}
                </div>
			</div>

            <Link to='/create-group' style={{textDecoration:"none"}}>
            <button className="create_btn"> {t('main.create')}&nbsp;{t('main.Group')}</button>
            </Link>
         </div>

         <table className="grouplist_table">
            <thead>
				<tr>
				<th>#</th>
            <th>{t('main.name')}</th>
     	    <th>{t('main.description')}</th>
     	    <th>{t('main.linkForParticipants')}</th>
            <th>{t('main.operation')}</th>
				</tr>
          </thead>
          <tbody>
     	 
		   {
					  groupDetails?.map( (group,index) => (

						<tr key={group._id} > 
						<td  data-label="#">{index + (currentPage -1) * 10 + 1 }</td>

                        <td  data-label={`${t('main.name')}`}>{group?.name}</td>

						<td  data-label={`${t('main.description')}`}>{truncate(group?.description,50)}</td>

						<td  data-label={`${t('main.linkForParticipants')}`}>
							<div className="copyUrl_div">
							<UrlCopiedPopup group_ID={group._id}/> 
							</div>
						 </td>

						<td  data-label={`${t('main.operation')}`}>
							
							<div className="grouplist_icons_table">

                            <Link to={`/live-rooms/${group.name}/${group._id}/grouplist/1/1`} className="icons_table_btn">
                                <img src={liveImg} className="live_img" alt="liveImg"/>
                            </Link>
                            
                            <Link to={`/offline-rooms/${group.name}/${group._id}/grouplist/1/1`}  className="icons_table_btn">
                                <img src={OfflineImg} className="offline_img" alt="offlineImg"/>
                            </Link>

                            <div className="grouplist__icons_wrapper">
							<Link to={`/edit-group/${group._id}/grouplist/1/1`} style={{textDecoration:"none"}}>
				             <FiEdit className="grouplist_editIcon" />
				            </Link>
				            
						     <PopUp deleteGroup={deleteGroup} _id={group?._id} parseToken={parseToken}/> 

							 <ChatExportPopUp _id={group?._id} parseToken={parseToken}/>
							</div>
							</div>
							   </td>
						   </tr>
					  ))
		   }

        </tbody>
        </table>
              
		<div className="pagination_wrapper">

		{ isLoaded ? (
        <ReactPaginate
		 previousLabel={`${t('main.previous')}`}
		 nextLabel={`${t('main.next')}`}
         pageCount={pageCount}
         onPageChange={handlePageChange}
         containerClassName={"pagination_btn"}
         previousClassName={"previousBtn"}
         activeClassName={"pagination_btn_active"}
        />
         ) : (
        <div>{t('main.noResultsToDisplay')}</div>
        )} 
		</div>

            </div>
        </div>
    )
}

export default GroupList;