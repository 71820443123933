import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { useHistory, useParams, Link } from 'react-router-dom';
import DownloadIcon from '../../../assets/images/download.png';
import axios from '../../../axios';


function OfflineRoomDetails() {
    const { t } = useTranslation();

    const { id , sessionTime, origin, userid, email} = useParams();
    
    const [details, setDetails] = useState([]);

    const getToken = localStorage.getItem("token")
    const parseToken = JSON.parse(getToken)

    useEffect(() => {
       const fetchDetails = async()=>{

        const res = await axios.get(`/room/${id}`, 
          {
            headers: {
            "Authorization": "Bearer " + parseToken 
           } 
          }
         )
          
        // console.log("fetchofflinedetailsRes>>>",res)
          setDetails(res.data);
       }     
       fetchDetails();   
      },[]);

    
      const handleExport =async(e)=>{
        e.preventDefault();

        const exportRes = await axios.get(`/message/export?room=${id}&locale=${new Date().getTimezoneOffset()}`, 
          {
            headers: {
            "Authorization": "Bearer " + parseToken 
           } 
          }
         )

         window.location = exportRes.data.url;


        // axios({
        //   url: `https://api.reso.chat/message/export?room=${id}&locale=${new Date().getTimezoneOffset()}`, 
        //   method: 'GET',
        //   headers: {
        //     "Authorization": "Bearer " + parseToken 
        //  }
        // }).then((response) => {
        //     window.location = response.data.url;
        //  });

      }

    return (
        <div className="accountDetails">

                    <div className="accountDetails_container" style={{marginBottom:"-10px"}}>

                    <div className="accountDetails_title" >
                  <Link to={`/offline-rooms/${details?.room?.group?.name}/${details?.room?.group?._id}/${origin}/${userid}/${email}`} style={{textDecoration:"none"}}>
		              <IoIosArrowRoundBack className="back_icon_centerAlign"/>
	                 </Link>
                  <div style={{marginRight:"20px"}}>{t('main.offlineRoom')}&nbsp;{t('main.details')}</div>
                    </div>  

                           <div className="LiveroomDetails_wrapper" style={{marginTop:"-10px"}}>
                           <table className="details_table">
                             <thead>
                             </thead>
                              <tbody>
                                <tr >
                             <td data-label={`${t('main.Group')} ${t('main.name')}`}>{details?.room?.group?.name}</td>
                             <td data-label={`${t('main.roomID')}`}>{details?.room?._id}</td>
                             <td data-label={`${t('main.startDateTime')}`}>{new Date(`${details?.room?.startDateTime}`).toDateString()} { new Date(`${details?.room?.startDateTime}`).toLocaleTimeString()}</td>
                             <td data-label={`${t('main.sessionTime')}`}>{ sessionTime === "1" ? `${sessionTime} min` : `${sessionTime} mins`}</td>
                             <td data-label={`${t('main.participants')}`}>{details?.participantCount}</td>    
                             <td data-label={`${t('main.messages')}`}> {details?.messageCount}</td> 
                                </tr>
                              </tbody>
                             </table>

                           </div>
                             
                           <div className="rooms_btn_div">                      
                           <button className="export_btn" onClick={handleExport} ><img src={DownloadIcon} alt="Download-img" className="export_img"/>{t('main.export')}</button>
                            </div>
                    </div> 
        </div>
    )
}

export default OfflineRoomDetails;
