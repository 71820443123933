import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosArrowRoundBack } from 'react-icons/io';
import {  useParams, Link } from 'react-router-dom';
import axios from '../../../axios';

function ChatRoomDetails() {
  const { t } = useTranslation();
    const { id  } = useParams();

    const [details, setDetails] = useState([]);

    const getToken = localStorage.getItem("token")
    const parseToken = JSON.parse(getToken)

    useEffect(() => {
       const fetchDetails = async()=>{
        const res = await axios.get(`/room/${id}`, 
          {
            headers: {
            "Authorization": "Bearer " + parseToken  } 
          }
        )
          setDetails(res.data);
       }     
       fetchDetails();   
      },[]);

    return (
        <div className="accountDetails">
                    <div className="accountDetails_container">
                    <div className="accountDetails_title" >
                     <Link to='/chat-rooms' style={{textDecoration:"none"}}>
		               <IoIosArrowRoundBack className="back_icon_centerAlign"/>
	                   </Link>
                     <div style={{marginRight:"20px"}}>{t('main.activeRoomDetails')}</div>
                     </div>
        
                          <div className="LiveroomDetails_wrapper">
                           <table className="details_table">
                             <thead>
                             </thead>
                              <tbody>      
                                <tr >
                             <td  data-label={`${t('main.Group')} ${t('main.name')}`}>{details?.room?.group?.name}</td>
                             <td data-label={`${t('main.roomID')}`}>{details?.room?._id}</td>
                             <td data-label={`${t('main.startDateTime')}`}>{new Date(`${details?.room?.startDateTime}`).toDateString()} { new Date(`${details?.room?.startDateTime}`).toLocaleTimeString()}</td>
                             <td data-label={`${t('main.sessionTime')}`}>{ Math.floor( ( new Date().getTime() - new Date(`${details?.room?.startDateTime}`).getTime() ) / 60000 ) === 1 ? (
                                             `${Math.floor( ( new Date().getTime() - new Date(`${details?.room?.startDateTime}`).getTime() ) / 60000 )} min`
                                              ):(
                                             `${Math.floor( ( new Date().getTime() - new Date(`${details?.room?.startDateTime}`).getTime() ) / 60000 )} mins` 
                                              )
                                              }
                             </td>
                             <td data-label={`${t('main.participants')}`}>{details?.participantCount}</td>    
                             <td data-label={`${t('main.messages')}`}> {details?.messageCount}</td> 
                                </tr>
                              </tbody>
                             </table>
                             
                           </div>

                           <div className="rooms_btn_div"></div>
                    </div> 
        </div>
    )
}

export default ChatRoomDetails;
