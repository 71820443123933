import React, { useEffect, useState } from 'react';
import './questionlist.css';
import {FiEdit} from 'react-icons/fi';
import { RiDeleteBin6Line } from 'react-icons/ri';
import {FaRegEye} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from '../../../axios';
import ReactPaginate from 'react-paginate';
import PopUp from '../PopUp';
import { IoIosArrowRoundBack } from 'react-icons/io';

function QuestionList() {
	  const { t } = useTranslation();

      const [questionDetails,setQuestionDetails]=useState([]);
	  const [pageCount, setPageCount] = useState(1);
	  const [isLoaded, setisLoaded] = useState(false);
	  const [currentPage,setCurrentPage]=useState(1)

	  const getToken = localStorage.getItem("token")
	  const parseToken = JSON.parse(getToken);
	  const userid = localStorage.getItem("userid");
	  const parsedUserid = JSON.parse(userid);

	  useEffect(()=>{
		fetchQuestions(currentPage);
	  },[currentPage])

	  // previously we used the config file fot the url now i am using Axios base url

		const fetchQuestions = async (pageToGet) => {
			const res = await axios.get(`/question?page=${pageToGet}`,{
				headers: {
				  "Authorization": "Bearer " + parseToken
				}
			  })		  
			setQuestionDetails(res.data.questions);
			setPageCount(res.data.count / 10); 
			if(res.data.count >= 1){
				setisLoaded(true); 
			} 	
		 }
	
		   //  reduces sentence
	   function truncate(str , n){
		 return str?.length > n ? str.substr(0,n - 1) + "..." : str;
	   }
 
	   const handlePageChange = ( selectedObject ) => {
		let intSelectedPage = parseInt(selectedObject.selected) + 1;
		setCurrentPage(intSelectedPage);
		fetchQuestions(intSelectedPage);
	  }

	  	 // previously we used the config file fot the url now i am using Axios base url

     	const deleteQuestion = async(id) => {
			    //    console.log(id);
			 try{
				const res=await axios.delete(`/question?id=${id}`,{
					headers: {
					   "Authorization": "Bearer " + parseToken
					 }
				 }) 
				 fetchQuestions(currentPage);
			 }
			 catch(e){
				const message = e.response?.data?.error;
				 console.log(message);
			}
	   }
	
    return (
        <div className="userlist">
        <div className="userlist_wrapper">
    
		<div className="manageVideo_titleWrapper">
            <div className="manageVideo_title">
			<div className="userlist_title">
                <Link to='/' style={{textDecoration:"none"}}>
		        <IoIosArrowRoundBack className="back_icon"/>
	           	</Link> {t('main.questionList')}
                 </div>
				</div>
            <Link to='/create-question' style={{textDecoration:"none"}}>
            <button className="create_btn">{t('main.create')}&nbsp;{t('main.Question')}</button>
            </Link>
         </div>

         <table className="table">
            <thead>
				<tr>
				<th>#</th>
     	    <th>{t('main.description')}</th>
     	    <th>{t('main.language')}</th>
     	    <th>{t('main.subject')}</th>
            <th>{t('main.operation')}</th>
				</tr>
          </thead>
          <tbody>
     	 
		   {
					  questionDetails.map( (question,index) => (
						
						<tr key={question._id} > 
						<td  data-label="#">{index + (currentPage -1) * 10 + 1 }</td>

						<td  data-label={`${t('main.description')}`}>{truncate(question?.description,50)}</td>

						<td  data-label={`${t('main.subject')}`}>{question?.language === 1 ? `${t("main.eng")}` : `${t("main.chi")}`}</td>

						<td  data-label={`${t('main.language')}`}>{question?.subject}</td>

						<td  data-label={`${t('main.operation')}`}>
							
							{/* {console.log("question?.user : " + question?.user)} */}

							{parsedUserid === question?.user ?
								<div className="icons_table">
									<Link to={`edit-question/${question?._id}`} style={{textDecoration:"none"}}>
										<FiEdit className="editIcon" />
									</Link>
									<PopUp deleteQuestion={deleteQuestion} _id={question?._id}/> 
								</div>
								: 
								<div className="icons_table">
									<Link to={`view-question/${question?._id}`} style={{textDecoration:"none"}}>
									     <FaRegEye className="editIcon" />
									</Link>
									<a style={{cursor:"not-allowed"}}><RiDeleteBin6Line className="deleteDisableIcon" /></a>
								</div>
							}
							   </td>

						   </tr>
					  ))
		   }

        </tbody>
        </table>
              
		<div className="pagination_wrapper">

		{ isLoaded ? (
        <ReactPaginate
         previousLabel={`${t('main.previous')}`}
         nextLabel={`${t('main.next')}`}
         pageCount={pageCount}
         onPageChange={handlePageChange}
         containerClassName={"pagination_btn"}
         previousClassName={"previousBtn"}
         activeClassName={"pagination_btn_active"}
        />
         ) : (
        <div>{t('main.noResultsToDisplay')}</div>
        )} 
		</div>

            </div>
        </div>
    )
}

export default QuestionList;
