import React, { useEffect, useState } from 'react';
import axios from '../../../../axios';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IoIosArrowRoundBack } from 'react-icons/io';

const EditGame = () => {
    const { t } = useTranslation();
    const [errors,setErrors]=useState({});

    const history = useHistory();
    const { _id } = useParams();

    const [input,setInput]= useState({
        name:"",
        description:"",
        gameUrl:""
    })
    const getToken = localStorage.getItem("token")
    const parseToken = JSON.parse(getToken)

    const validateInfo=()=>{
        let errors = {};

        if(!input.name){
            errors.name=`${t("main.Game")} ${t("main.name")} ${t("main.required")}`
        } 

        if(!input.description){
            errors.description=`${t("main.description")} ${t("main.required")}`
        }
    
        if(!input.gameUrl){
            errors.gameUrl=`${t("main.Game")} ${t("main.url")} ${t("main.required")}`
        }

        return errors;
     }
 
  useEffect(()=>{
    const fetchGame = async() =>{
    const res=await axios.get(`/game/${_id}`,{
             headers: {
                 "Authorization": "Bearer " + parseToken
         }     
    })
    setInput(res.data)
    }
    fetchGame();
   },[_id,parseToken])

    const handleChange=(e)=>{
        setInput(
         {
             ...input,
             [e.target.name]:e.target.value
         }
        )
    }

   const handleSubmit =  async (e) =>{
       e.preventDefault();
       setErrors(validateInfo())
       if(Object.keys(validateInfo()).length > 0){
        return;
      }else{ 
      try{
       const res = await axios.put(`/game?id=${_id}`,{
           description:input.description,
           gameUrl:input.gameUrl,
           name:input.name,
       },{
        headers: {
            "Authorization": "Bearer " + parseToken
          } 
       })
       if(res.statusText === 'OK'){
           history.push('/manage-games')
       }
      }catch(e){
          console.log(e.response.data.error);
      }
    }
    }

    return (
        <div className="CreateGame">

        <div className="CreateGame_wrapper">
                <div className="accountDetails_title" >
            <Link to='/manage-games' style={{textDecoration:"none"}}>
		        <IoIosArrowRoundBack className="back_icon_centerAlign"/>
	           </Link>
           <div style={{marginRight:"20px"}}> {t("main.edit")} {t("main.Game")}</div>
                    </div>
               <form className="CreateGame_form">

               <div className="form__input_wrapper">
               <label className="form__input_label">{t("main.gameName")}</label>
               <input type="text" name="name"  value={input.name} onChange={handleChange} className="CreateGame_input"  placeholder={`${t("main.enter")} ${t("main.gameName")}`}/>
               {errors.name && <p className="form_dropdown_error">{errors.name}</p>}
               </div>
                
               <div className="form__input_wrapper">
               <label className="form__input_label">{t("main.description")}</label>
               <textarea name="description" value={input.description} onChange={handleChange} className="CreateGame_textarea"  placeholder={`${t("main.enter")} ${t("main.description")}`}></textarea>
               {errors.description && <p className="form_dropdown_error">{errors.description}</p>}
               </div>
                
               <div className="form__input_wrapper">
               <label className="form__input_label">{t("main.Game")} {t("main.url")}</label>
               <input type="gameUrl" name="gameUrl"  value={input.gameUrl} onChange={handleChange} className="CreateGame_input"  placeholder={`${t("main.enter")} ${t("main.Game")} ${t("main.url")}`}/>
               {errors.gameUrl && <p className="form_dropdown_error">{errors.gameUrl}</p>}
               </div>

               <div className="CreateGame_btn_div">
               <button className="tab_btn" onClick={handleSubmit} >{t("main.update")}</button>
               </div>

               </form>
         </div>

        </div>
    )
}

export default EditGame;
