import React, { useEffect, useState } from 'react';
// import { fakeDropValues } from '../../../fakeData';
import axios from '../../../../axios';
import EditPopUp from '../EditPopUp/EditPopUp';
import { useTranslation } from 'react-i18next';
import DeletePopup from './DeletePopup';


const EditOpeningQuestion = ({ groupId ,chatStatus}) => {
    const { t } = useTranslation();

    const getToken = localStorage.getItem("token")
    const parseToken = JSON.parse(getToken)
    // console.log("chatStatus2",chatStatus);
    const [ modalIsOpen,setModalIsOpen ] = useState(false);
    const [ pQuesModal ,setPQuesModal ] = useState(false);
    const [isChecked,setIsChecked]=useState(false);
    const [errors,setErrors]=useState({});

    const [description , setDescription] = useState("");
    const [placement_instruction , setPlacementInstruction] = useState("");
    const [participantAmt ,  setParticipantAmount] = useState()

    const [input,setInput]= useState({
        choices:[
            {_id:"", label:"", identity:0},
            {_id:"", label:"", identity:0},
            {_id:"", label:"", identity:0},
            {_id:"", label:"", identity:0},
            {_id:"", label:"", identity:0}]
    })

    useEffect(()=>{
        const fetchOpeningQuestion = async () => {
            const fetchres=await axios.get(`/group/${groupId}`,{
              headers: {
                  "Authorization": "Bearer " + parseToken
                }
            })
            // console.log("editresOpeningQuestion",fetchres);
            setParticipantAmount(fetchres.data?.participantAmt)
            setInput(fetchres.data?.openingQuestion)
            setPlacementInstruction(fetchres.data?.placement_instruction)
            setDescription(fetchres.data?.openingQuestion?.description)
            setIsChecked(fetchres.data?.openingQuestion?.individualRoom === 1 ? true : false )
           }
           fetchOpeningQuestion();
     },[groupId,parseToken])



    const handleChoicesChange = (index, fieldType, targetValue) => {

        let newChoices = [];
        
        if ([...input.choices] !== undefined && [...input.choices].length > 0) {
            newChoices = [...input.choices]; // copying the old datas array
        } else {
            newChoices = [            
            {_id:"", label:"", identity:0},
            {_id:"", label:"", identity:0},
            {_id:"", label:"", identity:0},
            {_id:"", label:"", identity:0},
            {_id:"", label:"", identity:0}];
        }
        
        if(fieldType === 'label') {
            newChoices[index].label = targetValue; 
        } else {
            newChoices[index].identity = targetValue; 
        }
        setInput({choices: newChoices});
    }

    const validateInfo=()=>{
        let errors = {};
       
        if(!description){
            errors.question=`${t("main.Question")} ${t("main.required")}`
        } 

        if(!placement_instruction){
            errors.placement_instruction=`${t("main.placementInstruction")} ${t("main.required")}`
        }

        let totalIdentity = 0;
        let identityError = false;
        for(let i=0; i<5; i++) {
            // console.log("label",input.choices[i].label , "identity" , input.choices[i].identity );
            if(input.choices[i] !== undefined && input.choices[i].label !== "") {
               totalIdentity = totalIdentity + parseInt(input.choices[i].identity);
            }else if(input.choices[i]?.label === "" && input.choices[i]?.identity > 0 ){
                identityError = true ;
            }
        }

       if(totalIdentity > 0 && totalIdentity !== participantAmt || identityError ) {
          errors.choices=`* ${t("main.makesureIdentitiesEqualTo")} *`
       }

        return errors;
     }

     const clearInputValues = async() =>{
        setPQuesModal(false)
        setInput({
            choices:[
            {_id:"", label:"", identity:0},
            {_id:"", label:"", identity:0},
            {_id:"", label:"", identity:0},
            {_id:"", label:"", identity:0},
            {_id:"", label:"", identity:0}]
        })
        setDescription("")
        setPlacementInstruction("")
        setIsChecked(false)
        try{
             await axios.put(`/group?id=${groupId}`,{
                placement_instruction:"",
                openingQuestion:{
                    description : "",
                    individualRoom:0,
                    choices: []
                    }
            },{
                headers: {
                    "Authorization": "Bearer " + parseToken
               }
            })
        }catch(err){
              console.log("error",JSON.stringify(err))
        }
      }

    
    const handleSubmit =async(e)=>{
        e.preventDefault();
        setErrors(validateInfo());
        if(Object.keys(validateInfo()).length > 0){
            return;
        }else{
        try{
           
            let CheckBoxValue;
            if(isChecked === false){
                 CheckBoxValue = 0
             }else{
                  CheckBoxValue = 1
             }

            let selChoices = [];

            for(let i=0; i<5; i++) {
                 if(input.choices[i] !== undefined && input.choices[i] !== "") {
                    selChoices.push(
                        {
                            label: input.choices[i].label,
                            identity:input.choices[i].identity 
                        }
                    ) 
                 }   
            }

            const res = await axios.put(`/group?id=${groupId}`,{
                placement_instruction:placement_instruction,
                openingQuestion:{
                    description : description,
                    individualRoom:CheckBoxValue,
                    choices: selChoices
                    }
            },{
                headers: {
                    "Authorization": "Bearer " + parseToken
               }
            })

            // console.log(res);

             if(res.statusText === 'OK'){
                setModalIsOpen(true)
             }   
        }catch(err){
              console.log("error",JSON.stringify(err))
            // console.log(e.response.data.error);
        }
    }
    }


    return (
        <div className="createVideo">

        <div className="createVideo_wrapper">
            <div className="createVideo_title">
            {chatStatus === 0 ? `${t('main.edit')} ${t('main.placement')} ${t('main.Question')}` : `${t('main.view')} ${t('main.placement')} ${t('main.Question')}`}
            </div>

               <form className="openingQuestion_form">

               <div className="form__input_wrapper formManageGroup__input_wrapper">
                        <label className="form__input_label formManageGroup__input_label">{`${t("main.placementInstruction")}`}</label> 
               <input type="text"  value={placement_instruction} onChange={(e)=>setPlacementInstruction(e.target.value)} className="createVideo_input" placeholder={`${t("main.enter")} ${t("main.placementInstruction")}`}></input>
               {errors.placement_instruction && <p className="form_dropdown_error">{errors.placement_instruction}</p>}
               </div>

               <div className="form__input_wrapper formManageGroup__input_wrapper">
                        <label className="form__input_label formManageGroup__input_label">{`${t("main.Question")}`}</label> 
               <textarea name="description" value={description} onChange={(e)=>setDescription(e.target.value)} className="openingQuestion_textarea"  placeholder={`${t("main.enter")} ${t("main.Question")}`}></textarea>
               {errors.question && <p className="form_dropdown_error">{errors.question}</p>}
                  </div>

                        <div className="openingQuestion__checkbox-wrapper">
                        <input type="checkbox" checked={isChecked} onChange={(e)=>setIsChecked(e.target.checked)}/>
                        <span className="checkbox_name"> {`${t("main.onlyOneTypeOfParticipantInARoom")}`} </span>
                        </div>
                 </form>

                     <form className="openingQuestion_ChoiceForm">

                        <div className="openingQuestion_ChoiceForm1">    

                        <div className="openingQuestion_ChoiceField">

                          <div className="choice_div">
                          <p>{t('main.choice')} 1</p>
                         <input type="text" name="choice1"  value={input?.choices[0]?.label} onChange={(e)=>handleChoicesChange(0,'label',e.target.value)} className="openingQuestion_input"  placeholder={`${t("main.enter")} ${t("main.choice")} 1`}/>
                          </div>         


                         <div className="identity_div">
                          <p>{t('main.maximumParticipants')}</p>
                        <input className="openingQuestion_input" type="number" value={input?.choices[0]?.identity} onChange={(e)=>handleChoicesChange(0,'identity',e.target.value)} />
                        </div>

                        </div>

                        <div className="openingQuestion_ChoiceField">

                        <div className="choice_div">
                        <p>{t('main.choice')} 2</p>
                        <input type="text" name="choice2"  value={input?.choices[1]?.label} onChange={(e)=>handleChoicesChange(1,'label',e.target.value)} className="openingQuestion_input"  placeholder={`${t("main.enter")} ${t("main.choice")} 2`}/>
                        </div>


                          <div className="identity_div">
                          <p>{t('main.maximumParticipants')}</p>
                        <input className="openingQuestion_input" type="number" value={input?.choices[1]?.identity} onChange={(e)=>handleChoicesChange(1,'identity',e.target.value)} />
                        </div>

                  </div>

                        <div className="openingQuestion_ChoiceField">

                        <div className="choice_div">
                        <p>{t('main.choice')} 3</p>
                        <input type="text" name="choice3"  value={input?.choices[2]?.label} onChange={(e)=>handleChoicesChange(2,'label',e.target.value)} className="openingQuestion_input"  placeholder={`${t("main.enter")} ${t("main.choice")} 3`}/>
                        </div>


                        <div className="identity_div">
                          <p>{t('main.maximumParticipants')}</p>
                        <input className="openingQuestion_input" type="number" value={input?.choices[2]?.identity} onChange={(e)=>handleChoicesChange(2,'identity',e.target.value)} />
                        </div>

                        </div>


                       </div>

                       <div className="openingQuestion_ChoiceForm2">

                       <div className="openingQuestion_ChoiceField">

                       <div className="choice_div">
                       <p>{t('main.choice')} 4</p>
                       <input type="text" name="choice4"  value={input?.choices[3]?.label} onChange={(e)=>handleChoicesChange(3,'label',e.target.value)} className="openingQuestion_input"  placeholder={`${t("main.enter")} ${t("main.choice")} 4`}/>
                        </div>
                       

                          <div className="identity_div">
                          <p>{t('main.maximumParticipants')}</p>
                        <input className="openingQuestion_input" type="number" value={input?.choices[3]?.identity} onChange={(e)=>handleChoicesChange(3,'identity',e.target.value)} />
                        </div>

                      </div>

                        <div className="openingQuestion_ChoiceField">

                        <div className="choice_div">
                        <p>{t('main.choice')} 5</p>
                        <input type="text" name="choice5"  value={input?.choices[4]?.label} onChange={(e)=>handleChoicesChange(4,'label',e.target.value)}  className="openingQuestion_input"  placeholder={`${t("main.enter")} ${t("main.choice")} 5`}/>
                        </div>
                        

                        <div className="identity_div">
                          <p>{t('main.maximumParticipants')}</p>
                        <input className="openingQuestion_input" type="number" value={input?.choices[4]?.identity} onChange={(e)=>handleChoicesChange(4,'identity',e.target.value)} />
                        </div>

                        </div>

                        <div className="ChatGroupCreate_Disclaimer" style={{marginTop:"20px"}}>                                                                                                                    
                     {errors.choices && <p className="form_dropdown_error">{errors.choices}</p>}
                       </div>

                       </div>
                          
               </form>
                             
                              <div className="tab_btn_div">
                              {chatStatus === 0 &&  <EditPopUp modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} handleSubmit={handleSubmit}/>}
                               
                              {chatStatus === 0 &&  <DeletePopup pQuesModal={pQuesModal} setPQuesModal={setPQuesModal} clearInputValues={clearInputValues} path="EPlacementQues"/> }
                              
                              </div>
        
         </div>

        </div>
    )
}

export default EditOpeningQuestion;
