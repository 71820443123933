import React, { useEffect, useState } from 'react';
import './adminHome.css';
import {Link} from 'react-router-dom';
import chatSvg from './images/work_chat.svg';
import wave_svg from './images/Frame2.png';
import { useTranslation } from 'react-i18next';
// import { useDataLayerValue } from '../../../datalayer';
import JWT from 'jsonwebtoken';

const AdminHomepage = () => {
     const { t } = useTranslation();
    //  const [{},dispatch]=useDataLayerValue();
     const getToken = localStorage.getItem("token")
     const parseToken = JSON.parse(getToken)
     const userData = JWT.decode(parseToken)
       
    //  console.log(userData?.role);
    return (
        <div className="admin_home">
            <div className="admin_home_container">
                  <div className="admin_home_left">
                    
                    {
                        userData?.role === 1 ? (
                      <>
                      <div className="admin_button_top">
                      <Link to='/users-list' className="manage_btn_link"><button className="admin_link">{t('main.manage')} <br/> {t('main.accounts')}  </button></Link>
                      <Link to='/manage-hosts'  className="manage_btn_link"><button className="admin_link">{t('main.manage')} <br/> {t('main.hosts')} </button></Link>
                     </div>
                      
                      <div className="admin_button_middle">
                      <Link to='/group-list' className="manage_btn_link"><button className="admin_link">{t('main.manage')} <br/> {t('main.roomTemplates')} </button></Link>
                      <Link to='/chat-rooms' className="manage_btn_link"><button className="admin_link">{t('main.manage')} <br/> {t('main.activeRooms')} </button></Link>
                      </div>

                      <div className="admin_button_bottom">
                      <Link to='/manage-games' className="manage_btn_link"><button className="admin_link">{t('main.manage')} <br/> {t('main.games')} </button></Link>
                      <Link to='/manage-videos' className="manage_btn_link"><button className="admin_link">{t('main.manage')} <br/> {t('main.videos')} </button></Link>
                      </div>
                      </>
                       ):(
                            <div className="admin_button_bottom">
                            <Link to='/group-list'className="manage_btn_link"><button className="admin_link">{t('main.manage')} <br/> {t('main.roomTemplates')} </button></Link>
                            <Link to='/chat-rooms'className="manage_btn_link" ><button className="admin_link">{t('main.manage')} <br/> {t('main.activeRooms')}</button></Link>
                            </div>
                        )
                    }
                      

                  </div>

                  <div className="admin_home_right">
                      <img src={chatSvg} className="chat_svg" alt='chat_interface-svg'/>
                  </div>    
            </div>

            <img src={wave_svg} className="wave_svg" alt="wave_svg"/>

        </div>
    )
}

export default AdminHomepage
