import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiDeleteBin6Line } from 'react-icons/ri';
import Modal from 'react-modal';
import { customStyles } from '../../ModalLayout';


Modal.setAppElement('#root')
function PopUp({_id , deleteGame }) {
    const { t } = useTranslation();
    const [ modalIsOpen,setModalIsOpen ] = useState(false);

    return (
        <div>
            <RiDeleteBin6Line className="deleteIcon" onClick={()=>setModalIsOpen(true)} />
            <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen (false) }  style={customStyles}>
                <div className="modal_wrapper">
                <h3>{t("main.doYouWantToDeleteThe")} {t("main.game")} ?</h3>
               <div className="modal_btns">
               <button className="modal_red_btn" onClick={()=> {
                    deleteGame(_id)
                    setModalIsOpen (false)
                    }}>{t("main.yes")} </button>
                <button className="modal_blue_btn" onClick={()=>setModalIsOpen(false)}>{t("main.no")} </button></div> 
               </div>
            </Modal>
        </div>
    )
}

export default PopUp;
