import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import axios from '../../../axios';
import './selectgame.css';
import { useDataLayerValue } from '../../../datalayer';
import PopUp from '../../Groups/CreateGroup/PopUp/PopUp';
import { useHistory } from 'react-router-dom';


const SelectGame = () => {
    const { t } = useTranslation();
    const history=useHistory();
    const [{groupId },dispatch] = useDataLayerValue();

    const [radio,setRadio]=useState();
    const [Submiterrors,setSubmiterrors]=useState({});
    const [ modalIsOpen,setModalIsOpen ] = useState(false);

    const [gameDetails,setGameDetails]=useState([]);
   
    const getToken = localStorage.getItem("token")
	  const parseToken = JSON.parse(getToken);
   
      useEffect(()=>{
       if(!groupId){
          history.push('/create-group')
       }
	     	fetchGames();
	  },[groupId,history])

      const fetchGames= async()=>{
         try{
            const res = await axios.get(`/game`,{
				headers: {
				  "Authorization": "Bearer " + parseToken
				}
			 })	
             setGameDetails(res.data.games);
         } 
         catch(e) {
            console.log(e.response?.data?.error); 
          }
      }

      const submitValidateInfo=()=>{
        let Submiterrors = {};
        if(!radio){
            Submiterrors.radio=`${t("main.select")} ${t("main.game")} !`
        }
        return Submiterrors;
     }


      const handleSubmit=async(e)=>{
        e.preventDefault();
        setSubmiterrors(submitValidateInfo());
        if(Object.keys(submitValidateInfo()).length > 0){
            return;
        }else{ 
        try{
            const res = await axios.put(`/group?id=${groupId}`,{
              game:radio
            },{
                headers: {
                    "Authorization": "Bearer " + parseToken
               }
            })

            if(res.statusText === 'OK'){
               setModalIsOpen(true)
            } 
          }catch(e){
            console.log(e.response.data.error);
          }
        }
    }

  
         //  reduces sentence
   function truncate(str , n){
       return str?.length > n ? str.substr(0,n - 1) + "..." : str;
   }

    return (
           <div className="gamelist">
        <div className="gamelist_wrapper">
        <div className="game_title">{t('main.select')}&nbsp;{t('main.Game')}</div>

         <table className="game_table">
            <thead>
				<tr>
     	    <th>{t('main.select')}</th>
     	    <th>#</th>
     	    <th>{t('main.description')}</th>
     	    <th>{t('main.gameName')}</th>
				</tr>
          </thead>
          <tbody>
     	 

		   {
					  gameDetails.map( (game,index) => (
                          <tr key={index}>
                        <td  data-label={`${t('main.select')}`}>
                        <input type="radio" checked={radio === `${game._id}`} value={game._id} onChange={(e)=>setRadio(e.target.value)}/>
                        </td>

						<td  data-label="#">{index + 1 }</td>

						<td  data-label={`${t('main.description')}`}>{truncate(game.description,75)}</td>

						<td  data-label={`${t('main.gameName')}`}>{game?.name}</td>
						   </tr>
					  ))
		   }
		   
        </tbody>
        </table>
         
        {Submiterrors.radio && <p className="submit_error" style={{marginTop:"40px",marginBottom:"0px"}}>{Submiterrors.radio}</p>}
		 <div className="tab_btn_div">
    <PopUp modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} handleSubmit={handleSubmit} title={`${t('main.added')} ${t('main.Game')} ${t('main.successfully')}`}/>
     </div> 
            </div>
        </div>
    )
}

export default SelectGame
