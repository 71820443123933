import React, { useEffect, useState } from 'react';
import './OpeningQuestion.css';
import { useHistory} from 'react-router-dom';
// import { fakeDropValues } from '../../../../fakeData';
import axios from '../../../../../axios';
import PopUp from '../../PopUp/PopUp';
import { useDataLayerValue } from '../../../../../datalayer';
import { useTranslation } from 'react-i18next';

const OpeningQuestion = () => {
    const { t } = useTranslation();
    const [{ groupId },dispatch]=useDataLayerValue();
    const history = useHistory();
    
    const [ modalIsOpen,setModalIsOpen ] = useState(false);
    const [isChecked,setIsChecked]=useState(false);
    const [errors,setErrors]=useState({});

    useEffect(()=>{
        if(!groupId){
           history.push('/create-group')
        }
    },[groupId,history])

    const [input,setInput]= useState({
        description:"",
        placement_instruction:"",
        choice1:"",
        choice2:"",
        choice3:"",
        choice4:"",
        choice5:"", 
    })

    const [identity1,setIdentity1]=useState(0);
    const [identity2,setIdentity2]=useState(0);
    const [identity3,setIdentity3]=useState(0);
    const [identity4,setIdentity4]=useState(0);
    const [identity5,setIdentity5]=useState(0);

    const getToken = localStorage.getItem("token")
    const parseToken = JSON.parse(getToken)

    const handleChange=(e)=>{
        setInput(
         {
             ...input,
             [e.target.name]:e.target.value
         }
        )
    }

    const validateInfo = async ()=>{
        let errors = {};
    
        if(!input.description){
            errors.question=`${t("main.Question")} ${t("main.required")}`
        }

        if(!input.placement_instruction){
            errors.placement_instruction=`${t("main.placementInstruction")} ${t("main.required")}`
        }

        let group = await axios.get(`/group/${groupId}`, {
            headers: {
                "Authorization": "Bearer " + parseToken
            }
        });
        let { participantAmt } = group.data;

        let ChoiceArray = [input?.choice1,input?.choice2,input?.choice3,input?.choice4,input?.choice5]
        let IdentityArray = [identity1,identity2,identity3,identity4,identity5]
        let choiceError = false;
        let totalIdentity = 0;

       for(let i=0 ; i < 5 ; i ++){
          if(ChoiceArray[i] === ""  &&  Number(IdentityArray[i]) > 0 ){
            choiceError = true; 
          }
       }

       for(let i=0 ; i < 5 ; i ++){
        if(Number(IdentityArray[i]) !== "" ){
            totalIdentity +=  Number(IdentityArray[i])
        }
       }

        // console.log( totalIdentity, participantAmt );

        if(totalIdentity > 0 && totalIdentity !== participantAmt || choiceError ) {
            errors.choices=`* ${t("main.makesureIdentitiesEqualTo")} *`
         }
    
        return errors;
     }
    
    const handleSubmit =async(e)=>{
        e.preventDefault();
        const errors = await validateInfo();
        setErrors(errors);
        if(Object.keys(errors).length > 0){
            return;
        }else{
        try{
            let CheckBoxValue;
            if(isChecked === false){
                 CheckBoxValue = 0
             }else{
                  CheckBoxValue = 1
             }
             
            const res = await axios.put(`/group?id=${groupId}`,{
                placement_instruction:input.placement_instruction,
                openingQuestion:{
                    description :input.description,
                    individualRoom:CheckBoxValue,
                    choices: [
                           {
                               label: input.choice1,
                               identity:identity1, 
                           }, 
                           {
                              label: input.choice2,
                              identity:identity2, 
                           }, 
                           {
                            label: input.choice3,
                            identity:identity3, 
                           }, 
                           {
                           label: input.choice4,
                           identity:identity4, 
                           }, 
                            {
                            label: input.choice5,
                           identity:identity5, 
                          }, 
                       ]
                    }
            },{
                headers: {
                    "Authorization": "Bearer " + parseToken
               }
            })
            // console.log(res);
             if(res.statusText === 'OK'){
                setModalIsOpen(true)
             }   
        }catch(e){
            console.log(e.response?.data?.error);
        }
    }
    }


    return (
        <div className="createVideo">

        <div className="createVideo_wrapper">
        <div className="createVideo_title">{t('main.create')}&nbsp;{t('main.placement')}&nbsp;{t('main.Question')}</div>

               <form className="openingQuestion_form">

               <div className="form__input_wrapper formManageGroup__input_wrapper">
                        <label className="form__input_label formManageGroup__input_label">{`${t("main.placementInstruction")}`}</label> 
               <input type="text" name="placement_instruction" value={input.placement_instruction} onChange={handleChange} className="createVideo_input" placeholder={`${t("main.enter")} ${t("main.placementInstruction")}`}></input>
               {errors.placement_instruction && <p className="form_dropdown_error">{errors.placement_instruction}</p>}
               </div>
              
               <div className="form__input_wrapper formManageGroup__input_wrapper">
                        <label className="form__input_label formManageGroup__input_label">{`${t("main.Question")}`}</label> 
               <textarea name="description" value={input.description} onChange={handleChange} className="openingQuestion_textarea"  placeholder={`${t("main.enter")} ${t("main.Question")}`}></textarea>
               {errors.question && <p className="form_dropdown_error">{errors.question}</p>}
               </div>

                        <div className="openingQuestion__checkbox-wrapper">
                        <input type="checkbox" checked={isChecked} onChange={(e)=>setIsChecked(e.target.checked)}/>
                        <span className="checkbox_name">{t("main.onlyOneTypeOfParticipantInARoom")}</span>
                        </div>
                     
                 </form>

                     <form className="openingQuestion_ChoiceForm">
                        <div className="openingQuestion_ChoiceForm1">    

                        <div className="openingQuestion_ChoiceField">
                        
                        <div className="choice_div">
                        <p>{t('main.choice')} 1</p>
                        <input type="text" name="choice1"  value={input.choice1} onChange={handleChange} className="openingQuestion_input"  placeholder={`${t("main.enter")} ${t("main.choice")} 1`}/>
                        </div>   

                        <div className="identity_div">
                          <p>{t('main.maximumParticipants')}</p>
                       <input className="openingQuestion_input" type="number" value={identity1} onChange={(e)=>setIdentity1(e.target.value)} />
                        </div>
                        </div>   

                        <div className="openingQuestion_ChoiceField">
                      
                        <div className="choice_div">
                        <p>{t('main.choice')} 2</p>
                        <input type="text" name="choice2"  value={input.choice2} onChange={handleChange} className="openingQuestion_input"  placeholder={`${t("main.enter")} ${t("main.choice")} 2`}/>
                        </div>
                          
                        <div className="identity_div">
                        <p>{t('main.maximumParticipants')}</p>
                        <input className="openingQuestion_input" type="number" value={identity2} onChange={(e)=>setIdentity2(e.target.value)} />
                        </div>
                     </div>

                        <div className="openingQuestion_ChoiceField">
                          
                        <div className="choice_div">
                        <p>{t('main.choice')} 3</p>
                        <input type="text" name="choice3"  value={input.choice3} onChange={handleChange} className="openingQuestion_input"  placeholder={`${t("main.enter")} ${t("main.choice")} 3`}/>
                        </div>

                        <div className="identity_div">
                          <p>{t('main.maximumParticipants')}</p>
                          <input className="openingQuestion_input" type="number" value={identity3} onChange={(e)=>setIdentity3(e.target.value)} />
                        </div>
                        </div>

                       </div>

                       <div className="openingQuestion_ChoiceForm2">

                       <div className="openingQuestion_ChoiceField">
                       
                       <div className="choice_div">
                       <p>{t('main.choice')} 4</p>
                       <input type="text" name="choice4"  value={input.choice4} onChange={handleChange} className="openingQuestion_input"  placeholder={`${t("main.enter")} ${t("main.choice")} 4`}/>
                       </div>

                       <div className="identity_div">
                          <p>{t('main.maximumParticipants')}</p>
                          <input className="openingQuestion_input" type="number" value={identity4} onChange={(e)=>setIdentity4(e.target.value)} />

                      </div>
                      </div>

                        <div className="openingQuestion_ChoiceField">
                        
                        <div className="choice_div">
                        <p>{t('main.choice')} 5</p>
                        <input type="text" name="choice5"  value={input.choice5} onChange={handleChange}  className="openingQuestion_input"  placeholder={`${t("main.enter")} ${t("main.choice")} 5`}/>
                        </div>
                        
                        <div className="identity_div">
                          <p>{t('main.maximumParticipants')}</p>
                          <input className="openingQuestion_input" type="number" value={identity5} onChange={(e)=>setIdentity5(e.target.value)} />
                       
                       </div>
                        </div>

                        <div className="ChatGroupCreate_Disclaimer" style={{marginTop:"20px"}}>                                                                                                                    
                        {errors.choices && <p className="form_dropdown_error">{errors.choices}</p>}
                       </div>
                       
                       </div>
                          
               </form>

                      

                              <div className="tab_btn_div">
                         <PopUp modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} handleSubmit={handleSubmit} title={`${t('main.added')} ${t('main.opening')} ${t('main.Question')} ${t('main.successfully')}`}/>
                                 </div>
        
         </div>

        </div>
    )
}

export default OpeningQuestion;
