import React, {  useState } from 'react';
import {Link, useHistory} from "react-router-dom";
import axios from '../../../axios';
import { IoIosArrowRoundBack } from 'react-icons/io';
import './resetPassword.css';

function ResetPassword() {
    const history = useHistory();

    const [input,setInput] = useState({
        loginId:"",
        password:"",
        token:""
    })
    
    const [errors,setErrors]=useState({});
    const [message,setMessage]=useState('')

    const handleChange=(e)=>{
        setInput(
         {
             ...input,
             [e.target.name]:e.target.value
         }
        )
    }
     
    const validateInfo=()=>{
        let errors = {};

        if(!input.loginId){
            errors.loginId="Email Required"
        }

        if(!input.password){
        errors.password="Password required"
        }

        if(!input.token){
            errors.token="Reset Token Required"
        } 
        return errors;
     }

    const handleSubmit = async(event)=> {
        event.preventDefault();
        setErrors(validateInfo(input));

        if(Object.keys(validateInfo(input)).length > 0){
            return;
        }else{
            try {
                const res = await axios.post("/auth/reset",{
                    loginId:input.loginId,
                    token:input.token,
                    password:input.password
                   }) 
                     
                    if(res.data.success === true) {
                        history.push('/login')
                        alert('Password updated successfully')
                     }
              }
            catch(e) {
                const message = e.response.data.error;
                setMessage(`${message} !`)
                if(e.response.data.success === false){
                    setMessage("Invalid Token !")
                } 
            }
        }  
     }

   
    return (
        <div className="Login">
            <div className="Homepage__bgbox1 hide_on_mobile" />
            <div className="Homepage__bgbox2 hide_on_mobile" />

            <div className="reset__main">

                <div className="Login__left">
                    <div className="forgot_title">Reset Password</div>
                    <div className="forgot_description">Enter email id, reset token and new password to reset password</div>
                </div>
               
                <form className="Login__right" autoComplete="off">

                    <div>
                <Link to='/login' style={{textDecoration:"none"}}><IoIosArrowRoundBack className="back_icon" style={{color:"white",fontSize:"50px",marginBottom:"15px"}}/></Link>
		            </div>

                    <div className="Login__right_form">

                        <div className="form__input_wrapper">
                        <label className="form__input_label">Email</label>
                        <input className="Login__field" type="text"  name="loginId" value={input.loginId} onChange={handleChange} placeholder="Enter Email"/>
                        {errors.loginId && <p className="form_dropdown_error formLogin__input_error">{errors.loginId}</p>}
                        </div>

                        <div className="form__input_wrapper">
                        <label className="form__input_label">Reset Token</label>
                        <input className="Login__field" type="text" name="token" value={input.token} onChange={handleChange} placeholder="Enter Reset Token"/>
                        {errors.token && <p className="form_dropdown_error formLogin__input_error">{errors.token}</p>}
                        </div>

                        <div className="form__input_wrapper">
                        <label className="form__input_label">Password</label>
                       <input className="Login__field" type="text" name="password" value={input.password} onChange={handleChange} placeholder="Enter Password"/>
                        {errors.password && <p className="form_dropdown_error formLogin__input_error">{errors.password}</p>}
                        </div>

                          <p className="login_backend_error">{message}</p>
                    </div>

                               
                   <button className="Login__button" onClick={handleSubmit}>Submit</button> 
                    
                </form>
            </div>
        </div>
    )
}

export default ResetPassword;