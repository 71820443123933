import React, { useEffect, useState } from 'react';
import './EditGroup.css';
import { Tabs,Tab, AppBar } from '@material-ui/core';
import Header from '../../Header';
import EditGroupProperties from './EditProperties/EGroupProperties';
import EditOpeningQuestion from './EditProperties/EOpeningQuestion';
import EditGroupQuestions from './EditProperties/EGroupQuestions';
import EditSelectVideo from './EditProperties/ESelectVideo';
import EditSelectGame from './EditProperties/ESelectGame';
import { Link, useParams } from 'react-router-dom';
// import { useDataLayerValue } from '../../../datalayer';
import axios from '../../../axios';
import { useTranslation } from 'react-i18next';
import { IoIosArrowRoundBack } from 'react-icons/io';


const EditGroup = props => {
  const { t } = useTranslation();
  const getToken = localStorage.getItem("token")
  const parseToken = JSON.parse(getToken);

    const { _id, origin, id, email } = useParams();
    
    
  const [selectedTab , setSelectedTab] = useState(0);
  const [chatStatus,setChatStatus]=useState();
  // console.log("chatStatus",chatStatus);

  useEffect(()=>{
     const fetchGroupStatus = async () =>{
            const res = await axios.get(`/group/boolActive/${_id}`,{
                headers: {
                    "Authorization": "Bearer " + parseToken
                  }
            });
            // console.log(res);
           setChatStatus(res.data?.status)
        }
      fetchGroupStatus();
  },[_id,parseToken])
  
  const handleChange = (event , newValue) =>{
        setSelectedTab(newValue)
  }

  const linkTo =  origin === 'viewgroups' ? '/view-groups/'+email+'/' + id : '/group-list'; 

 return (
    <>
     <Header/>
     <div className="tab_container">

          <div className="accountDetails_title" style={{marginBottom:"-12px"}} >
               <Link to={linkTo} style={{textDecoration:"none",marginTop:"4px"}}>
		        <IoIosArrowRoundBack className="back_icon_centerAlign"/>
	           </Link>
           <h2 className="tabs_mainTitle" style={{marginRight:"20px"}}>{chatStatus === 0 ? `${t('main.edit')} ${t('main.Group')}` : `${t('main.view')} ${t('main.Group')}`} </h2>
                    </div>
       <div className='tab_wrapper'>
            <AppBar position="static" className="app_bar">
            <Tabs TabIndicatorProps={{style: {width:"17%",margin:"0px 10px"}}}  className="tabs"
                            variant="fullWidth" value={selectedTab} onChange={handleChange}>
                <Tab style={{textTransform:"capitalize"}} className="tab_title" label={`${t('main.mainProperties')}`} />
                <Tab style={{textTransform:"capitalize"}} className="tab_title" label={`${t('main.placement')} ${t('main.Question')}`}/>
                <Tab style={{textTransform:"capitalize"}} className="tab_title" label={`${t('main.chat')} ${t('main.Questions')}`}/>
                <Tab style={{textTransform:"capitalize"}} className="tab_title" label={`${t('main.Video')}`}/>
                <Tab style={{textTransform:"capitalize"}} className="tab_title" label={`${t('main.Game')}`}/>
             </Tabs>
            </AppBar>
       </div>

       {selectedTab === 0 && <EditGroupProperties groupId={_id} chatStatus={chatStatus}/> }
       {selectedTab === 1 && <EditOpeningQuestion groupId={_id} chatStatus={chatStatus}/> }
       {selectedTab === 2 && <EditGroupQuestions groupId={_id} chatStatus={chatStatus} /> }
       {selectedTab === 3 && <EditSelectVideo  groupId={_id} chatStatus={chatStatus}/> }
       {selectedTab === 4 && <EditSelectGame  groupId={_id}  chatStatus={chatStatus}/> }
       

     </div>
    </>
 )
}

export default EditGroup;
