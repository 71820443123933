export const customStyles = {
    overlay:{
        
    },
    content: {
      top: '35%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      minWidth: '42%',
      maxWidth: '70%',
      transform: 'translate(-50%, -10%)',
    }
  };

  // CSS for Success Dialog Box for create and edit group template
  export const blueBgCustomStyles = {
    overlay:{
        
    },
    content: {
      top: '35%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      minWidth: '40%',
      maxWidth: '70%',
      transform: 'translate(-50%, -10%)',
      color:"white",
      background:" #1e45a1",
    }
  };