import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { blueBgCustomStyles } from '../../../ModalLayout';
import './PopUp.css';

Modal.setAppElement('#root')
function PopUp({ modalIsOpen , setModalIsOpen, handleSubmit ,title , title2}) {
    const { t } = useTranslation();
   
    return (
        <div>
            <button className="tab_btn" onClick={handleSubmit}>{t("main.submit")}</button>
            <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen (false) }  style={blueBgCustomStyles}>
                <div className="modal_wrapper">
                <p className="createGroup_ModalTitle">{title}</p>

                <button className="create_btn" onClick={()=> setModalIsOpen(false)}>{t("main.ok")}</button>
                </div>
            </Modal>
        </div>
    )
}

export default PopUp;
