import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiEdit } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import './manageGames.css';
import PopUp from './PopUp';
import ReactPaginate from 'react-paginate';
import axios from '../../../axios';
import { IoIosArrowRoundBack } from 'react-icons/io';

const ManageGames = () => {
    const { t } = useTranslation();

    const [gameDetails,setGameDetails]=useState([]);
	const [pageCount, setPageCount] = useState(1);
	const [isLoaded, setisLoaded] = useState(false);
	const [currentPage,setCurrentPage]=useState(1)

    const getToken = localStorage.getItem("token")
	const parseToken = JSON.parse(getToken);

      useEffect(()=>{
		fetchGames(currentPage);
	  },[currentPage])

      const fetchGames= async(pageToGet)=>{
         try{
            const res = await axios.get(`/game?page=${pageToGet}`,{
				headers: {
				  "Authorization": "Bearer " + parseToken
				}
			 })	
             
             setGameDetails(res.data.games);
             setPageCount(res.data.count / 10); 
             setisLoaded(true); 
         } 
         catch(e) {
            console.log(e.response?.data?.error); 
          }
      }

	   const handlePageChange = ( selectedObject ) => {
		let intSelectedPage = parseInt(selectedObject.selected) + 1;
		setCurrentPage(intSelectedPage);
		fetchGames(intSelectedPage);
	  }

    const deleteGame = async(id) => {
           try{
            await axios.delete(`/game?id=${id}`,{
                headers: {
                   "Authorization": "Bearer " + parseToken
                 }
             }) 
             fetchGames(currentPage);
         }
         catch(e){
           console.log(e.response.data.error);
        }
    }
  
    //  reduces sentence
  function truncate(str , n){
    return str?.length > n ? str.substr(0,n - 1) + "..." : str;
   }

    return (
              <div className="manageGame">
        <div className="manageGame_wrapper">

            <div className="manageGame_titleWrapper">
            <div className="manageGame_title">
              <div className="userlist_title">
           <Link to='/' style={{textDecoration:"none"}}>
		     <IoIosArrowRoundBack className="back_icon"/>
		      </Link>
              {t('main.manageGamesForWaitingRoom')}</div>
           </div>
            <Link to='/create-game' style={{textDecoration:"none"}}>
            <button className="create_btn"> {t('main.create')}&nbsp;{t('main.Game')}</button>
            </Link>
            </div>
       

         <table className="table">
            <thead>
				<tr>
     	    <th>#</th>
             <th>{t('main.gameName')}</th>
     	    <th>{t('main.description')}</th>
             <th>{t('main.operation')}</th>
				</tr>
          </thead>
          <tbody>
     	 

		   {
					  gameDetails.map( (game,index) => (
                           <tr key={index}>
						<td  data-label="#">{index + (currentPage -1) * 10 + 1 }</td>
                        
                        <td  data-label={`${t('main.gameName')}`}>{game?.name}</td>

						<td  data-label={`${t('main.description')}`}>{truncate(game.description,75)}</td>
                              
                        <td  data-label={`${t('main.operation')}`}>
							
							<div className="icons_table">
							<Link to={`/edit-game/${game?._id}`} style={{textDecoration:"none"}}>
				            <FiEdit className="editIcon" />
				            </Link>
				            <PopUp deleteGame={deleteGame} _id={game?._id}/> 
							</div>
				              
							   </td> 
						   </tr>
					  ))
		   }
		   
        </tbody>
        </table>
              
        <div className="pagination_wrapper">
		{ isLoaded ? (
        <ReactPaginate
         previousLabel={`${t('main.previous')}`}
         nextLabel={`${t('main.next')}`}
         pageCount={pageCount}
         onPageChange={handlePageChange}
         containerClassName={"pagination_btn"}
         previousClassName={"previousBtn"}
         activeClassName={"pagination_btn_active"}
        />
         ) : (
        <div>{t('main.noResultsToDisplay')}</div>
        )} 
		</div>

            </div>
        </div>
    )
}

export default ManageGames;
