import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosArrowRoundBack } from 'react-icons/io';
import {Link, useHistory, useParams} from 'react-router-dom';
import axios from '../../../axios';
import '../Rooms.css';

function LiveRooms() {
    const history = useHistory();
    const { t } = useTranslation();
    const getToken = localStorage.getItem("token")
    const parseToken = JSON.parse(getToken);

    const { name , _id, origin, id, email } = useParams();

    const [url, setUrl] = useState();

    const [liveRoomDetails,setLiveRoomDetails] = useState();

    useEffect(()=>{
        const fetchLiveRooms = async () => {
          const fetchres=await axios.get(`/room/filter?group=${_id}&status=1`,{
            headers: {
                "Authorization": "Bearer " + parseToken
              }
          })
          setLiveRoomDetails(fetchres.data.rooms)
         }
         fetchLiveRooms();
    },[])
    
    const linkTo =  origin === 'grouplist' ? '/group-list' : '/view-groups/'+id+'/' + email; 

    return (
        <div className="userlist">
       <h2 className="liveroom_title">{t('main.liveRooms')}</h2>
        <div className="userlist_wrapper">

           <div className="manageVideo_title">
                 <div className="userlist_title">
                <Link to={linkTo} style={{textDecoration:"none"}}>
		        <IoIosArrowRoundBack className="back_icon"/>
	           	  </Link> 
                   {name}
                 </div>
            </div>    

                 <div className="liveroom_wrapper">
                <div className="liveRoom_wrapper_templeft">
                     
                     <table className="liveroom_table">
                             <thead>
                              <tr>
                             <th>#</th>
                             <th>{t('main.unique')}</th>
                              <th>{t('main.sessionTime')}</th>
                            <th>{t('main.operation')}</th>
                            </tr>
                                 </thead>
                              {
                                 liveRoomDetails?.length === 0 ? (
                               <tbody>
                                <tr>
                                <td style={{color:"red",fontSize:"15px"}}>{t("main.noliveroomforthisgroup")}</td>
                                </tr> 
                              </tbody>       
                              ):(
                            <tbody>
                 
                            {
                                  liveRoomDetails?.map( (room,index) => (
                                       <tr key={index}>
                                    <td data-label="#">{index + 1}</td>
                                    
                                    <td data-label={`${t('main.unique')}`}>{room?.id}</td>
            
                                    <td data-label={`${t('main.sessionTime')}`} >{room?.sessionTime === 1 ? `${room?.sessionTime} min` : `${room?.sessionTime} mins`}</td>
                                          
                                    <td data-label={`${t('main.operation')}`} >
                                        
                                        <div className="room_operation_btn_div">
                                     <Link to={`/liveroom-details/${room?.sessionTime}/${room?.id}/${origin}/${id}/${email}`} className="details_btn" >
                                     {t('main.details')}
                                     </Link> 
                                         <button className="join_btn" onClick={async () => {
                                             try {
                                                 let data = await axios.get(`https://api.reso.chat/auth/getChatroomToken?room=${room.id}`, {
                                                     headers: {
                                                         'Authorization': `Bearer ${parseToken}`
                                                     }
                                                 });
                                                 let token = data.data.token;
                                                 setUrl(`https://app.reso.chat/?auth=${token}`);
                                             }
                                             catch(e) {}
                                         }}>{t('main.join')}</button>
                                        </div>   
                                           </td> 
                                       </tr>
                                  ))
                               }
                                     </tbody>
                           )
                           }       
                             </table>
                     </div>
                     
                 <div className="liveRoom_wrapper_right">       
                 {(url && url.length) && <div className="liveroom_body_iframe">
                    <iframe className="liveroom_iframe" src={url}/>
                 </div>}
                 </div>

            </div>

                        <div className="rooms_btn_div"></div>
          </div>        
        </div>    
    )
}

export default LiveRooms;
