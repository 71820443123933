import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosArrowRoundBack } from 'react-icons/io';
import {  useParams, Link } from 'react-router-dom';
import axios from '../../../axios';

function LiveRoomDetails() {
  const { t } = useTranslation();

    const { id , sessionTime, origin, userid, email } = useParams();
    
    const [details, setDetails] = useState([]);

    const getToken = localStorage.getItem("token")
    const parseToken = JSON.parse(getToken)

    useEffect(() => {
       const fetchDetails = async()=>{
        const res = await axios.get(`/room/${id}`, 
          {
            headers: {
            "Authorization": "Bearer " + parseToken  } 
          }
        )
          setDetails(res.data);
       }     
       fetchDetails();   
      },[]);


      // console.log("session time",sessionTime)

    return (
        <div className="accountDetails">

                    <div className="accountDetails_container">
                      
                    <div className="accountDetails_title" >
                <Link to={`/live-rooms/${details?.room?.group?.name}/${details?.room?.group?._id}/${origin}/${userid}/${email}`} style={{textDecoration:"none"}}>
		               <IoIosArrowRoundBack className="back_icon_centerAlign"/>
	                   </Link>
                     <div style={{marginRight:"20px"}}> {t('main.liveRoom')}&nbsp;{t('main.details')}</div>
                    </div>
        
                          <div className="LiveroomDetails_wrapper">

                           <table className="details_table">
                             <thead>
                             </thead>
                              <tbody>
                                <tr >
                             <td  data-label={`${t('main.Group')} ${t('main.name')}`}>{details?.room?.group?.name} </td>
                             <td data-label={`${t('main.roomID')}`}>{details?.room?._id}</td>
                             <td data-label={`${t('main.startDateTime')}`}>{new Date(`${details?.room?.startDateTime}`).toDateString()} { new Date(`${details?.room?.startDateTime}`).toLocaleTimeString()}</td>
                             <td data-label={`${t('main.sessionTime')}`}> { sessionTime === "1" ? `${sessionTime} min` : `${sessionTime} mins`} </td>
                             <td data-label={`${t('main.participants')}`}>{details?.participantCount}</td>    
                             <td data-label={`${t('main.messages')}`}> {details?.messageCount}</td> 
                                </tr>
                              </tbody>
                             </table>
                             
                           </div>

                           <div className="rooms_btn_div"></div>
                    </div> 
        </div>
    )
}

export default LiveRoomDetails;
