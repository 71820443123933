import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiEdit } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import './manageVideos.css';
import PopUp from './PopUp';
import ReactPaginate from 'react-paginate';
import axios from '../../../axios';
import { IoIosArrowRoundBack } from 'react-icons/io';

const ManageVideos = () => {
    const { t } = useTranslation();

    const [videoDetails,setVideoDetails]=useState([]);
	const [pageCount, setPageCount] = useState(1);
	const [isLoaded, setisLoaded] = useState(false);
	const [currentPage,setCurrentPage]=useState(1)

    const [clickVideo,setClickVideo]=useState(false)

    const getToken = localStorage.getItem("token")
	const parseToken = JSON.parse(getToken);

    const [videoId,setVideoId]=useState("")

      useEffect(()=>{
          fetchVideos(currentPage);
	  },[])

      const showvideo=(video)=>{
        setClickVideo(!clickVideo)
        setVideoId(video)
    }

      const fetchVideos= async(pageToGet)=>{
         try{
            const res = await axios.get(`/video?page=${pageToGet}`,{
				headers: {
				  "Authorization": "Bearer " + parseToken
				}
			 })	
            //  console.log(res);
             setVideoDetails(res.data.videos);
             setPageCount(res.data.count / 10); 
             setisLoaded(true); 
         } 
         catch(e) {
            console.log(e.response?.data?.error); 
          }
      }

	   const handlePageChange = ( selectedObject ) => {
		let intSelectedPage = parseInt(selectedObject.selected) + 1;
		setCurrentPage(intSelectedPage);
		fetchVideos(intSelectedPage);
	  }

    const deleteVideo = async(id,url) => {
        // console.log(url); 
        const videoName = url.split('/').slice(-1).join()
        // console.log(videoName); 
           try{
            const vidDelRes = await axios.delete(`/video/purge?key=${videoName}`,{
                headers: {
                    "Authorization": "Bearer " + parseToken
               }
            })
            // console.log("vidDelRes",vidDelRes);
            const res = await axios.delete(`/video?id=${id}`,{
                headers: {
                   "Authorization": "Bearer " + parseToken
                 }
             }) 
             fetchVideos(currentPage);
         }
         catch(e){
           console.log(e.response.data.error);
        }
    }
  
    //  reduces sentence
 function truncate(str , n){
    return str?.length > n ? str.substr(0,n - 1) + "..." : str;
}

    return (
              <div className="manageVideo">
        <div className="manageVideo_wrapper">

            <div className="manageVideo_titleWrapper">
            <div className="manageVideo_title">
                <div className="userlist_title">
                <Link to='/' style={{textDecoration:"none"}}>
		        <IoIosArrowRoundBack className="back_icon"/>
	           	</Link>  {t('main.manageVideosForWaitingRoom')}
                </div>
                </div>
            <Link to='/create-video' style={{textDecoration:"none"}}>
            <button className="create_btn">{t('main.create')}&nbsp;{t('main.Video')}</button>
            </Link>
            </div>
       

         <table className="table">
            <thead>
				<tr>
     	    <th>#</th>
             <th>{t('main.shortName')}</th>
     	    <th>{t('main.subject')}</th>
             <th>{t('main.language')}</th>
             <th>{t('main.operation')}</th>
				</tr>
          </thead>
          <tbody>
     	 

		   {
					  videoDetails?.map( (video,index) => (
                           <tr key={index}>
						<td  data-label="#">{index + (currentPage -1) * 10 + 1 }</td>
                        
                      <td className="video_shortName"  data-label={`${t('main.shortName')}`}  onClick={()=> showvideo(video?._id)}>
                               {video.shortName}
                       {
                           clickVideo  && video?._id === videoId && 
                            <div className="video_div">
                            <video className="video_iframe" controls>
                             <source src={video?.mediaUrl} />
                               This video is not supported on your browser
                             </video>
                            </div>
                       } 
                       </td>

						<td  data-label={`${t('main.subject')}`}>{truncate(video.subject,50)}</td>

                        <td  data-label={`${t('main.language')}`}>{video.language === 1 ? `${t("main.eng")}` : `${t("main.chi")}`}</td>
                              
                        <td  data-label={`${t('main.operation')}`}>
							
							<div className="icons_table">
							<Link to={`/edit-video/${video._id}`} style={{textDecoration:"none"}}>
				           <FiEdit className="editIcon" />
				           </Link>
				          <PopUp deleteVideo={deleteVideo} _id={video?._id} mediaUrl={video?.mediaUrl} /> 
							</div>
				              
							   </td>
                               
						   </tr>
					  ))
		   }
		   
        </tbody>
        </table>

        <div className="pagination_wrapper">
		{ isLoaded ? (
        <ReactPaginate
         previousLabel={`${t('main.previous')}`}
         nextLabel={`${t('main.next')}`}
         pageCount={pageCount}
         onPageChange={handlePageChange}
         containerClassName={"pagination_btn"}
         previousClassName={"previousBtn"}
         activeClassName={"pagination_btn_active"}
        />
         ) : (
        <div>{t('main.noResultsToDisplay')}</div>
        )} 
		</div>
            </div>
        </div>
    )
}

export default ManageVideos;
