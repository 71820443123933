export const initialState = {  
    createGroupQuestions:[],
    questions:[],
    groupId:null,
}

export const reducer=(state , action)=>{

    // console.log("action",action);

    switch(action.type){
         case "CREATE_GROUP_QUESTIONS":
            let nCreateQuestion = [...state.createGroupQuestions , action.CreateQuestionDetails]

            const createIndex = state.createGroupQuestions.findIndex( ( createQuestionItem) => createQuestionItem._id === action.CreateQuestionDetails._id )
                             if( createIndex >= 0 ){
                                // alert(`Same Item ${action.CreateQuestionDetails.description}`)
                             }else{
                                return{
                               ...state,
                                createGroupQuestions:nCreateQuestion
                          }
                  }

           case "DELETE_CGROUP_QUESTION":
                     
            let newCreateQuestions = [...state.createGroupQuestions]
            const deleteCreateIndex = state.createGroupQuestions.findIndex( (DeleteQuestionItem) => DeleteQuestionItem._id === action._id)
                       
                if(deleteCreateIndex >= 0){
                    newCreateQuestions.splice(deleteCreateIndex,1) 
                }else{
                   console.warn( ` your id ${action._id} does not match !`);
                } 
                return{
                    ...state,
                    createGroupQuestions:newCreateQuestions
            }

        case 'EDIT_GROUP_ADD_QUESTION':
            let nQuestions = [...state.questions];
   
            //Get the length of the Array to be added
            let lenActionQ = (action.questionDetails).length;
            let curIndex = 0;

            //Add each item in the new Question array
            for(let i=0;i< lenActionQ; i++) {
                if((action.questionDetails)[i] === null) {
                    continue;
                }
                curIndex = nQuestions.findIndex( ( Item) => Item._id ===  (action.questionDetails)[i]._id )
                if( curIndex < 0 ){
                    nQuestions.push((action.questionDetails)[i]);
                }
            }  
            return{
                ...state,
                questions:nQuestions
            }

        case "EDIT_GROUP_DELETE_QUESTION":
                     
            let newquestions = [...state.questions]

            // console.log("action._id : " + action._id);

            const index = state.questions.findIndex( (questionItem) => questionItem._id === action._id)
                       
                if(index >= 0){
                    newquestions.splice(index,1) 
                }else{
                   console.warn( ` your id ${action._id} does not match !`);
                } 
                return{
                    ...state,
                    questions:newquestions
            }

             case "GROUP_ID":
                    return{
                     ...state,
                  groupId:action.group_id
             }


            case "USER_LOGGED_OUT":
                 return{
                ...state,
                groupId:action.emptyItems.group_id,
                questions:action.emptyItems.questionDetails,
                createGroupQuestions:action.emptyItems.CreateQuestionDetails,
            }
            

        default: return state;
    }
}

export default reducer;