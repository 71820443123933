import React, {  useState } from 'react';
import {Link, useHistory} from "react-router-dom";
import axios from '../../../axios';
import { IoIosArrowRoundBack } from 'react-icons/io';

function ForgotPassword() {
    const history = useHistory();

    const [input,setInput] = useState({
        loginId:"",
    })
    
    const [errors,setErrors]=useState({});
    const [message,setMessage]=useState('')

    const handleChange=(e)=>{
        setInput(
         {
             ...input,
             [e.target.name]:e.target.value
         }
        )
    }
     
    const validateInfo=()=>{
        let errors = {};

        if(!input.loginId){
            errors.loginId="Email Required"
        }

        return errors;
     }

    const handleSubmit = async(event)=> {
        event.preventDefault();
        setErrors(validateInfo());

        if(Object.keys(validateInfo()).length > 0){
            return;
        }else{
            try {
                const res = await axios.post("/auth/forgot",{
                    loginId:input.loginId,
                   })
                    if(res.data.success) {
                        history.push('/reset-password')
                     }
            }
            catch(e) {
                const message = e.response.data.error;
                setMessage(message)   
            }
        }  
     }

   
    return (
        <div className="Login">
            <div className="Homepage__bgbox1 hide_on_mobile" />
            <div className="Homepage__bgbox2 hide_on_mobile" />

            <div className="Login__main">
                <div className="Login__left">
                    <div className="forgot_title">Forgot Password ?</div>
                    <div className="forgot_description">Enter email id to get reset token at your registered email</div>
                </div>
               
                <div className="Login__right">
                    <div>
                <Link to='/login' style={{textDecoration:"none"}}><IoIosArrowRoundBack className="back_icon" style={{color:"white",fontSize:"50px",marginBottom:"15px"}}/></Link>
		            </div>
                    <div className="Login__right_form">

                        <div className="form__input_wrapper">
                        <label className="form__input_label">Email</label>
                        <input className="Login__field" type="text" name="loginId" value={input.loginId} onChange={handleChange} placeholder="Enter Email"/>
                        {errors.loginId && <p className="form_dropdown_error formLogin__input_error">{errors.loginId}</p>}
                        </div>
                        
                        <p className="login_backend_error">{message}</p>
                    </div>
                               
                   <button className="Login__button" onClick={handleSubmit}>Submit</button> 
                    
                </div>

            </div>
        </div>
    )
}

export default ForgotPassword;