import React, { useEffect, useRef, useState } from 'react';
import './adminHeader.css';
import {Link, useHistory} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
            //import icons
import {HiOutlineHome} from 'react-icons/hi';
import {RiAccountCircleLine} from 'react-icons/ri';
import {HiOutlineUserGroup} from 'react-icons/hi';
import {RiQuestionnaireLine} from 'react-icons/ri';
import {HiOutlineChatAlt2} from 'react-icons/hi';
import {AiOutlineCloudServer} from 'react-icons/ai';
import {RiMailSendLine} from 'react-icons/ri';
import {FiMenu} from 'react-icons/fi';
import {FaTimes} from 'react-icons/fa';
import JWT from 'jsonwebtoken';


const AdminHeader = () => {
   const history = useHistory()
    const [clicked,setClicked] = useState(false);
    const { t, i18n } = useTranslation();

    const getToken = localStorage.getItem("token")
    const parseToken = JSON.parse(getToken)
    const [role,setRole] = useState() 

   // code for onClick outside the sidebar hides sidebar-menu and for role

    let menuRef = useRef();
    useEffect(()=>{
         let handler=(event)=>{
         if(!menuRef.current.contains(event.target)){
            setClicked(false);
         }
         };
      document.addEventListener('mousedown',handler)
      const userData = JWT.decode(parseToken)
      setRole(userData?.role)
      return ()=>{
        document.removeEventListener('mousedown',handler)
      }
    },[parseToken]);
      
      //changes language
    const handleClick=(lang)=>{
      i18n.changeLanguage(lang)
      // console.log(lang);
    }

    const signout =(e)=>{
      e.preventDefault();
      localStorage.removeItem("token");
      localStorage.removeItem("userid");
      history.push('/login')
      window.location.reload();
    }
    return (
        <nav className="admin_header">
     
         <div className="admin_menu_bars" onClick={()=>setClicked(!clicked)} >
                {
                    clicked ? (
                      <FaTimes className="menu_icon"/>
                    ):(
                       <FiMenu className="menu_icon" />
                    )
                }
          </div>

          <div ref={menuRef} className={clicked ? 'admin_sidebar_menu_active' : 'adminSidebar_menu'}>
                     <ul className="sidebar_menu_ul">

                           {
                              role === 1 ? (
                              <>
                              <Link to="/" className="admin_sidebar_links" onClick={()=>setClicked(false)}>
                              <HiOutlineHome className="sidebar_svg"/><li className="sidebar_menu_title">{t('main.home')}</li>
                           </Link>
                           <Link to="/users-list" className="admin_sidebar_links" onClick={()=>setClicked(false)}>
                              <RiAccountCircleLine className="sidebar_svg" /><li className="sidebar_menu_title">{t('main.accounts')}</li>
                           </Link>
                           <Link to="/manage-hosts" className="admin_sidebar_links" onClick={()=>setClicked(false)}>
                              <AiOutlineCloudServer  className="sidebar_svg" /><li className="sidebar_menu_title">{t('main.hosts')}</li>
                           </Link>
                           <Link to="/group-list" className="admin_sidebar_links" onClick={()=>setClicked(false)}>
                              <HiOutlineUserGroup  className="sidebar_svg" /><li className="sidebar_menu_title">{t('main.roomTemplates')}</li>
                           </Link>
                           <Link to="/question-list" className="admin_sidebar_links" onClick={()=>setClicked(false)}>
                              <RiQuestionnaireLine className="sidebar_svg" /><li className="sidebar_menu_title">{t('main.Questions')}</li>
                           </Link>
                           <Link to="/chat-rooms" className="admin_sidebar_links" onClick={()=>setClicked(false)}>
                              <HiOutlineChatAlt2  className="sidebar_svg" /><li className="sidebar_menu_title">{t('main.activeRooms')}</li>
                           </Link>
                              </>
                              ):(
                                <>
                           <Link to="/" className="admin_sidebar_links" onClick={()=>setClicked(false)}>
                           <HiOutlineHome className="sidebar_svg"/><li className="sidebar_menu_title">{t('main.home')}</li>
                           </Link>
                           <Link to="/group-list" className="admin_sidebar_links" onClick={()=>setClicked(false)}>
                           <HiOutlineUserGroup  className="sidebar_svg" /><li className="sidebar_menu_title">{t('main.roomTemplates')}</li>
                           </Link>
                           <Link to="/question-list" className="admin_sidebar_links" onClick={()=>setClicked(false)}>
                           <RiQuestionnaireLine className="sidebar_svg" /><li className="sidebar_menu_title">{t('main.Questions')}</li>
                           </Link>
                           <Link to="/chat-rooms" className="admin_sidebar_links" onClick={()=>setClicked(false)}>
                           <HiOutlineChatAlt2  className="sidebar_svg" /><li className="sidebar_menu_title">{t('main.activeRooms')}</li>
                           </Link>
                           <Link to="/contact-admin" className="admin_sidebar_links" onClick={()=>setClicked(false)}>
                           <RiMailSendLine className="sidebar_svg"/><li className="sidebar_menu_title">{t('main.contact')}</li>
                           </Link>
                                </>
                              )
                           }
                        
                     </ul>
                     
                     <button className="sidebar_logout_btn" onClick={signout}>{t('main.logout')}</button>
          </div>

          <div className="admin_header_title">
             <h4>{t('main.home')}</h4>
          </div>
                
              <div className="admin_header_btn">
              <button className="btn" onClick={()=>handleClick('en')}>English</button>
              <button className="btn" onClick={()=>handleClick('chi')}>中国人</button>
              </div>
    
       </nav>
    )
}

export default AdminHeader;
