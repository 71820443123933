import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';

Modal.setAppElement('#root')
function PopUp({ modalIsOpen , setModalIsOpen, handleSubmit ,title , title2}) {
  const { t } = useTranslation();
    const history = useHistory();
    const customStyles = {
        overlay:{
            
        },
        content: {
          top: '35%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          width: '40%',
          transform: 'translate(-50%, -10%)',
          color:"white",
          background:" #1e45a1",
        }
      };

      const changeScreen=(e)=>{
        e.preventDefault();
        history.push('/create-group')
        setModalIsOpen(false)
      }

    return (
        <div>
            <button className="tab_btn"  onClick={handleSubmit}>{t('main.submit')}</button>
            <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen (false) }  style={customStyles}>
                <div className="modal_wrapper">
                <p className="createGroup_ModalTitle">{title}<br/>{title2}</p>

                <button className="create_btn" onClick={changeScreen}>{t('main.ok')}</button>
                </div>
            </Modal>
        </div>
    )
}

export default PopUp;
