import React, { useEffect, useState } from 'react';
import { IoIosArrowRoundBack} from 'react-icons/io';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from '../../../axios';
import ReactPaginate from 'react-paginate';
import './manageHosts.css';

function ManageHosts() {
      const { t } = useTranslation();
      const [hosts,setHostDetails]=useState([]);

	  const [pageCount, setPageCount] = useState(1);
	  const [isLoaded, setisLoaded] = useState(false);
	  const [currentPage,setCurrentPage]=useState(1)

	  const getToken = localStorage.getItem("token")
	  const parseToken = JSON.parse(getToken);
	
	  useEffect(()=>{
		fetchHosts(currentPage);
	  },[currentPage])

		const fetchHosts = async (pageToGet) => {
            const res = await axios.get(`/user/hosts?page=${pageToGet}`,{
				headers: {
				  "Authorization": "Bearer " + parseToken
				}
			  })
            setHostDetails(res.data.hosts);
			setPageCount(res.data.count / 10 );
			if(res?.data.count >= 1){
				setisLoaded(true); 
			} 
		 }
	    
		   //  reduces sentence
	//  function truncate(str , n){
	// 	 return str?.length > n ? str.substr(0,n - 1) + "..." : str;
	//  }
 

		const handlePageChange = ( selectedObject ) => {
		  let intSelectedPage = parseInt(selectedObject.selected) + 1;
		  setCurrentPage(intSelectedPage);
		  fetchHosts(intSelectedPage);
		}

	
    return (
        <div className="userlist">
        <div className="userlist_wrapper">
         <div className="userlist_title">
	    <Link to='/' style={{textDecoration:"none"}}><IoIosArrowRoundBack className="back_icon"/></Link>
              {t("main.manage")}&nbsp;{t("main.hosts")}
		 </div>

         <table className="table">
            <thead>
				<tr>
				<th>#</th>
     	       <th>{t("main.email")}</th>
               <th>{t("main.totalRoomTemplates")}</th>
               <th>{t("main.activeRooms")}</th>
               <th>{t("main.operation")}</th>
				</tr>
          </thead>
          <tbody>

		   {
					  hosts?.map( (user,index) => (

						<tr key={index}> 
						<td  data-label="#">{index + (currentPage -1) * 10 + 1 }</td>

						<td  data-label={`${t("main.email")}`}>{user?.email}</td>

						<td  data-label={`${t("main.totalRoomTemplates")}`}>{user?.groupCount}</td>

						<td  data-label={`${t("main.activeRooms")}`}>{user?.liveRooms}</td>

						<td  data-label={`${t("main.operation")}`}>
                           <Link to={`/view-groups/${user?.email}/${user?.id}`}>  
                        <button className="viewgroups_btn">{t("main.viewRoomTemplates")}</button>
                             </Link>
						   </td>
                        
						   </tr>
					  ))
		   }   
        </tbody>
        </table>
              
		<div className="pagination_wrapper">
		{ isLoaded ? (
        <ReactPaginate
         previousLabel={`${t('main.previous')}`}
         nextLabel={`${t('main.next')}`}
         pageCount={pageCount}
         onPageChange={handlePageChange}
         containerClassName={"pagination_btn"}
         previousClassName={"previousBtn"}
         activeClassName={"pagination_btn_active"}
        />
         ) : (
        <div>{t('main.noResultsToDisplay')}</div>
        )} 
		</div>

            </div>
        </div>
    )
}

export default ManageHosts;
