import React, {useEffect, useState} from 'react';
import {FaPlus, FaMinus} from "react-icons/fa";
import { useDataLayerValue } from '../../../../datalayer';
import axios from '../../../../axios';
import EditPopUp from '../EditPopUp/EditPopUp';
import { useTranslation } from 'react-i18next';
// import { TextField } from '@material-ui/core';

function EditGroupQuestions({ groupId ,chatStatus}) { 
    const { t } = useTranslation();
    const [{ questions  },dispatch] = useDataLayerValue();
    // console.log("chatStatus3",chatStatus);
    const [ modalIsOpen,setModalIsOpen ] = useState(false);
    const [Fetcherrors,setFetcherrors]=useState({});
    const [Submiterrors,setSubmiterrors]=useState({});

     var questionsFinal = [];
     for(var i=0;i<questions?.length;i++) {
         questionsFinal.push(questions[i]?._id)
     }

    const [input,setInput]= useState({
        subject:"",
        question:""
    })

    const [languageDropDown,setLanguageDropDown]=useState()
    const [isLoaded, setisLoaded] = useState(false);
    const [QuestionsData,setQuestionsData]=useState();

    const getToken = localStorage.getItem("token")
    const parseToken = JSON.parse(getToken)

 //dispatching the updating questions to show in editgroup questions
 
    useEffect(()=>{
        const fetchGroupQuestions = async () => {
            const fetchres=await axios.get(`/group/${groupId}`,{
              headers: {
                  "Authorization": "Bearer " + parseToken
                }
            })
         
            dispatch({
                type:'EDIT_GROUP_ADD_QUESTION',
                questionDetails:fetchres.data.questions
              }) 
           }
           fetchGroupQuestions();
    },[groupId,parseToken])


    const handleChange=(e)=>{
        setInput(
         {
             ...input,
             [e.target.name]:e.target.value
         }
        )
    }

    const FetchvalidateInfo=()=>{
        let Fetcherrors = {};

        // if(!input.subject){
        //     Fetcherrors.subject=`${t("main.subject")} ${t("main.required")}`
        // } 

        if(!languageDropDown){
            Fetcherrors.languageDropDown=`${t("main.language")} ${t("main.required")}`
        }

        return Fetcherrors;
     }

     const submitValidateInfo=()=>{
        let Submiterrors = {};
        if(questions.length === 0){
            Submiterrors.questions=`${t("main.Questions")} ${t("main.required")} ! ${t("main.fetch")} ${t("main.the")} ${t("main.Questions")} ${t("main.and")} ${t("main.add")} ${t("main.the")} ${t("main.Questions")} `
        }
        return Submiterrors;
     }


    const fetchQuestions = async(e) => {
        e.preventDefault();
        setFetcherrors(FetchvalidateInfo());
        if(Object.keys(FetchvalidateInfo()).length > 0){
            return;
        }else{  
            try{
                let question = input.question;
                let subject = input.subject;

                if(subject === ""){
                    subject = "null";
                }

                const url = `/question/${languageDropDown}/${subject}/${question}`;
               
                const res = await axios.get(url,{
                 headers: {
                     "Authorization": "Bearer " + parseToken
                 }
                })
                 setQuestionsData(res.data)
                //  console.log(res.data)
                 if(res.data.length >= 1){
                     setisLoaded(false)
                 }else{
                     setisLoaded(true)
                 }
     
             }catch(e){
                 console.log(e.response.data.error);
             }
        }
    }

    const handleSubmit = async(e)=>{
          e.preventDefault();
          setSubmiterrors(submitValidateInfo());
          if(Object.keys(submitValidateInfo()).length > 0){
              return;
          }else{ 
           try{
            const res = await axios.put(`/group?id=${groupId}`,{
               questions:questionsFinal
            },{
                headers: {
                    "Authorization": "Bearer " + parseToken
               }
            })
             if(res.statusText === 'OK'){
                setModalIsOpen(true)
             } 

          }catch(e){
            console.log(e.response.data.error);
          }
        }
    }

    function truncate(str , n){
        return str?.length > n ? str.substr(0,n - 1) + "..." : str;
    }


    return (
        <div className="ChatGroupCreate">
            <div className="ChatGroupCreate__form">
                <div className="ChatGroupCreate__form__title">
                    {chatStatus === 0 ? `${t('main.edit')} ${t('main.Questions')}` : `${t('main.view')} ${t('main.Questions')}`}
                </div>
                
               {/* <TextField type="text" name="subject" InputProps={{style:{borderRadius:55}}} InputLabelProps={{style: {fontSize: 15}}} size="small" style={{fontSize:"10px"}} variant="outlined" label={`${t("main.subject")}`}  value={input.subject} onChange={handleChange} className="createVideo_input"/> */}

               <div className="form__input_wrapper formManageGroup__input_wrapper">
                        <label className="form__input_label formManageGroup__input_label">{t("main.subject")}</label> 
                <input type="text" name="subject"  value={input.subject} onChange={handleChange} className="createVideo_input"  placeholder={`${t("main.enter")} ${t("main.subject")}`}/>
                {/* {Fetcherrors.subject && <p className="form_dropdown_error">{Fetcherrors.subject}</p>} */}
                </div>

                <div className="form__input_wrapper formManageGroup__input_wrapper">
                        <label className="form__input_label formManageGroup__input_label">{t("main.language")}</label> 
                <select className="createVideo_input" value={languageDropDown} onChange={(e)=>setLanguageDropDown(e.target.value)} >
                        <option value="" disabled selected >{t("main.select")} {t("main.language")}</option>
                        <option value="1">{t("main.eng")}</option>
                        <option value="2">{t("main.chi")}</option>
                 </select>
                 {Fetcherrors.languageDropDown && <p className="form_dropdown_error">{Fetcherrors.languageDropDown}</p>}
                 </div>

                 <div className="form__input_wrapper formManageGroup__input_wrapper">
                        <label className="form__input_label formManageGroup__input_label">{t("main.Question")}</label> 
                  <input type="text" name="question"  value={input.question} onChange={handleChange} className="createVideo_input"  placeholder={`${t("main.enter")} ${t("main.Question")}`}/>   
                  </div>
                  
                  {chatStatus === 0 &&
                  <button className="fetch_btn" onClick={fetchQuestions}>{t('main.fetch')}&nbsp;{t('main.Questions')}</button>}

                <div className="select_questions">
                    <div className="select_questions_left">

                        <div className="select_questions_left_search">
                            <h4>{t('main.select')}&nbsp;{t('main.Questions')}</h4>
                        </div>
                       
                        {
                            QuestionsData?.map((item,index)=>(       

                                <div className="question_list_item">    
                                <div className="question_text" key={index}>{item?.quesType === 1 ? (
                                                    <p style={{color:"#C31818"}}>{truncate(item.description,55)}</p>
                                                 ):(
                                                    <p style={{color:"#123075"}}>{truncate(item.description,55)}</p>
                                                 )}
                                                 </div>
                                
                                <FaPlus className="plus_icon"  onClick={()=>dispatch({
                                             type:'EDIT_GROUP_ADD_QUESTION',
                                             questionDetails:[{
                                             _id:item._id,
                                             description:item.description,
                                             quesType:item.quesType
                                             }]
                                })}/>

                             </div>

                            ))
                         }

                         {
                             isLoaded ? (
                                <p className="loading_disclaimer">{t('main.noResultsToDisplay')}</p>
                             ):(
                               <></>  
                             )
                         }
                       

                    </div>

                    <div className="select_questions_left">
                        <div className="select_questions_left_search" style={{paddingTop:"8px"}}>   
                          
                       
                           {
                               questions?.length === 1 ? (
                                <p> {questions?.length} {t('main.Question')}&nbsp;{t('main.selected')}</p>
                               ):(
                                <p> {questions?.length} {t('main.Questions')}&nbsp;{t('main.selected')}</p>
                               )
                            }

                         </div>

                     {/* questions are from the reducer */}

                         {questions?.map((question,index)=>(
                            <div className="question_list_item">
                                <div className="question_text" key={index}>{question?.quesType === 1 ? (
                                                    <p style={{color:"#C31818"}}>{truncate(question.description,55)}</p>
                                                 ):(
                                                    <p style={{color:"#123075"}}>{truncate(question.description,55)}</p>
                                                 )}</div>
                                <FaMinus className="plus_icon"  onClick={()=> dispatch({
                                                type:"EDIT_GROUP_DELETE_QUESTION",
                                                 _id:question._id
                                })}/>
                            </div>
                         ))}
                    </div>
                </div>
                     

                         <div className="legend_wrapper">

                         <div className="legend_container">
                         <div className="legend_red"></div>
                         <span className="legend_text" style={{color:"#C31818"}}>{t("main.Question")}</span>
                         </div>
 
                         <div className="legend_container">
                         <div className="legend_blue"></div>
                         <span className="legend_text" style={{color:"#123075"}} >{t("main.instruction")}</span>
                        </div>
 
                        </div>

                     {Submiterrors.questions && <p className="submit_error">{Submiterrors.questions}</p>}

                          <div className="tab_btn_div">
                        {chatStatus === 0 && <EditPopUp modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} handleSubmit={handleSubmit} />}
                           </div>


            </div>
        </div>
    )
}

export default EditGroupQuestions; 
