import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'react-modal';

Modal.setAppElement('#root')
function UrlCopiedPopup ({ group_ID }) {

    const { t } = useTranslation();
 
    const [ modalIsOpen,setModalIsOpen ] = useState(false);

    useEffect(()=>{
			if(modalIsOpen === true) {
				let timerFunc = setTimeout(() => {
                                  setModalIsOpen(false)
				                 },2000);

		    return () => clearTimeout(timerFunc);
		 	}
	      },[modalIsOpen])
  
    const customStyles = {
        overlay:{
            
        },
        content: {
          top: '35%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          width: '45%',
          transform: 'translate(-50%, -10%)',
          color:"white",
          background:" #1e45a1",
        }
      };

      const handleGroupUrl = async()=>{
		try {
		 await navigator.clipboard.writeText(`https://app.reso.chat?group=${group_ID}`);
		 setModalIsOpen(true)
		  } catch (err) {
			console.error('Failed to copy: ', err);
		  }
	   }

       const handleIframeUrl = async()=>{
		try {
		await navigator.clipboard.writeText(`<iframe id="rechat" height="700" width="700" style="border:4px solid #d3d3d3;border-radius:8px" src="https://app.reso.chat?group=${group_ID}" allow="clipboard-read;clipboard-write" ></iframe>`);
		setModalIsOpen(true)
		  } catch (err) {
			console.error('Failed to copy: ', err);
		  }
	   }

    return (
        <>
            	 
              <button className="copy_groupUrl_btn" onClick={handleGroupUrl}>{t("main.copygroupurl")} </button>
			  <button className="copy_groupUrl_btn" onClick={handleIframeUrl}>{t("main.copyiframeurl")} </button>
                
            <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen (false) } style={customStyles}>
                <div className="modal_wrapper">
                <h4 className="copyUrl_title">{t("main.urlCopied")}</h4>
                </div>
            </Modal>
        </>
    )
}

export default UrlCopiedPopup;
