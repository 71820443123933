import React from 'react';
import { useTranslation } from 'react-i18next';
import './pageNotFound.css';
import PageNotFoundSvg from './svg/404.svg'
import { HiOutlineEmojiSad } from "react-icons/hi";

const PageNotFound = () => {
    const { t } = useTranslation();
    return (
        <div className="PageNotFound">
            <div className="PageNotFound_wrapper">
            <img className="PageNotFound_svg" src={PageNotFoundSvg} alt="404-svg"/>
               <div className="PageNotFound_title">
               <HiOutlineEmojiSad className="sad_svg" />
               <h2>{t("main.pageNotFound")}</h2>
               </div>
        <a href="https://admin.reso.chat"><button className="PageNotFound_btn">{t("main.goToHome")}</button></a>
             </div>
        </div>
    )
}

export default PageNotFound;
