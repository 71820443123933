import React, {  useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiDeleteBin6Line } from 'react-icons/ri';
import Modal from 'react-modal';
import axios from '../../../axios';
import { customStyles } from '../../ModalLayout';

Modal.setAppElement('#root')
function PopUp({_id , deleteGroup,parseToken }) {
    const { t } = useTranslation();
    const [ modalIsOpen,setModalIsOpen ] = useState(false);
    const [chatStatus,setChatStatus]=useState()
      
    //   useEffect(()=>{
    //     const fetchGroupStatus = async () =>{
    //         const res = await axios.get(`/group/boolActive/${_id}`,{
    //             headers: {
    //                 "Authorization": "Bearer " + parseToken
    //               }
    //         });
    //         console.log(res);
    //        setGroupStatus(res.data?.status)
    //     }
    //     fetchGroupStatus();
    //   },[])

      const handleSubmit = async (e) => {
        e.preventDefault();
        setModalIsOpen(true)
        const res = await axios.get(`/group/boolActive/${_id}`,{
            headers: {
                "Authorization": "Bearer " + parseToken
              }
        });
        setChatStatus(res.data?.status)
        // console.log(res);
      }

    return (
        <div>
            <RiDeleteBin6Line className="grouplist_deleteIcon" onClick={handleSubmit} />
            <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen (false) }  style={customStyles}>
                { chatStatus === 1 ? (
                     <div className="modal_wrapper">
                     <h3 className="modal__title">{t("main.roomTemplate")} {t("main.cannotDelete")}</h3>
                     <button className="create_btn" onClick={()=> setModalIsOpen(false)}>{t('main.ok')}</button>
                    </div>
                 ):(
                    <div className="modal_wrapper">
                    <h3>{t('main.doYouWantToDeleteThe')} {t('main.group')} ?</h3>
                   <div className="modal_btns">
                   <button className="modal_red_btn" onClick={()=> {
                        deleteGroup(_id)
                        setModalIsOpen (false)
                        }}>{t('main.yes')}</button>
                    <button className="modal_blue_btn" onClick={()=>setModalIsOpen(false)}>{t('main.no')}</button></div> 
                   </div>
                 )
                 }
               
            </Modal>
        </div>
    )
}

export default PopUp;
