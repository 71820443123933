import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiDeleteBin6Line } from 'react-icons/ri';
import Modal from 'react-modal';
import { customStyles } from '../../ModalLayout';
import './PopUp.css';

Modal.setAppElement('#root')
function PopUp({_id , deleteUser }) {
    const { t } = useTranslation();

    const [ modalIsOpen,setModalIsOpen ] = useState();

    return (
        <div>
            <RiDeleteBin6Line className="deleteIcon" onClick={()=>setModalIsOpen(true)} />
            <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen (false) }  style={customStyles}>
                <div className="modal_wrapper">
                <h3>{t('main.doYouWantToDeleteThe')} {t('main.user')} ?</h3>
               <div className="modal_btns">
               <button className="modal_red_btn" onClick={()=> {
                    deleteUser(_id)
                    setModalIsOpen (false)
                    }}>{t('main.yes')}</button>
                <button className="modal_blue_btn" onClick={()=>setModalIsOpen(false)}>{t('main.no')}</button></div> 
               </div>
            </Modal>
        </div>
    )
}

export default PopUp;
