import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from '../../../../axios';
import EditPopUp from '../EditPopUp/EditPopUp';
import DeletePopup from './DeletePopup';


const EditSelectVideo = ({ groupId , chatStatus}) => {
    const { t } = useTranslation();
    const [ modalIsOpen,setModalIsOpen ] = useState(false);
    const [delVideoModal , setDelVideoModal ] = useState(false);
    // console.log("chatStatus5",chatStatus);
    const getToken = localStorage.getItem("token")
    const parseToken = JSON.parse(getToken) 

    const [errors,setErrors]=useState({});
    const [Submiterrors,setSubmiterrors]=useState({});

    const [input,setInput]= useState({
        subject:""
    })

    const [videosData,setVideosData]=useState([]);
    const [selectedVideoLoading, setSelectedVideoLoading] = useState(false);
	const [isLoaded, setisLoaded] = useState(false);
    
    const [radio,setRadio]=useState();
    const [languageDropDown,setLanguageDropDown]=useState()
    const [clickVideo,setClickVideo]=useState(false)
    const [videoId,setVideoId]=useState("")

    
    useEffect(()=>{
        const fetchEditVideos = async () => {
            const fetchres=await axios.get(`/group/${groupId}`,{
              headers: {
                  "Authorization": "Bearer " + parseToken
                }
            })
            setVideosData([ fetchres?.data?.video ])
            setRadio(fetchres.data?.video?._id)
            // console.log(fetchres);
            if(fetchres?.data?.video === undefined || fetchres?.data?.video === null){
            setSelectedVideoLoading(true)
            }else{
             setSelectedVideoLoading(false)
            }
           }
           fetchEditVideos();
     },[groupId,parseToken])

     const showvideo=(video)=>{
        setClickVideo(!clickVideo)
        setVideoId(video)
      }
    

    const handleChange=(e)=>{
        setInput(
         {
             ...input,
             [e.target.name]:e.target.value
         }
        )
    }

    const validateInfo=()=>{
        let errors = {};
    
        if(!input.subject){
            errors.subject=`${t("main.subject")} ${t("main.required")}`
        } 
    
        if(!languageDropDown){
            errors.languageDropDown=`${t("main.language")} ${t("main.required")}`
        }
        return errors;
     }
      
     const submitValidateInfo=()=>{
        let Submiterrors = {};
        if(!radio){
            Submiterrors.radio=`${t("main.fetch")} ${t("main.the")} ${t("main.videos")} ${t("main.and")} ${t("main.select")}  ${t("main.video")}`
        }
        return Submiterrors;
     }

    const fetchVideos = async(e) => {
        e.preventDefault();
        setErrors(validateInfo());
        if(Object.keys(validateInfo()).length > 0){
            return;
        }else{
        try{
           const res = await axios.get(`/video/${languageDropDown}/${input.subject}`,{
            headers: {
                "Authorization": "Bearer " + parseToken
            }
           })
        //    console.log(res);
        setVideosData(res?.data)
        if(res.data.length < 1){
            setisLoaded(true)
          }else{
            setisLoaded(false)
            setSelectedVideoLoading(false)
          }
        }catch(e){
            console.log(e.response?.data?.error);
        }
      }
    }

    const handleSubmit=async(e)=>{
        e.preventDefault();
        setSubmiterrors(submitValidateInfo());
        if(Object.keys(submitValidateInfo()).length > 0){
            return;
        }else{ 
        try{
            const res = await axios.put(`/group?id=${groupId}`,{
              video:radio
            },{
                headers: {
                    "Authorization": "Bearer " + parseToken
               }
            })

            if(res.statusText === 'OK'){
               setModalIsOpen(true)
            } 

          }catch(e){
            console.log(e.response.data.error);
          }
        }
    }

    const delVideoData = async() =>{
            try{
                const res = await axios.put(`/group?id=${groupId}`,{
                    video:null
                  },{
                      headers: {
                          "Authorization": "Bearer " + parseToken
                     }
                  })
                  if(res.status === 200){
                      setDelVideoModal(false);
                      setVideosData([])
                      setSelectedVideoLoading(true);
                  }
                //   console.log(res);
            }catch(e){
                console.log(e.response?.data?.error);
            }
    }

   return(
      <div className="videolist">
   <div className="video_wrapper">
       <div className="video_title">
       {chatStatus === 0 ? `${t('main.edit')} ${t('main.select')} ${t('main.Video')}` : `${t('main.view')} ${t('main.select')} ${t('main.Video')}`}
       </div>

           <div className="video_select">
           
           <div className="form__input_wrapper ">
            <label className="form__input_label">{t("main.subject")}</label> 
           <input type="text" name="subject"  value={input.subject} onChange={handleChange} className="Selectvideo_input"  placeholder={`${t("main.enter")} ${t("main.subject")}`}/>
           </div>

           <div className="form__input_wrapper ">
                        <label className="form__input_label ">{t("main.language")}</label> 
                <select className="Selectvideo_input" value={languageDropDown} onChange={(e)=>setLanguageDropDown(e.target.value)}>
                    <option value="" disabled selected>{t("main.select")} {t("main.language")}</option>
                    <option value="1">{t("main.eng")}</option>
                    <option value="2">{t("main.chi")}</option>
               </select>
           </div>
           </div>

           {errors.subject && <p className="form_dropdown_error selectVideo__fetchError">{errors.subject}</p>}
           {errors.languageDropDown && <p className="form_dropdown_error selectVideo__fetchError">{errors.languageDropDown}</p>}           
           
           {chatStatus === 0 &&
           <button className="fetch_btn" onClick={fetchVideos}>{t('main.fetch')}&nbsp;{t('main.videos')}</button>}


    <table className="video_table">
       <thead>
           <tr>
        <th>{t('main.select')}</th>
        <th>#</th>
        <th>{t('main.shortName')}</th>
        <th>{t('main.subject')}</th>
        <th>{t('main.language')}</th>
           </tr>
     </thead>
        
        {
           selectedVideoLoading ? (
            <tbody>
            <tr>
            <td style={{color:"red"}}>{t("main.novideoselectedforthisgroup")}</td>
            </tr>
          </tbody>
            ):(
            <tbody>
            {
                     videosData?.map( (video,index) => (
                         <tr key={index}>
                       <td  data-label={`${t('main.select')}`}>
                       <input type="radio" checked={radio === `${video?._id}`} value={video?._id} onChange={(e)=>setRadio(e.target.value)}/>
                       </td>
                      
                       <td  data-label="#">{index + 1}</td>
    
                       <td className="video_shortName" data-label={`${t('main.shortName')}`} onClick={()=> showvideo(video?._id)}>
                           {video?.shortName}
                           {
                           clickVideo  && video?._id === videoId && 
                            <div className="video_div">
                            <video className="video_iframe" controls>
                             <source src={video?.mediaUrl} />
                               This video is not supported on your browser
                             </video>
                            </div>
                       } 
                           </td>
    
                       <td  data-label={`${t('main.subject')}`}>{video?.subject}</td>
    
                       <td  data-label={`${t('main.language')}`}>{video?.language === 1 ? `${t("main.eng")}` : `${t("main.chi")}`}</td>
                          </tr>
                     ))
          } 
          
           
       </tbody>
           )
        }     

   </table>
                    
         
   <div className="pagination_wrapper">
             
   { isLoaded && <p className="form_dropdown_error">{t('main.noResultsToDisplay')}</p> } 

   </div>

              {Submiterrors.radio && <p className="submit_error">{Submiterrors.radio}</p>}

                  <div className="tab_btn_div">
                  {chatStatus === 0 &&  <EditPopUp modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} handleSubmit={handleSubmit} title="Updated Successfully"/>}
                  
                  {chatStatus === 0 &&  <DeletePopup delVideoModal={delVideoModal} setDelVideoModal={setDelVideoModal} delVideoData={delVideoData} path="ESelectVid"/> }
                </div>
       </div>
   </div>
   )
}

export default EditSelectVideo;
