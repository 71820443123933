import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { blueBgCustomStyles } from '../ModalLayout';

Modal.setAppElement('#root')
function ContactPopUp({ modalIsOpen , setModalIsOpen, handleSubmit  }) {
    const { t } = useTranslation();
    
      const handleOk=(e)=>{
      e.preventDefault();
      setModalIsOpen(false)
      }

    return (
        <div>
            <button className="tab_btn" onClick={handleSubmit}>{t('main.submit')}</button>
            <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen (false) }  style={blueBgCustomStyles}>
                <div className="modal_wrapper">
                <p className="createGroup_ModalTitle">{t("main.yourMessageHasBeenSent")}</p>

                <button className="create_btn" onClick={handleOk}>{t("main.ok")}</button>
                </div>
            </Modal>
        </div>
    )
}

export default ContactPopUp;
