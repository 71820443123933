import React, { useEffect, useState } from 'react';
import './CreateGroup.css';
import { Tabs,Tab, AppBar } from '@material-ui/core';
import Questions from './Questions/ChatQuestions';
import Header from '../../Header';
import GroupProperties from './GroupProperties/index';
import OpeningQuestion from './Questions/OpeningQuestion/OpeningQuestion';
import SelectVideo from '../../Videos/SelectVideo';
import SelectGame from '../../Games/SelectGame';
import { useDataLayerValue } from '../../../datalayer';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { IoIosArrowRoundBack } from 'react-icons/io';


const CreateGroup = props => {
   const { t } = useTranslation();
   const [{ groupId },dispatch]=useDataLayerValue();

    const { match , history } = props;
    const { params } = match;
    const { page } = params;

    const tabNameToIndex = {
        0:"groupProperties",
        1:"openingQuestion",
        2:"chatQuestions",
        3:"video",
        4:"game",
    }

    const indexToTabName = {
     groupProperties : 0,
     openingQuestion:1,
     chatQuestions : 2,
     video:3,
     game:4
    }

const [selectedTab , setSelectedTab] = useState(indexToTabName[page])

 const handleChange = (event , newValue) => {
  history.push(`/create-group/${tabNameToIndex[newValue]}`)
        setSelectedTab(newValue)
  }

 useEffect(()=>{
 if(groupId){
  history.push('/create-group/openingQuestion')
  setSelectedTab(1)
 }
 },[])
 
 return (
    <>
     <Header/>
     <div className="tab_container">
     <div className="accountDetails_title" style={{marginBottom:"-12px"}} >
               <Link to='/group-list' style={{textDecoration:"none",marginTop:"4px"}}>
		        <IoIosArrowRoundBack className="back_icon_centerAlign"/>
	           </Link>
           <h2 className="tabs_mainTitle"> {t('main.create')}&nbsp;{t('main.Group')}</h2>
         </div>
       <div className='tab_wrapper'>
      {
          groupId ? (
            <AppBar position="static" className="app_bar">
            <Tabs TabIndicatorProps={{style: {width:"17%",margin:"0px 10px"}}}  className="tabs"
                            variant="fullWidth" value={selectedTab} onChange={handleChange}>
                <Tab style={{textTransform:"capitalize"}} className="tab_title" disabled label={`${t('main.mainProperties')}`} />
                <Tab style={{textTransform:"capitalize"}} className="tab_title"  label={`${t('main.placement')} ${t('main.Question')}`}/>
                <Tab style={{textTransform:"capitalize"}} className="tab_title" label={`${t('main.chat')} ${t('main.Questions')}`}/>
                <Tab style={{textTransform:"capitalize"}} className="tab_title" label={`${t('main.Video')}`}/>
                <Tab style={{textTransform:"capitalize"}} className="tab_title" label={`${t('main.Game')}`}/>
    
             </Tabs>
            </AppBar>
          ):(
            <AppBar position="static" className="app_bar">
            <Tabs TabIndicatorProps={{style: {width:"17%",margin:"0px 10px"}}}  className="tabs"
                            variant="fullWidth" value={selectedTab} onChange={handleChange}>
    
                <Tab style={{textTransform:"capitalize"}} className="tab_title" label={`${t('main.mainProperties')}`}  />
                <Tab style={{textTransform:"capitalize"}} className="tab_title" disabled label={`${t('main.placement')} ${t('main.Question')}`}/>
                <Tab style={{textTransform:"capitalize"}} className="tab_title" disabled label={`${t('main.chat')} ${t('main.Questions')}`}/>
                <Tab style={{textTransform:"capitalize"}} className="tab_title" disabled label={`${t('main.Video')}`}/>
                <Tab style={{textTransform:"capitalize"}} className="tab_title" disabled label={`${t('main.Game')}`}/>
    
             </Tabs>
            </AppBar>
          )
      }
       </div>

       {selectedTab === 0 && <GroupProperties/> }
       {selectedTab === 1 && <OpeningQuestion/> }
       {selectedTab === 2 && <Questions/> }
       {selectedTab === 3 && <SelectVideo/> }
       {selectedTab === 4 && <SelectGame/> }

     </div>
    </>
 )
}

export default CreateGroup;
