import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosArrowRoundBack } from 'react-icons/io';
import ReactPaginate from 'react-paginate';
import {Link, useHistory, useParams} from 'react-router-dom';
import axios from '../../../axios';
import './offlineRoom.css'

function OfflineRooms() {
    const history = useHistory();
    const { t } = useTranslation();
    const getToken = localStorage.getItem("token")
    const parseToken = JSON.parse(getToken);

    const { name , _id, origin, id, email } = useParams();

    const [OfflineRoomDetails,setOfflineRoomDetails] = useState();

    const [searchDate,setSearchDate]=useState();
    
     const [isLoaded, setisLoaded] = useState(false);
     const [pageNumber , setPageNumber] = useState(0)


    useEffect(()=>{
        const fetchLiveRooms = async () => {
          const fetchres=await axios.get(`/room/filter?group=${_id}&status=2`,{
            headers: {
                "Authorization": "Bearer " + parseToken
              }
          })
          // console.log(fetchres?.data?.rooms.length)
          setOfflineRoomDetails(fetchres?.data?.rooms)
          if(fetchres?.data?.rooms.length >= 1){
            setisLoaded(true)
          }
         }
         fetchLiveRooms();
    },[])


    const usersPerPage = 10;
    const pagesVisited = pageNumber * usersPerPage;
    const Details = OfflineRoomDetails?.slice(pagesVisited , pagesVisited + usersPerPage )

    const pageCount = Math.ceil(OfflineRoomDetails?.length / usersPerPage )

    const handlePageChange = ({ selected }) =>{
        setPageNumber(selected)
    }
  
    // console.log("searchDate",searchDate);

    const linkTo =  origin === 'grouplist' ? '/group-list' : '/view-groups/'+id+'/' + email; 

    return (
        <div className="userlist">
       <h2 className="liveroom_title">{t('main.offlineRooms')}</h2>
        <div className="userlist_wrapper"
        >
            <div className="manageVideo_titleWrapper">
              <div className="manageVideo_title">
                 <div className="userlist_title">
                    <Link to={linkTo} style={{textDecoration:"none"}}>

                <IoIosArrowRoundBack className="back_icon"/>
	           	</Link> 
                   {name}
                 </div>
                 </div>
                  
                <div className="searchDate_div">
                <p className="searchDate_title">{t("main.searchbyDate")} :</p>
                 <input id="dateLabel" type="date" name="expirationdate" value={searchDate} className="searchDate_input offlineRoom__input"
                                  onChange={event => setSearchDate(event.target.value)} 
                     />
                </div>
            </div> 

                 <table className="table">
                         <thead>
				          <tr>
     	                <th>#</th>
                         <th>{t('main.unique')}</th>
     	                 <th>{t('main.sessionTime')}</th>
                          <th>{t('main.startDateTime')}</th>
                         <th>{t('main.operation')}</th>
				        </tr>
                             </thead>

                    {
                        Details?.length === 0 ? (
                            <tbody>
                                <tr>
                                <td style={{color:"red",fontSize:"18px"}}>{t("main.noofflineroomforthisgroup")}</td>
                                </tr> 
                            </tbody>       
                         ):(
                            <>
                              <tbody>
             
                 {
                       Details?.filter((room)=>{
                          if(searchDate === undefined){
                              return room
                          }else if(new Date(room?.createDateTime).toLocaleDateString().includes(new Date(searchDate).toLocaleDateString())){
                            // { console.log("room",room) }
                             return room
                               }
                       }).map( (room,index) => (

                            <tr key={index}>
                         <td data-label="#">{index + (pageNumber * 10) + 1}</td>
                         
                         <td data-label={`${t('main.unique')}`}>{room?.id}</td>
 
                         <td data-label={`${t('main.sessionTime')}`}>{room?.sessionTime < 1 ? "0 mins": `${room?.sessionTime} mins`}</td>

                         <td data-label={`${t('main.startDateTime')}`}>{new Date(`${room?.createDateTime}`).toDateString()} { new Date(`${room?.createDateTime}`).toLocaleTimeString()}</td>

                               
                         <td data-label={`${t('main.operation')}`} >
                             
                             <div className="room_operation_btn_div">
                          <Link to={`/offlineRoom-details/${room?.sessionTime < 1 ? "0" : room?.sessionTime }/${room?.id}/${origin}/${id}/${email}`} className="details_btn" >
                          {t('main.details')}
                          </Link> 
                             </div>   
                                </td> 
                            </tr>
                       ))
                    }
                          </tbody>
                            </>
                        )
                    }
                         </table>
  
                                     {/* <div className="rooms_btn_div">
                                     </div> */}

                      <div className="pagination_wrapper">

                                { isLoaded &&
                         <ReactPaginate
                         previousLabel={`${t('main.previous')}`}
                         nextLabel={`${t('main.next')}`}
                         pageCount={pageCount}
                         onPageChange={handlePageChange}
                         containerClassName={"pagination_btn"}
                          previousClassName={"previousBtn"}
                          activeClassName={"pagination_btn_active"}
                          />
                        } 
                            </div>
                    </div>
        </div>    
    )
}

export default OfflineRooms;

