import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { Link, useHistory } from 'react-router-dom';
import './createQuestion.css';
import axios from '../../../axios';
import UploadVideo from '../../Videos/UploadVideo';

const CreateQuestion = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [errors,setErrors]=useState({});
    const userid = localStorage.getItem("userid");
    const parsedUserid = JSON.parse(userid);

    const [input,setInput]= useState({
        description:"",
        subject:"",
        expTime:"",
        imgUrl:"",
        mediaUrl:"",
        choice1:"",
        choice2:"",
        choice3:"",
        choice4:"",
        choice5:"",
        
    })

    const [radio,setRadio]=useState();
    const [dropDown,setDropDown]=useState();

    const [imageSelected,setImageSelected]=useState("");
    const [videoSelected,setVideoSelected]=useState("");
    const [uploadPercentage,setUploadPercentage]=useState(0);

    const [uploadVideoError,setUploadVideoError]=useState()
    const [uploadVideoLoading,setUploadVideoLoading]=useState(false);

    const [uploadImageError,setUploadImageError]=useState()
    const [uploadImageLoading,setUploadImageLoading]=useState(false);


    const getToken = localStorage.getItem("token")
    const parseToken = JSON.parse(getToken)

    const validateInfo=()=>{
        let errors = {};

        if(!radio){
            errors.radio=`${t("main.Question")} ${t("main.type")} ${t("main.required")}`
        }

        if(!input.description){
            errors.description=`${t("main.description")} ${t("main.required")}`
        } 

        if(!input.subject){
            errors.subject=`${t("main.subject")} ${t("main.required")}`
        }
    
        if(!input.expTime){
            errors.expTime=`${t("main.expirationTime")} ${t("main.required")}`
        }

        if(!dropDown){
            errors.dropDown=`${t("main.language")} ${t("main.required")}`
        }
        return errors;
     }

    const handleChange=(e)=>{
        setInput(
         {
             ...input,
             [e.target.name]:e.target.value
         }
        )
    }

    const handleSubmit= async(e)=>{
       e.preventDefault();
       setErrors(validateInfo());

       if(Object.keys(validateInfo()).length > 0){
           return;
       }else{   
      try{
         
        let medialink = "";
        if( input?.imgUrl?.length > 1  ){
             medialink = input.imgUrl
        }else if(input?.mediaUrl?.length > 1){
             medialink = input.mediaUrl
            }
    //   console.log("APImedialink",medialink);

       const res = await axios.post('/question',{
           quesType:radio,
           user:parsedUserid,
           description:input.description,
           expTime:input.expTime,
           mediaUrl:medialink,
           language:dropDown,
           subject:input.subject,
           choice1:input.choice1,
           choice2:input.choice2,
           choice3:input.choice3,
           choice4:input.choice4,
           choice5:input.choice5,
       },{
        headers: {
            "Authorization": "Bearer " + parseToken
          } 
       })
        // console.log(res);  
       if(res.statusText === "Created"){
           history.push('/question-list')
       }

      }catch(e){
          console.log(e.response?.data?.error);
      }
    }
    }

    const uploadFile=async(e)=>{
        e.preventDefault();
        setUploadImageLoading(true)
        setUploadImageError("")
        const fData = new FormData()
        fData.append('image',imageSelected)
        try{
        const res = await axios.post('/question/image',fData,{
        headers: {
            "Authorization": "Bearer " + parseToken
          },
         }
         )
       if(res?.statusText === "Created"){
        setInput({...input,imgUrl:res.data.url})
        setUploadImageLoading(false)
       }
     
     }catch(e){
        if(e.response?.data?.error){
            setUploadImageError(e.response?.data?.error);
            setUploadImageLoading(false)
        }
     }
    }
 

    return (
        <div className="createVideo">

        <div className="createVideo_wrapper">

          <div className="accountDetails_title" >
               <Link to='/question-list' style={{textDecoration:"none"}}>
		        <IoIosArrowRoundBack className="back_icon_centerAlign"/>
	           </Link>
           <div style={{marginRight:"20px"}}> {t('main.create')}&nbsp;{t('main.Question')}</div>
                    </div>
       

               <form className="createVideo_form">

                       <div className="createVideo_form1">

                           <div className="createVideo_radioConatiner">
                        <input type="radio" id="question" checked={radio === "1"}  value="1" onChange={(e)=>{setRadio(e.target.value)}} /><label style={{marginRight:"15px"}} htmlFor="question">{t('main.Question')}</label>
                        <input type="radio" id="instruction" checked={radio === "2"} value="2" onChange={(e)=>{setRadio(e.target.value)}} /><label htmlFor="instruction">{t('main.instruction')}</label>    
                           </div>
                     {errors.radio && <p className="form_dropdown_error" style={{marginTop:"5px",marginBottom:"-5px"}}>{errors.radio}</p>}

                         <div className="form__input_wrapper">
                        <label className="form__input_label">{t("main.description")}</label>  
                       <textarea name="description" value={input.description} onChange={handleChange} className="createQuestion_textarea"  placeholder={`${t("main.enter")} ${t("main.description")}`}></textarea>
                       {errors.description && <p className="form_dropdown_error">{errors.description}</p>}
                       </div>

                       <div className="form__input_wrapper">
                        <label className="form__input_label">{t("main.expirationTime")}</label>  
                       <input type="number" name="expTime"  value={input.expTime} onChange={handleChange} className="createVideo_input"  placeholder={`${t("main.enterExpirationTimeInMinutes")}`}/>
                       {errors.expTime && <p className="form_dropdown_error">{errors.expTime}</p>}
                       </div>

                       {
                            input?.mediaUrl.length > 0 || uploadVideoLoading ? (
                                <div className="form__input_wrapper">
                               <label className="form__input_label">{t("main.image")} {t("main.url")}</label>  
                               <input type="text" disabled  value=""  className="createVideo_input"  placeholder={` ${t("main.image")} ${t("main.url")}`}/>
                              </div>
                             ):(
                            <>
                              <div className="form__input_wrapper">
                              <label className="form__input_label">{t("main.image")} {t("main.url")}</label>  
                            <input type="text" name="imgUrl" disabled value={input?.imgUrl} onChange={handleChange} className="createVideo_input"  placeholder={` ${t("main.image")} ${t("main.url")}`}/>
                            </div>

                            <span className="maximum_disclaimer">{`* ${t("main.maxUploadSize")} 1mb *`}</span>

                             <input type="file" className="choose_file" accept="image/*" onChange={(event)=>{setImageSelected(event.target.files[0])}}/>
                             { uploadImageLoading && <p className="form_dropdown_error loading__disclaimer">{`${t("main.uploadingFilePleaseWait")}......`}</p> }

                             <span className="form_dropdown_error">{uploadImageError} </span>
                    
                            <button className="upload_btn" onClick={uploadFile}>{t('main.uploadImage')}</button>
                            </>
                           ) 
                        }
                       </div>

                       <div className="createVideo_form2">

                       <div className="form__input_wrapper">
                        <label className="form__input_label">{t("main.language")}</label>  
                       <select className="createVideo_input" value={dropDown} onChange={(e)=>setDropDown(e.target.value)} >
                        <option value="" disabled selected >{t("main.select")} {t("main.language")}</option>
                        <option value="1">{t("main.eng")}</option>
                        <option value="2">{t("main.chi")}</option>
                       </select>
                       {errors.dropDown && <p className="form_dropdown_error">{errors.dropDown}</p>}
                       </div>

                       <div className="form__input_wrapper">
                        <label className="form__input_label">{t("main.subject")}</label>  
                        <input type="text" name="subject"  value={input.subject} onChange={handleChange} className="createVideo_input"  placeholder={`${t("main.enter")} ${t("main.subject")}`}/>
                        {errors.subject && <p className="form_dropdown_error">{errors.subject}</p>}
                        </div>

                       {
                           input?.imgUrl.length > 0 || uploadImageLoading ?  (
                            <div className="form__input_wrapper">
                            <label className="form__input_label">{t("main.Video")} {t("main.url")}</label>  
                            <input type="text" disabled  value=""  className="createVideo_input"  placeholder={`${t("main.Video")} ${t("main.url")}`}/>
                            </div>
                            ):(
                               <UploadVideo 
                            backendMessage={uploadVideoError}
                            setBackendMessage={setUploadVideoError}
                            loaded={uploadVideoLoading}
                            setLoaded={setUploadVideoLoading}
                            videoSelected={videoSelected}
                            setVideoSelected={setVideoSelected}
                            parseToken={parseToken}
                            uploadPercentage={uploadPercentage}
                            setUploadPercentage={setUploadPercentage}
                            setInput={setInput}
                            errors={errors}
                            input={input}
                            handleChange={handleChange}
                            /> 
                            ) 
                       }

                       </div>
               </form>

              <form className="createQuestion_ChoiceForm">
                        <div className="createQuestion_ChoiceForm1">
                        
                        <div className="form__input_wrapper">
                             <label className="form__input_label">{t("main.choice")} 1</label>  
                             <input type="text" name="choice1"  value={input.choice1} onChange={handleChange} className="createVideo_input"  placeholder={`${t("main.enter")} ${t("main.choice")} 1`}/>
                        </div> 

                       <div className="form__input_wrapper">
                             <label className="form__input_label">{t("main.choice")} 2</label> 
                             <input type="text" name="choice2"  value={input.choice2} onChange={handleChange} className="createVideo_input"  placeholder={`${t("main.enter")} ${t("main.choice")} 2`}/>
                       </div>

                       <div className="form__input_wrapper">
                             <label className="form__input_label">{t("main.choice")} 3</label> 
                             <input type="text" name="choice3"  value={input.choice3} onChange={handleChange} className="createVideo_input"  placeholder={`${t("main.enter")} ${t("main.choice")} 3`}/>
                             </div>
                       </div>

                       <div className="createQuestion_ChoiceForm2">
                       
                       <div className="form__input_wrapper">
                             <label className="form__input_label">{t("main.choice")} 4</label> 
                             <input type="text" name="choice4"  value={input.choice4} onChange={handleChange} className="createVideo_input"  placeholder={`${t("main.enter")} ${t("main.choice")} 4`}/>
                      </div>

                       <div className="form__input_wrapper">
                             <label className="form__input_label">{t("main.choice")} 5</label> 
                            <input type="text" name="choice5"  value={input.choice5} onChange={handleChange} className="createVideo_input"  placeholder={`${t("main.enter")} ${t("main.choice")} 5`}/>
                      </div>
                 </div>
               </form>

               { uploadImageLoading || uploadVideoLoading ?  (
                    <>
                    </>
               ):(
                    <div className="createVideo_btn_div">
                    <button className="createVideo_btn" onClick={handleSubmit}>{t('main.submit')}</button>
                         </div>
               )
                }
         </div>

        </div>
    )
}

export default CreateQuestion; 
