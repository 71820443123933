import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './createVideo.css';
import axios from '../../../axios';
import { useTranslation } from 'react-i18next';
import { IoIosArrowRoundBack } from 'react-icons/io';
import UploadVideo from '../UploadVideo';

const CreateVideo = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [errors,setErrors]=useState({});

    const [input,setInput]= useState({
        shortName:"",
        mediaUrl:"",
        description:"",
        subject:""
    })
    const [dropDown,setDropDown]=useState();

    const [videoSelected,setVideoSelected]=useState("");
    const [uploadPercentage,setUploadPercentage]=useState(0);
    const [backendMessage,setBackendMessage]=useState()
    const [loaded,setLoaded]=useState(false);

    const getToken = localStorage.getItem("token")
    const parseToken = JSON.parse(getToken)

    const validateInfo=()=>{
        let errors = {};

        if(!input.shortName){
            errors.shortName=`${t("main.name")} ${t("main.required")}`
        } 
        if(!input.description){
            errors.description=`${t("main.description")} ${t("main.required")}`
        }
    
        if(!input.subject){
            errors.subject=`${t("main.subject")} ${t("main.required")}`
        }
        if(!input.mediaUrl){
            errors.mediaUrl=`${t("main.Video")} ${t("main.required")}`
        }
        if(!dropDown){
            errors.dropDown=`${t("main.language")} ${t("main.required")}`
        }
        return errors;
     }

    const handleChange=(e)=>{
        setInput(
         {
             ...input,
             [e.target.name]:e.target.value
         }
        )
    }

    const handleSubmit =  async (e) =>{
       e.preventDefault();
       setErrors(validateInfo())
       if(Object.keys(validateInfo()).length > 0){
        return;
       }else{ 
      try{
       const res = await axios.post('/video',{
           description:input.description,
           mediaUrl:input.mediaUrl,
           language:dropDown,
           shortName:input.shortName,
           subject:input.subject,
       },{
        headers: {
            "Authorization": "Bearer " + parseToken
          } 
       })
       console.log(res);
       if(res.statusText === 'Created'){
           history.push('/manage-videos')
       }

      }catch(e){
          console.log(e.response?.data?.error);
      }
     }
    }

    return (
        <div className="createVideo">

        <div className="createVideo_wrapper">
            
        <div className="accountDetails_title" >
            <Link to='/manage-videos' style={{textDecoration:"none"}}>
		        <IoIosArrowRoundBack className="back_icon_centerAlign"/>
	           </Link>
           <div style={{marginRight:"20px"}}> {t('main.add')}&nbsp;{t('main.Video')}</div>
                    </div>

               <form className="createVideo_form">

                       <div className="createVideo_form1">

                         <div className="form__input_wrapper">
                        <label className="form__input_label">{t("main.shortName")}</label>  
                       <input type="text" name="shortName"  value={input.shortName} onChange={handleChange} className="createVideo_input"  placeholder={`${t("main.enter")} ${t("main.shortName")}`}/>
                       {errors.shortName && <p className="form_dropdown_error">{errors.shortName}</p>}
                        </div>

                        <div className="form__input_wrapper">
                        <label className="form__input_label">{t("main.language")}</label> 
                       <select className="createVideo_input" value={dropDown} onChange={(e)=>setDropDown(e.target.value)} >
                        <option value="" disabled selected >{t("main.select")} {t("main.language")}</option>
                        <option value="1">{t("main.eng")}</option>
                        <option value="2">{t("main.chi")}</option>
                       </select>
                       {errors.dropDown && <p className="form_dropdown_error">{errors.dropDown}</p>}
                       </div>

                            <UploadVideo 
                            backendMessage={backendMessage}
                            setBackendMessage={setBackendMessage}
                            loaded={loaded}
                            setLoaded={setLoaded}
                            videoSelected={videoSelected}
                            setVideoSelected={setVideoSelected}
                            parseToken={parseToken}
                            uploadPercentage={uploadPercentage}
                            setUploadPercentage={setUploadPercentage}
                            setInput={setInput}
                            errors={errors}
                            input={input}
                            handleChange={handleChange}
                            /> 
                       </div>


                       <div className="createVideo_form2">
                       <div className="form__input_wrapper">
                        <label className="form__input_label">{t("main.subject")}</label> 
                       <input type="text" name="subject"  value={input.subject} onChange={handleChange} className="createVideo_input"  placeholder={`${t("main.enter")} ${t("main.subject")}`}/>
                       {errors.subject && <p className="form_dropdown_error" >{errors.subject}</p>}
                       </div>
                        
                       <div className="form__input_wrapper">
                        <label className="form__input_label">{t("main.description")}</label> 
                       <textarea name="description" value={input.description} onChange={handleChange} className="createVideo_textarea"  placeholder={`${t("main.enter")} ${t("main.description")}`}></textarea>
                       {errors.description && <p className="form_dropdown_error" style={{marginTop:"3px"}}>{errors.description}</p>}
                       </div>

                       </div>
               </form>

               <div className="createVideo_btn_div">
           <button className="tab_btn" onClick={handleSubmit}>{t('main.submit')}</button>
                  </div>
         </div>

        </div>
    )
}

export default CreateVideo
