import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Routes from './routes/Routes';
import Login from './components/Login/Login';
import ForgotPassword from './components/Login/ForgotPassword';
import PageNotFound from './components/PageNotFound';
import ResetPassword from './components/Login/ResetPassword';
import { useState } from 'react';
import axios from './axios';

function App() {
  const getToken = localStorage.getItem("token")
  const parseToken = JSON.parse(getToken);
  const [authError , setAuthError] = useState("");
  
  useEffect(()=>{
    const fetchGames= async()=>{
      console.log("app triggered");
    try{
        await axios.get(`/game`,{
       headers: {
     "Authorization": "Bearer " + parseToken
         }  
       })	
      //  console.log(res);
      } 
      catch(e) { 
       setAuthError(e.response?.data?.error)
     }
    }
      fetchGames();
  },[authError,parseToken])

   console.log("authErrorFromApp >>>>",authError);

  return (
    <div className="App">
     <BrowserRouter>
     {
       parseToken ? (
        <Routes authError={authError} />
       ):(
         <>
       <Switch>
       <Redirect exact from='/login' to="/" />
       <Route exact path="/"><Login/></Route>
       <Route  path="/forgot-password"><ForgotPassword/></Route>
       <Route  path="/reset-password"><ResetPassword/></Route>
       <Route exact component={PageNotFound} />
       </Switch>
        </>
       )
     }
     </BrowserRouter>
    </div>
  );
}

export default App;
