import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineDownload } from 'react-icons/hi';
import { RiDeleteBin6Line } from 'react-icons/ri';
import Modal from 'react-modal';
import axios from '../../../../axios';
import './chatExportPopUp.css';
import {CgClose} from 'react-icons/cg';
import DownloadIcon from '../../../../assets/images/download.png';

Modal.setAppElement('#root')

const customStyles = {
  overlay:{
      
  },
  content: {
    top: '35%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    minWidth: '50%',
    maxWidth: '60%',
    minHeight: '35%',
    transform: 'translate(-50%, -10%)',
    borderRadius:"10px",
    boxShadow:"0px 0px 20px #0000001e",
    overflow:"hidden"
  }
};

function ChatExportPopUp({_id , parseToken }) {
    const { t } = useTranslation();
    const [ modalIsOpen,setModalIsOpen ] = useState(false);
    const [errors,setErrors]=useState({});
    const [backendError,setBackendError]=useState(false);
    const [calenderData,setCalenderData]=useState({
      fromDate:"",
      toDate:"",
    })
  
      const handleChange =(e)=>{
        setCalenderData({
          ...calenderData,
          [e.target.name]:e.target.value
        })
      }
       
      const validateInfo=()=>{
          let errors = {};

          if(!calenderData.fromDate){
            errors.fromDate = `${t("main.fromDate")} ${t("main.required")}`
          }

          if(!calenderData.toDate){
            errors.toDate = `${t("main.toDate")} ${t("main.required")}`
          }
          return errors;
      }
      
      // calender format => 2012-02-26
      let fromDate = calenderData.fromDate;
      fromDate = fromDate.split("-");
      // console.log("fromDate",fromDate);
      const newfromDate = new Date( fromDate[0], fromDate[1] - 1, fromDate[2]);
      const getFromDateTime = newfromDate.getTime()
      // console.log("getFromDateTime",getFromDateTime);

      let toDate = calenderData.toDate;
      toDate = toDate.split("-");
      // console.log("toDate",toDate);
      const newToDate = new Date( toDate[0], toDate[1] - 1, toDate[2]);
      const getToDateTime = newToDate.getTime()
      // console.log("getToDateTime",getToDateTime);

      const handleExport = async (e) => {
        e.preventDefault();
        setErrors(validateInfo())
        if(Object.keys(validateInfo()).length > 0){
          return
        }else{
             try{

              const exportUrl = `/group/export/${_id}/?from=${getFromDateTime}&to=${getToDateTime}&locale=${new Date().getTimezoneOffset()}`
              const exportRes = await axios.get(exportUrl, 
              {
                headers: {
                "Authorization": "Bearer " + parseToken 
               } 
              }
             )
             window.location = exportRes.data.url;
             if(exportRes?.status === 200){
              setBackendError(false)
             }
            //  console.log(exportRes);
             }
             catch(e){
               const fetchError = e.response?.status
              //  console.log(fetchError);
               if(fetchError === 404){
                setBackendError(true)
               }
             }
        }
      }

    return (
        <>
            <HiOutlineDownload className="grouplist_deleteIcon grouplist__downloadIcon" onClick={()=> setModalIsOpen(true)}/>
            <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen (false) }  style={customStyles}>
            <div className="ChatExportPopUp">
                   <div className="ChatExportPopUp__title_wrapper">
                   <h3 className="ChatExportPopUp__title">{t("main.export")} {t("main.chatTranscripts")}</h3>
                    <CgClose className="ChatExportPopUp__icon" onClick={()=> setModalIsOpen(false)}/>
                   </div>
                   

                   <div className="ChatExportPopUp__inputFields-wrapper">

                    <div className="ChatExportPopUp__input-wrapper">
                    <label className="form__input_label formManageGroup__input_label">{t("main.fromDate")}</label> 
                   <input  type="date" name="fromDate" value={calenderData.fromDate} className="searchDate_input ChatExportPopUp__input"
                                  onChange={handleChange} /> 
                    {errors.fromDate && <h6 className="form_dropdown_error ChatExportPopUp__error">{errors.fromDate}</h6>}
                   </div>

                   <div className="ChatExportPopUp__input-wrapper">
                    <label className="form__input_label formManageGroup__input_label">{t("main.toDate")}</label> 
                    <input type="date" name="toDate" value={calenderData.toDate} className="searchDate_input ChatExportPopUp__input"
                                  onChange={handleChange} />
                    {errors.toDate && <h6 className="form_dropdown_error ChatExportPopUp__error">{errors.toDate}</h6>} 
                   </div>
                </div>

                    <div className="ChatExportPopUp_btnWrapper">
                        {backendError && <h6 className="form_dropdown_error">{t("main.chatTranscriptError")}</h6>}                      
                    <button className="export_btn ChatExportPopUp_btn" onClick={handleExport} ><img src={DownloadIcon} alt="Download" className="export_img"/>{t('main.export')}</button>
                    </div>
               </div>
            </Modal>
        </>
    )
}

export default ChatExportPopUp;
