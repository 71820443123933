import React, { useEffect } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HomeWrapper from '../components/HomePage/HomeWrapper';
import Header from '../components/Header/index';
import QuestionList from '../components/Questions/QuestionList/QuestionList';
import UserList from '../components/Accounts/userList/UserList';
import AccountDetails from '../components/Accounts/modifyUserList/index';
import Login from '../components/Login/Login';
import ContactAdmin from '../components/Contact';
import CreateGame from '../components/Games/CreateGame';
import CreateVideo from '../components/Videos/CreateVideo';
import ManageVideos from '../components/Videos/ManageVideos';
import ManageGames from '../components/Games/ManageGames';
import CreateQuestion from '../components/Questions/CreateQuestion';
import EditQuestion from '../components/Questions/CreateQuestion/EditQuestion';
import EditVideo from '../components/Videos/CreateVideo/EditVideo/EditVideo';
import EditGame from '../components/Games/CreateGame/EditGame/EditGame';
import GroupList from '../components/Groups/GroupList';
import CreateGroup from '../components/Groups/CreateGroup/CreateGroup';
import EditGroup from '../components/Groups/EditGroup/EditGroup';
import LiveRooms from '../components/Rooms/LiveRooms/index';
import OfflineRooms from '../components/Rooms/OfflineRooms';
import LiveRoomDetails from '../components/Rooms/LiveRooms/LRoomDetails';
import OfflineRoomDetails from '../components/Rooms/OfflineRooms/ORoomDetails';

import axios from '../axios';
import JWT from 'jsonwebtoken';
import ManageHosts from '../components/Hosts/ManageHosts';
import ViewGroups from '../components/Hosts/ViewGroups';
import ChatRooms from '../components/Rooms/ChatRooms';
import ChatRoomDetails from '../components/Rooms/ChatRooms/ChatRoomDetails';
import ForgotPassword from '../components/Login/ForgotPassword';
import ResetPassword from '../components/Login/ResetPassword';
import ViewQuestion from '../components/Questions/ViewQuestion/index';

import PageNotFound from '../components/PageNotFound';


const Routes = ({authError}) => {
    const { t } = useTranslation();
    const history = useHistory();

    const getToken = localStorage.getItem("token")
    const parseToken = JSON.parse(getToken)
    const userData = JWT.decode(parseToken)

    console.log("sessionTimeout>>>>>>>",authError);
  
    useEffect(()=>{
      if(authError === "auth failed"){
        localStorage.removeItem("token");
        localStorage.removeItem("userid");
        history.push('/login')
        alert(`${t("main.sessionExpired")}`)
       }
	 },[parseToken,history,authError])

    //  console.log("role from routes",userData?.role);
    return (  
         <div>
        {
            userData?.role === 1 ? (

         // These routes are for ADMIN!

                <Switch>
         <Route exact path="/"><HomeWrapper/></Route>

         <Route exact path="/login"><Login/></Route>
        <Route exact path="/forgot-password"><ForgotPassword/></Route>
        <Route exact path="/reset-password"><ResetPassword/></Route>
       
        <Route path="/users-list"> <Header/> <UserList/> </Route>
        
        <Route path="/account-details/:_id"><Header/><AccountDetails/> </Route>
      
        <Route path="/question-list"> <Header/><QuestionList/> </Route>
        <Route path="/create-question"> <Header/><CreateQuestion/> </Route>
        <Route path="/edit-question/:_id"> <Header/><EditQuestion/> </Route>
        <Route path="/view-question/:_id"><Header/><ViewQuestion/></Route>

        <Route path="/group-list"><Header/><GroupList/></Route>

        <Redirect exact from='/create-group' to='/create-group/groupProperties'/>
        <Route exact path='/create-group/:page?' render={props => <CreateGroup {...props}/> } ></Route>

        <Route exact path='/edit-group/:_id/:origin/:email/:id'><EditGroup/></Route> 

        <Route path="/create-game"> <Header/><CreateGame/> </Route>
        <Route path="/manage-games"> <Header/><ManageGames/> </Route>
        <Route path="/edit-game/:_id"> <Header/><EditGame/> </Route>
        
        <Route path="/manage-videos"> <Header/><ManageVideos/> </Route>
        <Route path="/create-video"> <Header/><CreateVideo/> </Route>
        <Route path="/edit-video/:_id"> <Header/><EditVideo/> </Route>

        <Route path="/live-rooms/:name/:_id/:origin/:id/:email"><Header/><LiveRooms/></Route>
        <Route exact path="/liveroom-details/:sessionTime/:id/:origin/:userid/:email"><Header/><LiveRoomDetails/></Route> 

        <Route path="/offline-rooms/:name/:_id/:origin/:id/:email"><Header/><OfflineRooms/></Route>
        <Route exact path="/offlineRoom-details/:sessionTime/:id/:origin/:userid/:email"><Header/><OfflineRoomDetails/></Route> 

        <Route path="/manage-hosts"> <Header/><ManageHosts/> </Route>
        <Route path="/view-groups/:email/:id"> <Header/><ViewGroups/> </Route>

        <Route path="/chat-rooms"> <Header/><ChatRooms/></Route>
        <Route exact path="/chatRoom-details/:id"><Header/><ChatRoomDetails/></Route>

        {/* pagenotfound route should be in last route */}
        <Route exact component={PageNotFound} />
                   </Switch>       
          ):(
            
              // These routes are for RESEARCHER!

                  <Switch>
         <Route exact path="/"><HomeWrapper/></Route>

        <Route exact path="/login"><Login/></Route>
        <Route exact path="/forgot-password"><ForgotPassword/></Route>  
        <Route exact path="/reset-password"><ResetPassword/></Route>

        <Route path="/question-list"> <Header/><QuestionList/> </Route>
        <Route path="/create-question"> <Header/><CreateQuestion/> </Route>
        <Route path="/edit-question/:_id"> <Header/><EditQuestion/> </Route>
        <Route path="/view-question/:_id"><Header/><ViewQuestion/></Route>

        <Route path="/group-list"><Header/><GroupList/></Route>

        <Redirect exact from='/create-group' to='/create-group/groupProperties'/>
        <Route exact path='/create-group/:page?' render={props => <CreateGroup {...props}/> } ></Route>

        <Route exact path='/edit-group/:_id/:origin/:email/:id'><EditGroup/></Route> 

        <Route path="/live-rooms/:name/:_id/:origin/:id/:email"><Header/><LiveRooms/></Route>
        <Route exact path="/liveroom-details/:sessionTime/:id/:origin/:userid/:email"><Header/><LiveRoomDetails/></Route> 

        <Route path="/offline-rooms/:name/:_id/:origin/:id/:email"><Header/><OfflineRooms/></Route>
        <Route exact path="/offlineRoom-details/:sessionTime/:id/:origin/:userid/:email"><Header/><OfflineRoomDetails/></Route> 

        <Route path="/chat-rooms"> <Header/><ChatRooms/></Route>
        <Route exact path="/chatRoom-details/:id"><Header/><ChatRoomDetails/></Route> 

        <Route path="/contact-admin"> <Header/><ContactAdmin/> </Route>

        {/* pagenotfound route should be in last route */}
         <Route exact component={PageNotFound} />
                </Switch>
              )
          }
         </div>
    )
}

export default Routes;
