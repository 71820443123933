import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { blueBgCustomStyles } from '../../../ModalLayout';

Modal.setAppElement('#root')
function EditPopUp({ modalIsOpen , setModalIsOpen, handleSubmit  }) {
    const { t } = useTranslation();
   
    return (
        <div>
            <button className="tab_btn" onClick={handleSubmit}>{t('main.update')}</button>
            <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen (false) }  style={blueBgCustomStyles}>
                <div className="modal_wrapper">
                <p className="createGroup_ModalTitle">{t('main.editedChangesSuccessfully')}</p>

                <button className="create_btn" onClick={()=> setModalIsOpen(false)}>{t('main.ok')}</button>
                </div>
            </Modal>
        </div>
    )
}

export default EditPopUp;
