import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import axios from '../../axios';
import DeletePopup from '../Groups/EditGroup/EditProperties/DeletePopup';

const UploadVideo = ({ 
    backendMessage,
    setBackendMessage , 
    loaded,
    setLoaded ,
    videoSelected,
    setVideoSelected,
    parseToken,
    uploadPercentage,
    setUploadPercentage,
    setInput,
    errors,
    input,
    handleChange,
    editQues = false,
    _id,
}) => {
    //  console.log(input?.mediaUrl)

    const { t } = useTranslation();
    const [delVideoModal , setDelVideoModal ] = useState(false);

    const delVideoData = async() =>{
        const videoName = input?.mediaUrl.split('/').slice(-1).join() 
        try{

            const vidDelRes = await axios.delete(`/video/purge?key=${videoName}`,{
                headers: {
                    "Authorization": "Bearer " + parseToken
               }
            })

            // console.log("vidDelRes",vidDelRes);

            const res = await axios.put(`/question?id=${_id}`,{
                mediaUrl:"",
              },{
                  headers: {
                      "Authorization": "Bearer " + parseToken
                 }
              })
            //   console.log(res);
              if(res.status === 200){
                  setDelVideoModal(false);
                  setInput({...input,mediaUrl:""})
              }
            //   console.log(res);
        }catch(e){
            console.log(e.response?.data?.error);
        }
}

    const uploadFile=async(e)=>{
        e.preventDefault();
        setBackendMessage("")
        setLoaded(true)

        const fData = new FormData()
        fData.append('video',videoSelected)
        try{

        const res = await axios.post('/video/upload',fData,{
        headers: {
            "Authorization": "Bearer " + parseToken
          },
          onUploadProgress:progressEvent=>{
              let percentage = parseInt(Math.round((progressEvent.loaded *100 ) / progressEvent.total ))
               if(percentage < 100){
                setUploadPercentage(percentage) 
               } 
          }
         })
       if(res?.statusText === "Created"){
           if(editQues){
               console.log("del triggered");
            setInput({imgUrl:"",mediaUrl:res?.data?.url})
           }else if(!editQues){
                console.log("normal triggered");
                 setInput({...input,mediaUrl:res?.data?.url})
           }

            setTimeout(()=>{
                setLoaded(false)
               setUploadPercentage(0)
            },1000)
       }  
     }catch(e){
        if(e.response?.data?.error){
            setBackendMessage(e.response?.data?.error);
            setLoaded(false)
            setUploadPercentage(0)
        }
     }
    }
    return (
        <>
                    <div className="form__input_wrapper">
                             <label className="form__input_label">{t("main.Video")} {t("main.url")}</label>  
                             {errors.mediaUrl && <p className="form_dropdown_error">{errors.mediaUrl}</p>}
                            <input type="text" name="mediaUrl" disabled value={input.mediaUrl} onChange={handleChange} className="createVideo_input"  placeholder={` ${t("main.Video")} ${t("main.url")}`}/>
                            </div>

                            <span className="maximum_disclaimer">{`* ${t("main.maxUploadSize")} 100mb *`}</span>

                             <input type="file" className="choose_file" accept="video/*" onChange={(event)=>{setVideoSelected(event.target.files[0])}}/>
                             { loaded && <p className="form_dropdown_error loading__disclaimer">{`${t("main.uploadingFilePleaseWait")}......`}</p> }

                             <span className="form_dropdown_error">{backendMessage} </span>

                             { uploadPercentage > 0 && <div className="loading__bar-wrapper">
                                 <div className="loading__bar" style={{width:`${uploadPercentage}%`}}>{uploadPercentage}%</div>
                             </div>}
                    
                           {!loaded && 
                           <div className="ChatGroupCreate__uploadBtn-wrapper">
                           <button className="upload_btn" onClick={uploadFile}>{t('main.upload')}&nbsp;{t('main.Video')}</button>
                           { editQues && input?.mediaUrl && <DeletePopup delVideoModal={delVideoModal} setDelVideoModal={setDelVideoModal} delVideoData={delVideoData} path="ESelectVid"/>}
                           </div>
                           }
        </>
    )
}

export default UploadVideo
