import React, { useState } from 'react';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState, convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

function EditTextEditor({setTextEditor,textEditor}){
    const contentBlock = htmlToDraft(textEditor);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState))

    const onEditorStateChange = (editorState)=>{
        let change = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        setEditorState(editorState)  
        setTextEditor(change)
    }
    // console.log("editorState",draftToHtml(convertToRaw(editorState.getCurrentContent())))
    
    return (
        <>
           <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
             wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
             onEditorStateChange={onEditorStateChange}
           />
            
        </>
    )
}

export default EditTextEditor;