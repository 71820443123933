import React from "react";
import { useTranslation } from "react-i18next";
import { RiDeleteBin6Line } from "react-icons/ri";
import Modal from "react-modal";
import { customStyles } from "../../../ModalLayout";

Modal.setAppElement("#root");
function DeletePopup({
  clearInputValues,
  pQuesModal,
  setPQuesModal,
  path,
  delVideoModal,
  setDelVideoModal,
  delVideoData,
  delImgModal,
  setDelImgModal,
  delImage,
  delTranscript,
  delBotTranscriptModal,
  setDelBotTranscriptModal
}) {
  const { t } = useTranslation();
  //testing

  return (
    <>
      {path === "EPlacementQues" && (
        <div>
          <RiDeleteBin6Line
            className="openingQues__delIcon"
            onClick={() => setPQuesModal(true)}
          />
          <Modal
            isOpen={pQuesModal}
            onRequestClose={() => setPQuesModal(false)}
            style={customStyles}
          >
            <div className="modal_wrapper">
              <h3>
                {t("main.doYouWantToDeleteThe")} {t("main.placementQuestion")} ?
              </h3>
              <div className="modal_btns">
                <button className="modal_red_btn" onClick={clearInputValues}>
                  {t("main.yes")}
                </button>
                <button
                  className="modal_blue_btn"
                  onClick={() => setPQuesModal(false)}
                >
                  {t("main.no")}
                </button>
              </div>
            </div>
          </Modal>
        </div>
      )}

      {path === "ESelectVid" && (
        <div>
          <RiDeleteBin6Line
            className="openingQues__delIcon"
            onClick={() => setDelVideoModal(true)}
          />
          <Modal
            isOpen={delVideoModal}
            onRequestClose={() => setDelVideoModal(false)}
            style={customStyles}
          >
            <div className="modal_wrapper">
              <h3>
                {t("main.doYouWantToDeleteThe")} {t("main.video")} ?
              </h3>
              <div className="modal_btns">
                <button className="modal_red_btn" onClick={delVideoData}>
                  {t("main.yes")}
                </button>
                <button
                  className="modal_blue_btn"
                  onClick={() => setDelVideoModal(false)}
                >
                  {t("main.no")}
                </button>
              </div>
            </div>
          </Modal>
        </div>
      )}

      {path === "welcome_image" && (
        <div>
          <RiDeleteBin6Line
            className="openingQues__delIcon"
            onClick={() => {
              setDelImgModal(true);
              setTimeout(() => {
                document.querySelector(".ReactModal__Overlay").style.zIndex =
                  "2";
              }, 500);
            }}
          />
          <Modal
            isOpen={delImgModal}
            onRequestClose={() => setDelImgModal(false)}
            style={customStyles}
          >
            <div className="modal_wrapper">
              <h3>
                {t("main.doYouWantToDeleteThe")} {t("main.wel")} {t("main.img")} ?
              </h3>
              <div className="modal_btns">
                <button className="modal_red_btn" onClick={delImage}>
                  {t("main.yes")}
                </button>
                <button
                  className="modal_blue_btn"
                  onClick={() => setDelImgModal(false)}
                >
                  {t("main.no")}
                </button>
              </div>
            </div>
          </Modal>
        </div>
      )}

      {path === "bot_transcript" && (
        <div>
          <RiDeleteBin6Line
            className="openingQues__delIcon"
            onClick={() => {
              setDelBotTranscriptModal(true);
              setTimeout(() => {
                document.querySelector(".ReactModal__Overlay").style.zIndex =
                  "2";
              }, 500);
            }}
          />
          <Modal
            isOpen={delBotTranscriptModal}
            onRequestClose={() => setDelBotTranscriptModal(false)}
            style={customStyles}
          >
            <div className="modal_wrapper">
              <h3>
                {t("main.doYouWantToDeleteThe")}  {t("main.chatbottranscript")} ?
              </h3>
              <div className="modal_btns">
                <button className="modal_red_btn" onClick={delTranscript}>
                  {t("main.yes")}
                </button>
                <button
                  className="modal_blue_btn"
                  onClick={() => setDelBotTranscriptModal(false)}
                >
                  {t("main.no")}
                </button>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
}

export default DeletePopup;
