import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import axios from '../../../../axios';
import EditPopUp from '../EditPopUp/EditPopUp';

const EditSelectGame = ({ groupId , chatStatus}) => {
    const { t } = useTranslation();
    // console.log("chatStatus4",chatStatus);
    const [radio,setRadio]=useState();
    const [Submiterrors,setSubmiterrors]=useState({});
    const [ modalIsOpen,setModalIsOpen ] = useState(false);

    const [gameDetails,setGameDetails]=useState([]);

    const getToken = localStorage.getItem("token")
	  const parseToken = JSON.parse(getToken);

    useEffect(()=>{
      const fetchEditGame = async () => {
        const fetchres=await axios.get(`/group/${groupId}`,{
          headers: {
              "Authorization": "Bearer " + parseToken
            }
        })
        setGameDetails([ fetchres?.data.game ])
        setRadio(fetchres.data.game?._id)
       }
       fetchEditGame();
    },[groupId,parseToken])
     

      const fetchGames= async()=>{
         try{
            const fetchGamesres = await axios.get(`/game`,{
				headers: {
				  "Authorization": "Bearer " + parseToken
				}
		 	 })	
         setGameDetails(fetchGamesres.data.games);
        } 
         catch(e) {
            console.log(e.response.data.error); 
         }
      }

      const submitValidateInfo=()=>{
        let Submiterrors = {};
        if(!radio){
          Submiterrors.radio=`${t("main.select")} ${t("main.game")} !`
        }
        return Submiterrors;
     }

      const handleSubmit=async(e)=>{
        e.preventDefault();
        setSubmiterrors(submitValidateInfo());
        if(Object.keys(submitValidateInfo()).length > 0){
            return;
        }else{ 
        try{
            const res = await axios.put(`/group?id=${groupId}`,{
              game:radio
            },{
                headers: {
                    "Authorization": "Bearer " + parseToken
               }
            })

            // console.log(res)
            if(res.statusText === 'OK'){
               setModalIsOpen(true)
            } 
          }catch(e){
            console.log(e.response.data.error);
          }
        }
    }
  
         //  reduces sentence
   function truncate(str , n){
       return str?.length > n ? str.substr(0,n - 1) + "..." : str;
   }
    // console.log("gameDetails",gameDetails)
    return (
           <div className="gamelist">
        <div className="gamelist_wrapper">
        <div className="game_title"> 
        {chatStatus === 0 ? `${t('main.edit')} ${t('main.select')} ${t('main.Game')}` : `${t('main.view')} ${t('main.select')} ${t('main.Game')}`}
        </div>

         <table className="game_table">
            <thead>
				<tr>
     	    <th>{t('main.select')}</th>
     	    <th>#</th>
     	    <th>{t('main.description')}</th>
     	    <th>{t('main.gameName')}</th>
				</tr>
          </thead>
          {
           gameDetails[0] === undefined || gameDetails[0] === null ?(
               <tbody>
                 <tr>
                 <td style={{color:"red"}}>{t("main.nogameselectedforthisgroup")}</td>
                 </tr>
               </tbody>
           ):(
          <tbody>
		   {
					  gameDetails?.map( (game,index) => (
                          <tr key={index}>
                        <td  data-label={`${t('main.select')}`}>
                        <input type="radio" checked={radio === `${game?._id}`} value={game?._id} onChange={(e)=>setRadio(e.target.value)}/>
                        </td>

						<td  data-label="#">{index + 1 }</td>

						<td  data-label={`${t('main.description')}`}>{truncate(game?.description,75)}</td>

						<td  data-label={`${t('main.gameName')}`}>{game?.name}</td>
						   </tr>
					  ))
		    }
		   
        </tbody>
           )}
        </table>
    
    {chatStatus === 0 &&
    <button className="fetch_btn" style={{marginTop:"20px"}} onClick={fetchGames}>{t('main.fetch')}&nbsp;{t('main.games')}</button>}

         
    {Submiterrors.radio && <p className="submit_error" style={{marginTop:"40px",marginBottom:"0px"}}>{Submiterrors.radio}</p>}

		 <div className="tab_btn_div">
     {chatStatus === 0 &&   <EditPopUp modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} handleSubmit={handleSubmit} title="Updated Successfully"/>}
     </div> 
            </div>
        </div>
    )
}

export default EditSelectGame;
