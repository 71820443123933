import './ChatGroupCreate.css';
import React, { useState } from 'react';
import axios from '../../../../axios';
import PopUp from './PopUp';
import { useDataLayerValue } from '../../../../datalayer';
// import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TextEditor from './TextEditor';
import ScrollToTop from '../../../utilities/ScrollToTop';



function GroupProperties() {
  const { t } = useTranslation();
    
const getToken = localStorage.getItem("token")
const parseToken = JSON.parse(getToken);

   const [{groupId},dispatch] = useDataLayerValue();
   const [ modalIsOpen,setModalIsOpen ] = useState(false);
   const [modeDropDown,setModeDropDown]=useState()
   const [langDropDown,setLangDropDown]=useState()
   const [isChecked,setIsChecked]=useState(false);
   const [aggResponse,setAggResponse]=useState(false);
   const [offensiveFilter, setOffensiveFilter] = useState(false);
   
   const [textEditor, setTextEditor] = useState();
   const [fileUrl,setFileUrl]=useState({
       welcome_image:"",
       transcriptUrl:""
   });
   const [imageSelected,setImageSelected]=useState("");
   const [transcriptSelected,setTranscriptSelected]=useState("");
   const [backendErrMsg,setBackendErrMsg]=useState({
              imgError:"",
              fileError:"",
   })
   const [loading,setLoading]=useState({
       img:false,
       file:false
   });
   
   const [input,setInput]=useState({
          name:"",
          description:"",
          expTime:"",
          participantAmt:"",
          waitingTime:"",
          public_name:"",
          end_instruction:"",
          prefixCompletionCode:"",
          prefixTimeoutCode:"",
          wait_timeout_instruction:""
   })
   
   const [errors,setErrors]=useState({});

   const handleChange=(e)=>{
    setInput(
     {
         ...input,
         [e.target.name]:e.target.value
     }
    )
}
// console.log(Number(modeDropDown));
// console.log("expTime",Number(input.expTime));

const validateInfo=()=>{
    let errors = {};

    if(!input.name){
        errors.name=`${t("main.name")} ${t("main.required")}`
    }else if(/[!$%^&*+|~=`\\#{}\[\]:";@<>?,.\/]/.test(input.name)){
        errors.name=`${t("main.invalid")}`
    }

    if(!input.public_name){
        errors.public_name=`${t("main.public")} ${t("main.name")} ${t("main.required")}`
    } 

    if(!input.participantAmt){
        errors.participantAmt=`${t("main.participants")} ${t("main.required")}`
    }
    else if(Number(input.participantAmt) <= 0){
        errors.participantAmt=`${t("main.participants")} ${t("main.shouldBeMore")} 0`
    }

    if(!input.waitingTime){
        errors.waitingTime=`${t("main.waitingTime")} ${t("main.required")}`
    }else if(Number(input.waitingTime) <= 0){
        errors.waitingTime=`${t("main.waitingTime")} ${t("main.shouldBeMore")} 0`
    }

    if(!langDropDown){
        errors.langDropDown=`${t("main.language")} ${t("main.required")}`
    }

    if(!modeDropDown){
        errors.modeDropDown=`${t("main.chatEndMode")} ${t("main.required")}`
    }  

    if(Number(modeDropDown) === 1){
         if(!input.expTime){
            errors.expTime=`${t("main.expirationTime")} ${t("main.required")}`
         }else if (Number(input.expTime) <= 0){
            errors.expTime=`${t("main.expirationTime")} ${t("main.shouldBeMore")}  0`
         }
    }

    if(!input.end_instruction){
        errors.end_instruction=`${t("main.endInstruction")} ${t("main.required")}`
    } 
    if(!input.wait_timeout_instruction){
        errors.wait_timeout_instruction=`${t("main.waitingRoomTimeoutMessage")} ${t("main.required")}`
    } 

    if(!input.prefixCompletionCode || input.prefixCompletionCode.length < 3){
        errors.prefixCompletionCode=`${t("main.prefixContain")}`
    }else if(/[!$%^&*+|~=`\\#{}\[\]:";@<>?,.(\/')_-]/.test(input.prefixCompletionCode)){
        errors.prefixCompletionCode=`${t("main.prefixAlphaNum")}`
    }

    if(!input.prefixTimeoutCode || input.prefixTimeoutCode.length < 3){
        errors.prefixTimeoutCode=`${t("main.prefixContain")}`
    }else if(/[!$%^&*+|~=`\\#{}\[\]:";@<>?,.(\/')_-]/.test(input.prefixTimeoutCode)){
        errors.prefixTimeoutCode=`${t("main.prefixAlphaNum")}`
    }

    if(!textEditor ||  textEditor === "<p></p>\n"){
        errors.textEditor=`${t("main.welcomeMessage")} ${t("main.required")}`
    } 
   
    return errors;
 }

   const handleSubmit = async(e) =>{
        e.preventDefault();
        setErrors(validateInfo());
         
        if(Object.keys(validateInfo()).length > 0){
            return;
        }else{     
            try{
                 let CheckBoxValue;
                 if(isChecked === false){
                     CheckBoxValue = 0
                 }else{
                      CheckBoxValue = 1
                 }

                 let aggResponseValue;
                 if(aggResponse === false){
                    aggResponseValue = 0
                 }else{
                    aggResponseValue = 1
                 }

                //  console.log("aggResponseValue",aggResponseValue);

                 let expiryTime;
            
                 if(Number(modeDropDown) === 0){
                     expiryTime = ""
                 }else{
                     expiryTime = input.expTime
                 }

                 const groupConfigData = {
                    name:input.name,
                    description:input.description,
                    expTime:expiryTime,
                    waitingTime:input.waitingTime,
                    participantAmt:input.participantAmt,
                    language:langDropDown,
                    expWarning:CheckBoxValue,
                    chatEndMode:modeDropDown,
                    public_name:input.public_name,
                    end_instruction:input.end_instruction,
                    show_ag_response:aggResponseValue,
                    welcome_message:textEditor,
                    welcome_image:fileUrl.welcome_image,
                    transcriptUrl:fileUrl.transcriptUrl,
                    prefixCompletionCode:input.prefixCompletionCode,
                    prefixTimeoutCode:input.prefixTimeoutCode,
                    wait_timeout_instruction:input.wait_timeout_instruction,
                    offensive_filter: offensiveFilter
                }
                 console.log("GroupConfigData",groupConfigData);
                const res = await axios.post('/group',groupConfigData,{
                    headers: {
                        "Authorization": "Bearer " + parseToken
                      }
                })
                 console.log("res",res);
                if(res.data?._id) {
                const group_id = res.data?._id;
                  dispatch({
                    type:"GROUP_ID",
                    group_id:group_id
                   })
                 setModalIsOpen(true)
                  //css for dialog box to overlay from other components
                  document.querySelector('.ReactModal__Overlay').style.zIndex = '2';
                 }
               } catch(e) {
                console.log("backend error",e.response?.data?.error);
            }
        }
   }

   const handleFileChange=(e)=>{
       setFileUrl({
           ...fileUrl,
           [e.target.name]:e.target.value
       })
   }

   const uploadFile=async(e)=>{
    e.preventDefault();
    setFileUrl({...fileUrl,welcome_image:""})
    setBackendErrMsg({...backendErrMsg,imgError:""})
    setLoading({...loading,img:true})
    const fData = new FormData()
    fData.append('image',imageSelected)
    try{
    const res = await axios.post('/question/image',fData,{
    headers: {
        "Authorization": "Bearer " + parseToken
      },
     }
     )
    //  console.log(res);
     if(res?.statusText === "Created"){
       setFileUrl({...fileUrl,welcome_image:res.data.url})
       setLoading({...loading,img:false})
      }
    }catch(e){
      if(e.response?.data?.error){
          console.log(e.response?.data?.error);
        setBackendErrMsg({...backendErrMsg , imgError:e.response.data.error});
        setLoading({img:false,...loading})
     }
   }
}

const uploadTranscriptFile= async(e)=>{
    e.preventDefault();
    setFileUrl({...fileUrl,transcriptUrl:""})
    setBackendErrMsg({...backendErrMsg,fileError:""})
    setLoading({...loading,file:true})
    const fileData = new FormData()
    fileData.set('file',transcriptSelected)
    try{
    const res = await axios.post('/group/upload-csv',fileData,{
    headers: {
        "Authorization": "Bearer " + parseToken
      },
     }
     )
    //  console.log("upload file res>>>>",res);
     if(res?.statusText === "Created"){
       setFileUrl({...fileUrl,transcriptUrl:res.data.url})
       setLoading({...loading,file:false})
      }
    }catch(e){
      if(e.response?.data?.error){
        setBackendErrMsg({...backendErrMsg,fileError:e.response?.data?.error});
        setLoading({...loading,file:false})
     }
   }
}

    return (
        <div className="ChatGroupCreate">
            <div className="ChatGroupCreate__form">

                <div className="ChatGroupCreate__form__title">
           {t('main.roomTemplate')}&nbsp;{t('main.configuration')}
                </div>
                
                <div className="ChatGroupCreate_Disclaimer">                                                                                                                    
                    <p>{`${t("main.toAddUpdateGroupProprtiesDisclaimer")}`}</p>
                </div>

            <div className="ChatGroupCreate__newform-wrapper">

                <div className="ChatGroupCreate__input_wrapper">
                        <label className="form__input_label formManageGroup__input_label">{`${t("main.Group")} ${t("main.name")}`}</label> 
                <input type="text" name="name" value={input.name} onChange={handleChange} className="ChatGroupCreate__form__input" placeholder={`${t("main.enter")} ${t("main.name")} ${t("main.of")} ${t("main.your")} ${t("main.Group")}`}/>
                {errors.name && <p className="form_dropdown_error">{errors.name}</p>}
                </div>

                <div className="ChatGroupCreate__input_wrapper">
                        <label className="form__input_label formManageGroup__input_label">{t("main.public")} {t("main.name")}</label> 
                <input type="text" name="public_name" value={input?.public_name} onChange={handleChange} className="ChatGroupCreate__form__input" placeholder={`${t("main.enter")} ${t("main.public")} ${t("main.name")}`}/>
                {errors.public_name && <p className="form_dropdown_error">{errors.public_name}</p>}
                </div>

                <div className="ChatGroupCreate__input_wrapper">
                        <label className="form__input_label formManageGroup__input_label">{t("main.description")}</label> 
                <textarea name="description"  value={input.description} onChange={handleChange} className="ChatGroupCreate_textarea"  placeholder={`${t("main.enter")} ${t("main.description")} ${t("main.of")} ${t("main.your")} ${t("main.chat")} ${t("main.Group")}`}></textarea>
               </div>
               
                <div className="ChatGroupCreate__input_wrapper">
                        <label className="form__input_label formManageGroup__input_label">{t("main.language")}</label> 
                  <select className="ChatGroupCreate__form__input" value={langDropDown} onChange={(e)=>setLangDropDown(e.target.value)} >
                        <option value="" disabled selected >{t("main.select")} {t("main.language")}</option>
                        <option value="1">{t("main.eng")}</option>
                        <option value="2">{t("main.chi")}</option>
                   </select>
                   {errors.langDropDown && <p className="form_dropdown_error">{errors.langDropDown}</p>}
                 </div>

                   <div className="ChatGroupCreate__input_wrapper">
                        <label className="form__input_label formManageGroup__input_label">{t("main.chatEndMode")}</label> 
                    <select className="ChatGroupCreate__form__input"  value={modeDropDown} onChange={(e)=>setModeDropDown(e.target.value)} >
                        <option value="Mode" disabled selected >{t("main.select")} {t("main.chatEndMode")}</option>
                        <option value="1">{t("main.byTime")}</option>
                        <option value="0">{t("main.byQuestions")}</option>
                    </select>
                    {errors.modeDropDown && <p className="form_dropdown_error">{errors.modeDropDown}</p>}
                </div>

                {
                   Number(modeDropDown) === 0  ? (  
                    <div className="ChatGroupCreate__input_wrapper">
                    <label className="form__input_label formManageGroup__input_label">{t("main.expirationTime")}</label> 
                    <input type="number"  className="ChatGroupCreate__form__input" disabled  placeholder={`${t("main.enterExpirationTimeInMinutes")}`}/>
                    </div>
                   ):(
                   <>
                   <div className="ChatGroupCreate__input_wrapper">
                        <label className="form__input_label formManageGroup__input_label">{t("main.expirationTime")}</label> 
                    <input type="number" name="expTime" value={input.expTime} onChange={handleChange} className="ChatGroupCreate__form__input"  placeholder={`${t("main.enterExpirationTimeInMinutes")}`}/>
                     {errors.expTime && <p className="form_dropdown_error">{errors.expTime}</p>}
                     </div>
                   </>
                   )
                }
                  
                <div className="ChatGroupCreate__input_wrapper">
                        <label className="form__input_label formManageGroup__input_label">{t("main.waitingTime")}</label> 
                <input type="number" name="waitingTime" value={input.waitingTime} onChange={handleChange} className="ChatGroupCreate__form__input"  placeholder={`${t("main.enterWaitingTimeInMinutes")}`}/>
                {errors.waitingTime && <p className="form_dropdown_error">{errors.waitingTime}</p>}
                </div>

                <div className="ChatGroupCreate__input_wrapper">
                        <label className="form__input_label formManageGroup__input_label">{t("main.numberOfMaxParticipants")}</label> 
                <input type="number" name="participantAmt" value={input.participantAmt} onChange={handleChange} className="ChatGroupCreate__form__input"  placeholder={` ${t("main.enter")} ${t("main.numberOfMaxParticipants")}`}/>
                {errors.participantAmt && <p className="form_dropdown_error">{errors.participantAmt}</p>}
                  </div>

                  <div className="ChatGroupCreate__input_wrapper">
                <label className="form__input_label formManageGroup__input_label">{t("main.endInstruction")}</label> 
                <input type="text" name="end_instruction" value={input.end_instruction} onChange={handleChange} className="ChatGroupCreate__form__input"  placeholder={`${t("main.enter")} ${t("main.endInstruction")}`}/>
                {errors.end_instruction && <p className="form_dropdown_error">{errors.end_instruction}</p>}
                  </div>

                  <div className="ChatGroupCreate__input_wrapper">
                <label className="form__input_label formManageGroup__input_label">{t("main.prefixForCompletionCode")}</label> 
                <input type="text" name="prefixCompletionCode" maxLength="3" value={input.prefixCompletionCode} onChange={handleChange} className="ChatGroupCreate__form__input"  placeholder={`${t("main.enter")} ${t("main.prefixForCompletionCode")}`}/>
                {errors.prefixCompletionCode && <p className="form_dropdown_error">{errors.prefixCompletionCode}</p>}
                  </div>

                  <div className="ChatGroupCreate__input_wrapper">
                <label className="form__input_label formManageGroup__input_label">{t("main.waitingRoomTimeoutMessage")}</label> 
                <input type="text" name="wait_timeout_instruction" value={input.wait_timeout_instruction} onChange={handleChange} className="ChatGroupCreate__form__input"  placeholder={`${t("main.enter")} ${t("main.waitingRoomTimeoutMessage")}`}/>
                {errors.wait_timeout_instruction && <p className="form_dropdown_error">{errors.wait_timeout_instruction}</p>}
                  </div>

                  <div className="ChatGroupCreate__input_wrapper">
                <label className="form__input_label formManageGroup__input_label">{t("main.prefixForTimeoutCompletionCode")}</label> 
                <input type="text" name="prefixTimeoutCode" maxLength="3" value={input.prefixTimeoutCode} onChange={handleChange} className="ChatGroupCreate__form__input"  placeholder={`${t("main.enter")} ${t("main.prefixForTimeoutCompletionCode")}`}/>
                {errors.prefixTimeoutCode && <p className="form_dropdown_error">{errors.prefixTimeoutCode}</p>}
                  </div>


                  <div className="ChatGroupCreate__options-wrappper">
                 <div className="ChatGroupCreate__smallText">#{t('main.chat')}&nbsp;{t('main.options')}</div>
               
                    <div className="option_row">
                        <div className="checkbox">
                            <input type="checkbox" checked={isChecked} onChange={(e)=>setIsChecked(e.target.checked)}/>
                            <span className="checkbox_name">{t('main.enableExpirationWarning')}</span>
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" checked={aggResponse} onChange={(e)=>setAggResponse(e.target.checked)}/>
                            <span className="checkbox_name">{t("main.aggResponse")}</span>
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" checked={offensiveFilter} onChange={(e)=>setOffensiveFilter(e.target.checked)}/>
                            <span className="checkbox_name">{t("main.enableOffensiveFilter")}</span>
                        </div>
                    </div>
                </div>

                 <div className="ChatGroupCreate__uploadImage-wrapper uploadFile-wrapper">
                            <div className="form__input_wrapper">
                            <h5 className="ChatGroupCreate__waitingRoom-label">{t("main.upload")} {t("main.ChatBotTranscript")}</h5>
                            <input type="text" name="transcriptUrl" disabled value={fileUrl.transcriptUrl} onChange={handleFileChange} className="createVideo_input ChatGroupCreate__input"  placeholder={`${t("main.ChatBotTranscript")} ${t("main.url")}`}/>
                            </div>

                            {/* <span className="maximum_disclaimer">{`* ${t("main.maxUploadSize")} 1mb *`}</span> */}

                             <input type="file" className="choose_file" accept=".csv,application/vnd.ms-excel" onChange={(event)=>{setTranscriptSelected(event.target.files[0])}}/>
                             { loading.file && <p className="form_dropdown_error loading__disclaimer">{`${t("main.uploadingFilePleaseWait")}......`}</p> }

                             <span className="form_dropdown_error">{backendErrMsg?.fileError} </span>
                            <button className="upload_btn" onClick={uploadTranscriptFile}>{t('main.upload')}&nbsp;{t("main.file")}</button>
                    </div>

                </div>

                           {/* Waiting Room Information */}  

               <div className="ChatGroupCreate__waitingRoom-wrapper">

               <h3 className="ChatGroupCreate__waitingRoom-title">#{t("main.waitingRoomInfo")}</h3>
                   <div className="ChatGroupCreate__waitingRoom-container">
                       <div className="ChatGroupCreate__textEditor-wrapper">
                     <h5 className="ChatGroupCreate__waitingRoom-label">{t("main.welcomeMessage")}</h5>
                     {errors.textEditor && <p className="form_dropdown_error">{errors.textEditor}</p>}
                       <TextEditor setTextEditor={setTextEditor} />
                       </div>

                    <div className="ChatGroupCreate__uploadImage-wrapper">
                            <div className="form__input_wrapper">
                            <h5 className="ChatGroupCreate__waitingRoom-label">{t("main.welcome")} {t("main.image")}</h5>

                            <input type="text" name="welcome_image" disabled value={fileUrl.welcome_image} onChange={handleFileChange} className="createVideo_input ChatGroupCreate__input"  placeholder={`${t("main.welcome")} ${t("main.image")} ${t("main.url")}`}/>
                            </div>
                            
                            <span className="maximum_disclaimer">{`* ${t("main.maxUploadSize")} 1mb *`}</span>

                             <input type="file" className="choose_file" accept="image/*" onChange={(event)=>{setImageSelected(event.target.files[0])}}/>
                             { loading.img && <p className="form_dropdown_error loading__disclaimer">{`${t("main.uploadingFilePleaseWait")}......`}</p> }

                             <span className="form_dropdown_error">{backendErrMsg?.imgError}</span>
                            <button className="upload_btn" onClick={uploadFile}>{t('main.uploadImage')}</button>
                    </div>

                    </div>
               </div>

                <div className="tab_btn_div">
           <PopUp modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} handleSubmit={handleSubmit} title={`${t('main.groupCreatedSuccessfully')}`} title2={`${t('main.pleaseEnterOtherPropertiesoftheGroup')}`}/>
                </div>
                
            </div>
              <ScrollToTop/>
        </div>
    )
}

export default GroupProperties; 
