import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { Link, useParams } from 'react-router-dom';
import axios from '../../../axios';


const ViewQuestion = () => {
    const { t } = useTranslation();

    const { _id } = useParams();

    const [input,setInput]= useState({
        mediaUrl:"",
        description:"",
        subject:"",
        expTime:"",
        choice1:"",
        choice2:"",
        choice3:"",
        choice4:"",
        choice5:"",
        
    })

    const [radio,setRadio]=useState();
    const [dropDown,setDropDown]=useState();
  
    const [media , setMedia] = useState({
        imgUrl:"",
        mediaUrl:"",
    })

    const getToken = localStorage.getItem("token")
    const parseToken = JSON.parse(getToken)

    
    const handleChange=(e)=>{
        setInput(
         {
             ...input,
             [e.target.name]:e.target.value
         }
         )

         setMedia({
            ...media,
            [e.target.name]:e.target.value
        })
     }

    useEffect(()=>{
       const fetchUser = async()=>{
       const res=await axios.get(`/question/${_id}`,{
                headers: {
                    "Authorization": "Bearer " + parseToken
            }     
       })
       console.log('editfetch res',res.data);
       const data = res.data
       setInput(data)

       if(data?.mediaUrl?.includes('amazonaws')){
        setMedia({...media,mediaUrl:data?.mediaUrl})
         }else{
        setMedia({...media,imgUrl:data?.mediaUrl})
        }

       setDropDown(data?.language)
       setRadio(data.quesType === 1 ? '1' : '2')
       }
       fetchUser();
    },[_id,parseToken])
  
    return (
      
        <div className="createVideo">

        <div className="createVideo_wrapper">
        
                  <div className="accountDetails_title" >
               <Link to='/question-list' style={{textDecoration:"none"}}>
		        <IoIosArrowRoundBack className="back_icon_centerAlign"/>
	           </Link>
           <div style={{marginRight:"20px"}}> {t('main.view')}&nbsp;{t('main.Question')}</div>
                    </div>

               <form className="createVideo_form">

                       <div className="createVideo_form1">

                           <div className="createVideo_radioConatiner">
                        <input type="radio" id="question" checked={radio === "1"}  value="1" onChange={(e)=>{setRadio(e.target.value)}} /><label style={{marginRight:"15px"}} htmlFor="question">{t('main.Question')}</label>
                        <input type="radio" id="instruction" checked={radio === "2"} value="2" onChange={(e)=>{setRadio(e.target.value)}} /><label htmlFor="instruction">{t('main.instruction')}</label>    
                           </div>
                           
                           <div className="form__input_wrapper">
                        <label className="form__input_label">{t("main.description")}</label> 
                       <textarea name="description" value={input.description} disabled onChange={handleChange} className="createQuestion_textarea"  placeholder={`${t("main.description")}`}></textarea>
                       </div>

                        <div className="form__input_wrapper">
                        <label className="form__input_label">{t("main.expirationTime")}</label>  
                       <input type="number" name="expTime"  value={input.expTime} disabled onChange={handleChange} className="createVideo_input"  placeholder={`${t("main.enterExpirationTimeInMinutes")}`}/>
                       </div>

                    <div className="form__input_wrapper">
                    <label className="form__input_label">{t("main.image")} {t("main.url")}</label>
                     <input type="text" name="imgUrl" disabled value={media?.imgUrl} onChange={handleChange} className="createVideo_input"  placeholder={`${t("main.image")} ${t("main.url")}`}/>
                     </div>

                       </div>

                       <div className="createVideo_form2">
                       
                       <div className="form__input_wrapper">
                        <label className="form__input_label">{t("main.language")}</label>
                       <select className="createVideo_input" disabled value={dropDown} onChange={(e)=>setDropDown(e.target.value)} >
                        <option value="" disabled selected >{t("main.language")}</option>
                        <option value="1">{t("main.eng")}</option>
                        <option value="2">{t("main.chi")}</option>
                       </select>
                       </div>

                       <div className="form__input_wrapper">
                        <label className="form__input_label">{t("main.subject")}</label> 
                       <input type="text" name="subject" disabled value={input.subject} onChange={handleChange} className="createVideo_input"  placeholder={`${t("main.subject")}`}/>
                       </div>

                                      <div className="form__input_wrapper">
                            <label className="form__input_label">{t("main.Video")} {t("main.url")}</label>
                      <input type="text" name="videoUrl" disabled value={media?.mediaUrl} onChange={handleChange} className="createVideo_input"  placeholder={`${t("main.Video")} ${t("main.url")}`}/>           
                      </div>

                       </div>
               </form> 


              <form className="createQuestion_ChoiceForm">
                        <div className="createQuestion_ChoiceForm1">
                        
                        <div className="form__input_wrapper">
                             <label className="form__input_label">{t("main.choice")} 1</label>  
                       <input type="text" name="choice1" disabled value={input.choice1} onChange={handleChange} className="createVideo_input"  placeholder={`${t("main.choice")} 1`}/>
                       </div>

                       <div className="form__input_wrapper">
                             <label className="form__input_label">{t("main.choice")} 2</label>  
                       <input type="text" name="choice2" disabled value={input.choice2} onChange={handleChange} className="createVideo_input"  placeholder={`${t("main.choice")} 2`}/>
                       </div>

                       <div className="form__input_wrapper">
                             <label className="form__input_label">{t("main.choice")} 3</label>  
                       <input type="text" name="choice3" disabled value={input.choice3} onChange={handleChange} className="createVideo_input"  placeholder={`${t("main.choice")} 3`}/>
                       </div>

                       </div>

                       <div className="createQuestion_ChoiceForm2">
                       
                       <div className="form__input_wrapper">
                             <label className="form__input_label">{t("main.choice")} 4</label>  
                       <input type="text" name="choice4" disabled  value={input.choice4} onChange={handleChange} className="createVideo_input"  placeholder={`${t("main.choice")} 4`}/>
                       </div>

                       <div className="form__input_wrapper">
                             <label className="form__input_label">{t("main.choice")} 5</label>  
                       <input type="text" name="choice5" disabled value={input.choice5} onChange={handleChange} className="createVideo_input"  placeholder={`${t("main.choice")} 5`}/>
                       </div>
                 </div>
               </form>

         </div>

        </div>
    )
}

export default ViewQuestion;
