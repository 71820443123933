import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18next';
import { DataLayer } from './datalayer';
import reducer, { initialState } from './reducers/reducer';


ReactDOM.render(
  
  <Suspense fallback={(<div>Loading</div>)}>
    <DataLayer initialState={initialState} reducer={reducer}>
    <App />
   </DataLayer>
  </Suspense> ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
