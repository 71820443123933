import React from 'react'
import AdminHomepage from './adminHome'
import AdminHeader from './adminHeader'

const HomeWrapper = () => {

    return (
        <div style={{background: "#D3D3D3",height:"100vh"}}>
                    <AdminHeader/>
                    <AdminHomepage/>
        </div>
    )
}

export default HomeWrapper;
