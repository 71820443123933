import React, {  useEffect, useState } from 'react';
import {Link, useHistory} from "react-router-dom";
import { useDataLayerValue } from '../../datalayer';
import axios from '../../axios';
import './Login.css';
import JWT from 'jsonwebtoken';

function Login() {
    const getToken = localStorage.getItem("token")
    const parseToken = JSON.parse(getToken)
    const history = useHistory();

    const [{},dispatch]=useDataLayerValue();

    //  useEffect(()=>{
    //   if(parseToken){
    //       history.push('/')
    //   }
    //  })

    const [input,setInput] = useState({
        email:"",
        loginId:"",
        password:""
    })
    
    const [errors,setErrors]=useState({});
    const [message,setMessage]=useState('')

    const handleChange=(e)=>{
        setInput(
         {
             ...input,
             [e.target.name]:e.target.value
         }
        )
    }
     
    const validateInfo=()=>{
        let errors = {};

        if(!input.loginId){
            errors.loginId="Email Required"
        } 

        if(!input.password){
        errors.password="Password required"
        }

        return errors;
     }

    const handleSubmit = async(event)=> {
        event.preventDefault();
        setErrors(validateInfo(input));

        if(Object.keys(validateInfo(input)).length > 0){
            return;
        }else{
            try {
                const res = await axios.post(`/auth/login`,{
                    loginId:input.loginId,
                    password:input.password,
                   }) 

                    if(res.data.success) {
                        const token = res.data.token;
                        const localtoken = localStorage.setItem('token',JSON.stringify(token));
                        const userData = JWT.decode(token);
                        const userid = localStorage.setItem('userid',JSON.stringify(userData.id));
                        history.push('/')
                        window.location.reload();
                     }
                    
            }
            catch(e) {
                const message = e.response?.data?.error;
                setMessage(message)   
            }
        }  
     }

   
    return (
        <div className="Login">
            <div className="Homepage__bgbox1 hide_on_mobile" />
            <div className="Homepage__bgbox2 hide_on_mobile" />

            <div className="Login__main">
                <div className="Login__left">
                    <div className="Login__bigText">Sign-In</div>
                    <div className="login__smallText">Dont have an account? <a href="https://reso.chat/signup" className='login__left_link'>Sign up  Here</a></div>
                </div>
               
                <div className="Login__right">
                    <div className="Login__right_form">

                             <div className="form__input_wrapper">
                             <label className="form__input_label">Email</label>
                             <input className="Login__field" type="text" name="loginId" value={input.loginId} onChange={handleChange} placeholder="Enter Email"/>
                             {errors.loginId && <p className="form_dropdown_error formLogin__input_error">{errors.loginId}</p>}
                             </div>

                        {/* <input className="Login__field" type="text" name="loginId" value={input.loginId} onChange={handleChange} placeholder="Enter Email"/>
                        {errors.loginId && <p className="form_dropdown_error">{errors.loginId}</p>} */}
                        
                        <div className="form__input_wrapper">
                        <label className="form__input_label">Password</label>
                        <input className="Login__field" type="password" name="password" value={input.password} onChange={handleChange} placeholder="Enter Password"/>
                        {errors.password && <p className="form_dropdown_error formLogin__input_error">{errors.password}</p>}
                        </div>

                        <Link to="/forgot-password"><div className="Login__forgot_pass">Forgot Password</div></Link>

                        <Link to="/reset-password"><div className="Login__forgot_pass">Reset Password</div></Link>

                        <p className="login_backend_error">{message}</p>
                    </div>
                   <button className="Login__button" onClick={handleSubmit}>Sign In</button> 
                    
                </div>

            </div>
        </div>
    )
}

export default Login