import React, { useEffect, useState } from 'react';
import './selectVideo.css';
import { useTranslation } from 'react-i18next';
import axios from '../../../axios';
import { useDataLayerValue } from '../../../datalayer';
import PopUp from '../../Groups/CreateGroup/PopUp/PopUp';
import { useHistory } from 'react-router-dom';


const SelectVideo = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const [ modalIsOpen,setModalIsOpen ] = useState(false);
    const [{groupId },dispatch] = useDataLayerValue();
    const [errors,setErrors]=useState({});
    const [Submiterrors,setSubmiterrors]=useState({});

    const [input,setInput]= useState({
        subject:""
    })

    const [videosData,setVideosData]=useState();
	const [isLoaded, setisLoaded] = useState(false);

    const [radio,setRadio]=useState();
    const [languageDropDown,setLanguageDropDown]=useState()
    const [clickVideo,setClickVideo]=useState(false)
    const [videoId,setVideoId]=useState("")

    const getToken = localStorage.getItem("token")
    const parseToken = JSON.parse(getToken)

    useEffect(()=>{
        if(!groupId){
            history.push('/create-group')
        }
    },[groupId,history])

    const showvideo=(video)=>{
      setClickVideo(!clickVideo)
      setVideoId(video)
    }

    const handleChange=(e)=>{
        setInput(
         {
             ...input,
             [e.target.name]:e.target.value
         }
        )
    }

    const validateInfo=()=>{
        let errors = {};
    
        if(!input.subject){
            errors.subject=`${t("main.subject")} ${t("main.required")}`
        } 
    
        if(!languageDropDown){
            errors.languageDropDown=`${t("main.language")} ${t("main.required")}`
        }
        return errors;
     }
      
     const submitValidateInfo=()=>{
        let Submiterrors = {};
        if(!radio){
            Submiterrors.radio=`${t("main.fetch")} ${t("main.the")} ${t("main.videos")} ${t("main.and")} ${t("main.select")}  ${t("main.video")}`
        }
        return Submiterrors;
     }

    const fetchVideos = async(e) => {
        e.preventDefault();
        setErrors(validateInfo());
        if(Object.keys(validateInfo()).length > 0){
            return;
        }else{
        try{
           const res = await axios.get(`/video/${languageDropDown}/${input.subject}`,{
            headers: {
                "Authorization": "Bearer " + parseToken
            }
        }) 
            setVideosData(res.data)
            if(res.data.length < 1){
                setisLoaded(true)
            }else{
                setisLoaded(false)
            }
        }catch(e){
            console.log(e.response?.data?.error);
        }
      }
    }

    const handleSubmit=async(e)=>{
        e.preventDefault();
        setSubmiterrors(submitValidateInfo());
        if(Object.keys(submitValidateInfo()).length > 0){
            return;
        }else{ 
        try{
            const res = await axios.put(`/group?id=${groupId}`,{
              video:radio
            },{
                headers: {
                    "Authorization": "Bearer " + parseToken
               }
            })
            if(res.statusText === 'OK'){
               setModalIsOpen(true)
            } 
          }catch(e){
            console.log(e.response.data.error);
          }
        }
    }


   return(
      <div className="videolist">
   <div className="video_wrapper">
   <div className="video_title">{t('main.select')}&nbsp;{t('main.Video')}</div>

           <div className="video_select">
          
           <div className="form__input_wrapper ">
            <label className="form__input_label">{t("main.subject")}</label> 
           <input type="text" name="subject"  value={input.subject} onChange={handleChange} className="Selectvideo_input"  placeholder={`${t("main.enter")} ${t("main.subject")}`}/>
            </div>

           <div className="form__input_wrapper ">
                        <label className="form__input_label ">{t("main.language")}</label> 
                <select className="Selectvideo_input" value={languageDropDown} onChange={(e)=>setLanguageDropDown(e.target.value)}>
                    <option value="" disabled selected>{t("main.select")} {t("main.language")}</option>
                    <option value="1">{t("main.eng")}</option>
                    <option value="2">{t("main.chi")}</option>
               </select>
           </div>
           </div>
           {errors.subject && <p className="form_dropdown_error selectVideo__fetchError">{errors.subject}</p>}
           {errors.languageDropDown && <p className="form_dropdown_error selectVideo__fetchError">{errors.languageDropDown}</p>}           

           <button className="fetch_btn" onClick={fetchVideos}>{t('main.fetch')}&nbsp;{t('main.videos')}</button>


    <table className="video_table">
       <thead>
           <tr>
        <th>{t('main.select')}</th>
        <th>#</th>
        <th>{t('main.shortName')}</th>
        <th>{t('main.subject')}</th>
        <th>{t('main.language')}</th>
           </tr>
     </thead>
     <tbody>
     

      {
                 videosData?.map( (video,index) => (
                     <tr key={index}>
                   <td  data-label={`${t('main.select')}`}>
                   <input type="radio" checked={radio === `${video._id}`} value={video._id} onChange={(e)=>setRadio(e.target.value)}/>
                   </td>
                  
                   <td  data-label="#">{index + 1 }</td>

                   <td className="video_shortName" data-label={`${t('main.shortName')}`} onClick={()=> showvideo(video?._id)}>
                       {video.shortName}
                       {
                           clickVideo  && video?._id === videoId && 
                            <div className="video_div">
                            <video className="video_iframe" controls>
                             <source src={video?.mediaUrl} />
                               This video is not supported on your browser
                             </video>
                            </div>
                       }  
                       </td>

                   <td  data-label={`${t('main.subject')}`}>{video?.subject}</td>

                   <td  data-label={`${t('main.language')}`}>{video.language === 1 ? `${t("main.eng")}` : `${t("main.chi")}`}</td>
                      </tr>
                 ))
      } 
      
   </tbody>
           
   </table>
                    
         
   <div className="pagination_wrapper">    
   { isLoaded ? (
        <p className="form_dropdown_error">{t('main.noResultsToDisplay')}</p>
       
         ) : (
            <>
            </>      
     )}  
   </div>

              {Submiterrors.radio && <p className="submit_error">{Submiterrors.radio}</p>}

                  <div className="tab_btn_div">
               <PopUp modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} handleSubmit={handleSubmit} title={`${t('main.added')} ${t('main.Video')} ${t('main.successfully')}`}/>
                </div>
       </div>
   </div>
   )
}

export default SelectVideo;
