import React, { useEffect, useState } from 'react';
import { IoIosArrowRoundBack} from 'react-icons/io';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from '../../../axios';
import ReactPaginate from 'react-paginate';

function ChatRooms() {
      const { t } = useTranslation();
      const [chatRooms,setChatRoomDetails]=useState([]);

	  const [pageCount, setPageCount] = useState(1);
	  const [isLoaded, setisLoaded] = useState(false);
	  const [currentPage,setCurrentPage]=useState(1)

	  const getToken = localStorage.getItem("token")
	  const parseToken = JSON.parse(getToken);
    const [url, setUrl] = useState();

	  useEffect(()=>{
		fetchchatRooms(currentPage);
	  },[])

		const fetchchatRooms = async (pageToGet) => {
		
       const res = await axios.get(`/room/live?page=${pageToGet}`,{ 
				headers: {
				  "Authorization": "Bearer " + parseToken
				}
			  }) 
      setChatRoomDetails(res.data.liveRooms);
			setPageCount(res.data.count / 10 );
			if(res.data?.count >= 1){
				setisLoaded(true); 
			} 
		 }
		 

		const handlePageChange = ( selectedObject ) => {
		  let intSelectedPage = parseInt(selectedObject.selected) + 1;
		  setCurrentPage(intSelectedPage);
		  fetchchatRooms(intSelectedPage);
		}

	
    return (
		 <div className="userlist">
        <div className="userlist_wrapper">

           <div className="manageVideo_title">
                 <div className="userlist_title">
                <Link to='' style={{textDecoration:"none"}}>
		        <IoIosArrowRoundBack className="back_icon"/>
	           	</Link> 
				   {t("main.manage")}&nbsp;{t("main.activeRooms")}
                 </div>
            </div>    

                 <div className="liveroom_wrapper">
                <div className="liveRoom_wrapper_left">
                     
                     <table className="liveroom_table">
                             <thead>
                              <tr>
                             <th>#</th>
                             <th>{t('main.Group')} {t('main.name')}</th>
                              <th>{t('main.email')}</th>
							               <th>{t('main.operation')}</th>
                            </tr>
                                 </thead>
                            <tbody>
                 
                            {
                                  chatRooms?.map( (room,index) => (
                                       <tr key={index}>
                                    <td data-label="#">{index + (currentPage -1) * 10 + 1 }</td>

									<td data-label={`${t('main.Group')} ${t('main.name')}`}>{room?.groupName}</td>
            
                                    <td data-label={`${t('main.email')}`} >{room.userEmail}</td>
                                          
                                    <td data-label={`${t('main.operation')}`} >
                                        
                                        <div className="room_operation_btn_div">
                                     <Link to={`/chatRoom-details/${room?.roomId}`} className="details_btn" >
                                         {t('main.details')}
                                     </Link> 
                                     <button className="join_btn" onClick={async () => {
                                             try {
                                                 let data = await axios.get(`https://api.reso.chat/auth/getChatroomToken?room=${room.roomId}`, {
                                                     headers: {
                                                         'Authorization': `Bearer ${parseToken}`
                                                     }
                                                 });
                                                 let token = data.data.token;
                                                 setUrl(`https://app.reso.chat/?auth=${token}`);
                                             }
                                             catch(e) {}
                                         }}>{t('main.join')}</button>
                                        </div>   
                                           </td> 
                                       </tr>
                                  ))
                               }
                                     </tbody>      
                             </table>
		 <div className="pagination_wrapper" style={{margin:"15px 0px"}}>
		{ isLoaded ? (
        <ReactPaginate
         previousLabel={`${t('main.previous')}`}
         nextLabel={`${t('main.next')}`}
         pageCount={pageCount}
         onPageChange={handlePageChange}
         containerClassName={"pagination_btn"}
         previousClassName={"previousBtn"}
         activeClassName={"pagination_btn_active"}
        />
         ) : (
        <div>{t('main.noResultsToDisplay')}</div>
        )} 
		</div>
                     </div>
                     
                 <div className="liveRoom_wrapper_right"> 

                 {(url && url.length) && <div className="liveroom_body_iframe">
                    <iframe className="liveroom_iframe" src={url}/>
                 </div>}
                 </div>

            </div>

            
          </div>        
        </div> 
    )
}

export default ChatRooms;
