import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './contactAdmin.css';
import axios from '../../axios';
import { IoIosArrowRoundBack } from 'react-icons/io';
import ContactPopUp from './ContactPopUp';

const ContactAdmin = () => {
    const { t } = useTranslation();
    const [ modalIsOpen,setModalIsOpen ] = useState(false);

    const getToken = localStorage.getItem("token")
    const parseToken = JSON.parse(getToken)

    const [errors,setErrors]=useState({});
    const [input,setInput]= useState({
        subject:"",
        query:""
    })

    const validateInfo=()=>{
        let errors = {};

        if(!input.subject){
            errors.subject=`${t("main.subject") } ${t("main.required") }`
        } 
        if(!input.query){
            errors.query=`${t("main.comment/query") } ${t("main.required") }`
        }
        return errors;
     }


    const handleChange=(e)=>{
        setInput(
         {
             ...input,
             [e.target.name]:e.target.value
         }
        )
    }

    const handleSubmit=async(e)=>{
       e.preventDefault();
       console.log(input);
       setErrors(validateInfo())
       if(Object.keys(validateInfo()).length > 0){
        return;
       }else{ 
      try{
       const res = await axios.post(`/user/mailadmin`,{
           subject:input.subject,
           message:input.query,
       },{
        headers: {
            "Authorization": "Bearer " + parseToken
          } 
        })
       if(res.status === 204 ){
          setModalIsOpen(true)
       }
      }catch(e){
          console.log(e.response?.data?.error);
      }
     }
    }

    return (
        <div className="contactAdmin">

        <div className="contactAdmin_wrapper">
        <div className="contactAdmin_title">
        <Link to='/' style={{textDecoration:"none"}}>
		        <IoIosArrowRoundBack className="back_icon_centerAlign"/>
		</Link>
          <div style={{marginRight:"20px"}}> {t('main.contact')}&nbsp;{t('main.admin')}</div>
          </div>
               <form className="contactAdmin_form">

               <div className="form__input_wrapper">
                        <label className="form__input_label">{t("main.subject")}</label> 
               <input type="text" name="subject"  value={input.subject} onChange={handleChange} className="contactAdmin_input"  placeholder={`${t("main.enter")} ${t("main.subject")}`}/>
               {errors.subject && <p className="form_dropdown_error" style={{marginTop:"-15px"}}>{errors.subject}</p>}
               </div>

               <div className="form__input_wrapper">
                        <label className="form__input_label">{t("main.comment/query")}</label> 
               <textarea name="query" value={input.query} onChange={handleChange} className="contactAdmin_textarea"  placeholder={`${t("main.enter")} ${t("main.comment/query")}`}></textarea>
               {errors.query && <p className="form_dropdown_error" style={{marginTop:"15px",marginBottom:"-5px"}}>{errors.query}</p>}
               </div>

               <div className="contactAdmin_btn_div">
            <ContactPopUp modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} handleSubmit={handleSubmit} title="Updated Successfully"/>
                  </div>

               </form>
         </div>

        </div>
    )
}

export default ContactAdmin
